
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthorizationToken } from 'app/domain/models/authorization-token.model';
import { FunctionService } from 'app/shared/utils/function.service';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConnectionResolver } from 'app/api/signalr.connectionresolver';
import { CompanyToken } from 'app/auth/auth.service';


@Injectable()
export class AuthorizationTokenService {

  domainName = 'AuthorizationToken';

  constructor(private functionService: FunctionService, private http: HttpClient, private signalRConnection: ConnectionResolver, ) { }

  get(companyToken: CompanyToken, invitationToken?: string): Observable<AuthorizationToken> {
    const url = environment.apiBaseUrl + 'api/' + this.domainName;
    const companyIds: number[] = [];

    if (companyToken && companyToken.CompanyItems) {
      companyToken.CompanyItems.forEach(item => {
        companyIds.push(item.CompanyId);
      });
    }

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
      'Content-Type': 'application/json'
    });

    const body = {
      'InvitationId': invitationToken,
      'CompanyIds': companyIds
    };

    return this.http.post<any>(url, body, ({ headers: headers })).pipe(
      map(result => { return JSON.parse(this.functionService.decodeString(result)) }),
      catchError(error => { return observableThrowError(error.json()) }),);
  }
}
