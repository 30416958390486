import { Component, HostListener, Input, NgZone, OnInit } from '@angular/core';
import { Address } from 'app/domain/models/address.model';
import { State } from 'app/domain/models/state.model';
import { AddressService } from 'app/domain/services/address.service';
import { StateService } from 'app/domain/services/state.service';
import { NotificationService } from 'app/shared/utils/notification.service';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';

declare var google;
declare var $;

@Component({
   selector: 'kcms-address',
   templateUrl: './address.component.html',
   styleUrls: ['./address.component.scss'],
})
export class KcmsAddressComponent implements OnInit {
   public stateList: State[] = [];
   public stateId: string;
   public stateAcronym: string;
   public autocomplete: any;
   public addressMap: any;
   public sessionToken: any;
   public timeoutAddressAutocomplete: any;
   public suggestions: any[];
   public isZeroResults = false;
   public mapResults = false;

   @Input() showFullAddress: boolean = false;
   @Input() autocompleteLabel: string;
   @Input() autocompleteName = 'addressAutocomplete';
   @Input() mapDivId = 'mapDiv';

   public _address = new Address();
   @Input()
   get address() {
      return this._address;
   }
   set address(val: any) {
      this._address = val;
      if (val && val.Latitude && val.Longitude) {
         this.openAddressMap(val.Latitude, val.Longitude);
         val.FormattedAddress = this.addressService.formatAddress(val);
         this.changeStateByAcronym(val.StateAcronym);
         this.showFullAddress = true;
      } else if (val && val.AddressLine1) {
         this.changeStateByAcronym(val.StateAcronym);
         val.FormattedAddress = this.addressService.formatAddress(val);
         this.showFullAddress = true;
      }
   }

   changeStateByAcronym(stateAcronym: string, triggerChange: boolean = true) {
      if (stateAcronym) {
         const state = this.stateList.find((n) => n.Acronym === stateAcronym);
         this.stateId = state.Id;

         if (triggerChange) {
            this.changeState(null);
         }
      }
   }

   constructor(
      private addressService: AddressService,
      private stateService: StateService,
      private notificationService: NotificationService,
      private zone: NgZone
   ) {}

   ngOnInit() {
      this.loadStates();
   }

   @HostListener('document:click', ['$event.target'])
   public onClick(targetElement) {
      const list = $('.address-autocomplete');
      if (!list || list.length == 0) {
         return;
      }

      const elem = $(targetElement);
      if (elem && elem.length > 0 && elem[0].attributes) {
         for (let i = 0; i < elem[0].attributes.length; i++) {
            if (elem[0].attributes[i].name === 'ng-reflect-name' && elem[0].attributes[i].value === this.autocompleteName) {
               $('.address-autocomplete').show();
               return;
            }
         }
      }

      $('.address-autocomplete').hide();
   }

   loadStates() {
      this.stateService.getBrazilianStatesWithoutAuthentication().subscribe((ret) => {
         this.stateList = ret;
      });
   }

   autocompleteChanged() {
      this.address.Latitude = null;
      this.address.Longitude = null;

      this.isZeroResults = false;
      clearTimeout(this.timeoutAddressAutocomplete);

      this.timeoutAddressAutocomplete = setTimeout(() => {
         if (this.address.FormattedAddress && this.address.FormattedAddress.length > 3) {
            if (!this.sessionToken) {
               this.sessionToken = new google.maps.places.AutocompleteSessionToken();
            }

            const autocompleteService = new google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions(
               {
                  input: this.address.FormattedAddress,
                  sessionToken: this.sessionToken,
                  types: ['address'],
                  componentRestrictions: {
                     country: 'br',
                  },
               },
               (predictions, status) => {
                  if (status !== google.maps.places.PlacesServiceStatus.OK) {
                     if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                        this.isZeroResults = true;
                     } else {
                        return;
                     }
                  }

                  this.zone.run(() => {
                     this.suggestions = predictions;

                     setTimeout(() => {
                        $('.address-autocomplete .ui-menu-item-wrapper').hover(
                           function () {
                              $(this).addClass('ui-state-active');
                           },
                           function () {
                              $(this).removeClass('ui-state-active');
                           }
                        );
                     }, 10);
                  });
               }
            );
         } else {
            this.suggestions = null;
         }
      }, 1000);
   }

   addressClicked(placeId: any) {
      const placesService = new google.maps.places.PlacesService(document.createElement('div'));
      placesService.getDetails(
         {
            placeId: placeId,
            sessionToken: this.sessionToken,
            fields: ['formatted_address', 'address_component', 'geometry'],
         },
         (place: GoogleAddress, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
               this.zone.run(() => {
                  this.addressChanged(place);
               });
            }
            this.sessionToken = null;
         }
      );
      this.showFullAddress = true;
      this.suggestions = null;
      this.mapResults = false;
   }

   addressSearchOpenSearchMap() {
      if (this.address.FormattedAddress) {
         const addressInput = this.address.FormattedAddress;
         const geocoder = new google.maps.Geocoder();

         geocoder.geocode({ address: addressInput }, (place, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
               this.address.Latitude = place[0].geometry.location.lat();
               this.address.Longitude = place[0].geometry.location.lng();

               this.openAddressMap(this.address.Latitude, this.address.Longitude);
            }
         });
      }
   }

   addressChanged(address: GoogleAddress) {
      const convertedAddress = this.addressService.transform(address);

      if (!this.address) {
         this.address = new Address();
      }

      if (convertedAddress) {
         this.address.ZipCode = convertedAddress.ZipCode;
         this.address.FormattedAddress = convertedAddress.FormattedAddress;
         this.address.AddressLine1 = convertedAddress.AddressLine1;
         this.address.AddressNumber = convertedAddress.AddressNumber;
         this.address.Neighborhood = convertedAddress.Neighborhood ? convertedAddress.Neighborhood : convertedAddress.CityName;
         this.address.CityName = convertedAddress.CityName;
         this.address.StateAcronym = convertedAddress.StateAcronym;
         this.address.StateName = convertedAddress.StateName;
         this.address.CountryAcronym = convertedAddress.CountryAcronym;
         this.address.CountryName = convertedAddress.CountryName;
         this.address.Latitude = convertedAddress.Latitude;
         this.address.Longitude = convertedAddress.Longitude;

         this.changeStateByAcronym(this.address.StateAcronym, false);

         if (this.address.AddressNumber) {
            this.openAddressMap(this.address.Latitude, this.address.Longitude);
         }
      } else {
         this.address.ZipCode = null;
         this.address.FormattedAddress = null;
         this.address.AddressLine1 = null;
         this.address.AddressNumber = null;
         this.address.Neighborhood = null;
         this.address.CityName = null;
         this.address.StateAcronym = null;
         this.address.StateName = null;
         this.address.CountryAcronym = null;
         this.address.CountryName = null;
         this.address.Latitude = null;
         this.address.Longitude = null;
      }
   }

   openSearchMap() {
      navigator.geolocation.getCurrentPosition(
         (resp) => {
            this.zone.run(() => {
               this.isZeroResults = false;
               this.showFullAddress = true;

               if (!this.address) {
                  this.address = new Address();
               }
               this.address.FormattedAddress = null;
               this.address.ZipCode = null;
               this.address.AddressLine1 = null;
               this.address.Neighborhood = null;
               this.address.CityName = null;
               this.address.StateAcronym = null;
               this.address.StateName = null;
               this.address.CountryAcronym = 'BR';
               this.address.CountryName = 'Brasil';
               this.address.Latitude = resp.coords.latitude;
               this.address.Longitude = resp.coords.longitude;

               this.openAddressMap(this.address.Latitude, this.address.Longitude);
            });
         },
         (err) => {
            this.notificationService.showErrorBox('AddressUserLocationTurnedOff');
         }
      );
   }

   openAddressMap(latitude: number, longitude: number) {
      setTimeout(() => {
         const div = document.getElementById(this.mapDivId);
         this.addressMap = new google.maps.Map(div, {
            center: { lat: latitude, lng: longitude },
            zoom: 19,
            maxZoom: 20,
            minZoom: 18,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            clickableIcons: false,
            fullscreenControl: false,
            mapTypeControl: false,
            panControl: false,
            zoomControl: false,
            streetViewControl: false,
            restriction: {
               latLngBounds: { north: latitude + 0.01, south: latitude - 0.01, west: longitude - 0.02, east: longitude + 0.02 },
            },
         });

         const marker = new google.maps.Marker({
            map: this.addressMap,
            animation: google.maps.Animation.DROP,
         });

         this.addressMap.setCenter({
            lat: latitude,
            lng: longitude,
         });

         marker.setMap(this.addressMap);
         marker.setPosition(this.addressMap.getCenter());

         google.maps.event.addListener(this.addressMap, 'center_changed', () => {
            marker.setPosition(this.addressMap.getCenter());
         });

         google.maps.event.addListener(this.addressMap, 'dragend', (evt) => {
            this.address.Latitude = this.addressMap.getCenter().lat();
            this.address.Longitude = this.addressMap.getCenter().lng();
         });
      }, 100);
   }

   changeState(event) {
      const state = this.stateList.find((n) => n.Id == this.stateId);

      if (state) {
         this.address.StateAcronym = state.Acronym;
         this.address.StateName = state.Name;
         this.stateAcronym = state.Acronym;
      } else {
         this.address.StateAcronym = null;
         this.address.StateName = null;
      }
   }

   addressInputChanged() {
      const addrees = this.address;
      const addreesFormated = `${addrees.AddressLine1}, ${addrees.AddressNumber} - ${addrees.Neighborhood}, ${addrees.CityName} - ${addrees.StateAcronym}, ${addrees.ZipCode}, ${addrees.CountryName}`;

      this.address.Latitude = null;
      this.address.Longitude = null;

      this.address.FormattedAddress = addreesFormated;
   }

   addressInputChangedAddress() {
      this.mapResults = true;

      const addrees = this.address;
      const addreesFormated = `${addrees.AddressLine1}, ${addrees.AddressNumber} - ${addrees.Neighborhood}, ${addrees.CityName} - ${addrees.StateAcronym}, ${addrees.ZipCode}, ${addrees.CountryName}`;

      this.address.FormattedAddress = addreesFormated;

      this.clearInputs();
      this.autocompleteChanged();
   }

   clearInputs() {
      this.address.AddressNumber = '';
      this.address.Neighborhood = '';
      this.address.ZipCode = '';
   }
}
