import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WootricDirective } from './wootric.directive';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [WootricDirective],
  exports: [WootricDirective],
})
export class WootricModule { }
