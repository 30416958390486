import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService, Filter, FilterComparisonType } from 'app/api/api.service';
import { AccessType, AuthService, PermissionType } from 'app/auth/auth.service';
import { NfeEntryStatus } from 'app/domain/models/nfe-entry.model';
import { NfeStatus } from 'app/domain/models/nfe.model';
import { I18nService } from 'app/shared/i18n';
import { config } from 'app/shared/smartadmin.config';
import { DatatableComponent } from 'app/shared/ui/datatable';
import { FunctionService } from 'app/shared/utils/function.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';

declare var $: any;

@Component({
   selector: 'kcms-grid-list',
   templateUrl: './grid-list.component.html',
   styleUrls: ['./grid-list.component.scss'],
})
export class GridListComponent implements OnInit {
   public hasRemoveButton = false;
   public hasOrderButtons = false;
   public hasProductActionButtons = false;
   public hasNfeActionButtons = false;
   public hasNfeEntryActionButtons = false;
   public hasNfeResumeActionButtons = false;
   public hasWaitlistActionButtons = false;
   public hasAccountantRemoveButton = false;
   public hasOrderCheckActionButtons = false;
   public hasUserActionButtons = false;
   public hasProductPriceHistoryButton = false;
   public options: any;
   public tickIds: string[] = [];
   public hasAddEditRemoveButton = false;
   public hasBankAccountActionButtons = false;
   public hasCostCenterActionButtons = false;
   public hasFinanceAccountActionButtons = false;
   public hasOrderCheckPrinterQrCode = false;
   public hasOrderCheckDownloadQrCode = false;
   public hasPixActionButtons = false;

   @Input() accessDomain: string;
   @Input() domain: string;
   @Input() pageLength = 10;
   @Input() ordering = true;
   @Input() order: any[];
   @Input() orderBy = 'Name';
   @Input() orderDirection = 'asc';
   @Input() detailUrl: string;
   @Input() isOData = true;
   @Input() useIntro = true;
   @Input() sds: boolean;
   @Input() multiSelect: boolean;
   @Input() customFilters: any;
   @Input() paginationLength = true;
   @Input() loadOnInit = true;
   @Input() loadOnFilter = false;
   @Input() tableHover = false;
   @Input() keepFilters = false;
   @Input() removeButtonConfirmTitle: string;
   @Input() removeButtonConfirmMessage: string;
   @Input() hasPagination = true;
   @Input() hideHeader = false;
   @Input() isLoading = false;
   @Input() hasFilter = true;
   @Input() hasFooter = true;
   @Input() addRowClass: boolean = false;
   @Input() id: string;

   public _dataConfig: any[];
   @Input()
   get dataConfig() {
      return this._dataConfig;
   }
   set dataConfig(val: any[]) {
      if (val) {
         const companyNameColumnIndex = val.findIndex((n) => n.dataField === 'CompanyName' && !n.alwaysShow);
         if (companyNameColumnIndex >= 0) {
            const companies = this.authService.getCompanies();
            if (!companies || !companies.CompanyItems || companies.CompanyItems.length < 2) {
               val.splice(companyNameColumnIndex, 1);
            }
         }
      }
      this._dataConfig = val;
      if (this.data) {
         this.createGrid();
      }
   }

   public _dataCheck: any;
   @Input()
   get dataCheck() {
      return this._dataCheck;
   }
   set dataCheck(val: any) {
      this._dataCheck = val;
   }

   public _data: any[];
   @Input()
   get data() {
      return this._data;
   }
   set data(val) {
      this._data = val;
      this.createGrid();
   }

   public _baseApiUrl: string;
   @Input()
   get baseApiUrl(): string {
      if (this._baseApiUrl === null || this._baseApiUrl === undefined) return environment.apiBaseUrl;

      return this._baseApiUrl;
   }
   set baseApiUrl(val: string) {
      this._baseApiUrl = val;
      this.createGrid();
   }

   public _apiAddress: string;
   @Input()
   get apiAddress(): string {
      return this._apiAddress;
   }
   set apiAddress(val: string) {
      this._apiAddress = val;
      this.createGrid();
   }

   public _companyID: number;
   @Input()
   get companyID() {
      return this._companyID;
   }
   set companyID(val) {
      if (this.companyID !== val) {
         this._companyID = val;
         this.createGrid();
      }
   }

   @Output() onClickRow = new EventEmitter();
   @Output() onClickRemove = new EventEmitter();

   @Output() onClickPrinter = new EventEmitter();
   @Output() onClickDownload = new EventEmitter();

   @Output() onClickOrder = new EventEmitter();
   @Output() onGridClickProductAction = new EventEmitter();
   @Output() onGridClickNfeAction = new EventEmitter();
   @Output() onGridClickNfeEntryAction = new EventEmitter();
   @Output() onGridClickNfeResumeAction = new EventEmitter();
   @Output() onClickWaitlistAction = new EventEmitter();
   @Output() onClickAccountantRemove = new EventEmitter();
   @Output() onClickOrderCheckAction = new EventEmitter();
   @Output() onGridClickUserAction = new EventEmitter();
   @Output() onGridClickProductPriceHistory = new EventEmitter();
   @Output() datatablesOnInit = new EventEmitter();
   @Output() onClickAddEditRemove = new EventEmitter();
   @Output() onGridClickBankAccountAction = new EventEmitter();
   @Output() onGridClickCostCenterAction = new EventEmitter();
   @Output() onGridClickFinanceAccountAction = new EventEmitter();
   @Output() onRowCreating = new EventEmitter();
   @Output() onGridClickPix = new EventEmitter();

   @ViewChild(DatatableComponent, { static: true }) datatableComponent: DatatableComponent;

   constructor(
      public apiService: ApiService,
      public decimalPipe: DecimalPipe,
      public currencyPipe: CurrencyPipe,
      public functionService: FunctionService,
      public i18nService: I18nService,
      public authService: AuthService
   ) {
      this.decimalPipe = new DecimalPipe(config.defaultLocale);
      this.currencyPipe = new CurrencyPipe(config.defaultLocale);
   }

   ngOnInit() {
      if (this.loadOnInit) {
         this.createGrid();
      }
   }

   datatablesonInit() {
      this.datatablesOnInit.emit();
   }

   createGrid() {
      const types = ['decimal', 'percentage', 'currency'];
      if ((this.apiAddress || this.domain || this.data) && this.dataConfig) {
         const columns: any[] = [];

         this.dataConfig.forEach((dc, i) => {
            if (this.keepFilters) {
               dc.filterValue = sessionStorage.getItem('sSearch_' + i);
            }

            const dataCell = {
               data: dc.dataField,
               className: dc.className,
               defaultContent: '',
               render: null,
            };

            if (!dataCell.className && types.findIndex((x) => x === dc.dataType) > -1) {
               dataCell.className = 'dt-right';
            }

            // Setting default ordering
            if (!this.order && dc.dataField === this.orderBy) {
               this.order = [[i, this.orderDirection]];
            }

            // Formatting cell value according to the data type
            switch (dc.dataType) {
               case 'string':
                  dataCell.render = function (data, type, row) {
                     return this.renderString(data, type, row, dc.translateText, dc.icon);
                  }.bind(this);
                  break;

               case 'date':
                  dataCell.render = function (d) {
                     return moment(d).format(dc.textFormat);
                  };
                  break;

               case 'decimal':
                  dataCell.render = function (d) {
                     return this.formatDecimal(d, dc.textFormat);
                  }.bind(this);
                  break;

               case 'percentage':
                  dataCell.render = function (d) {
                     return this.formatDecimal(d, dc.textFormat) + '%';
                  }.bind(this);
                  break;

               case 'currency':
                  dataCell.render = function (d) {
                     return this.formatCurrency(d, dc.textFormat);
                  }.bind(this);
                  break;

               case 'boolean':
                  dataCell.render = function (data, type, row) {
                     return this.renderStandardCheckbox(data, type, row);
                  }.bind(this);
                  break;

               case 'booleanText':
                  dataCell.render = function (data, type) {
                     return this.renderBooleanText(data, dc.dataField, type, dc.useYesOrNo);
                  }.bind(this);
                  break;

               case 'booleanTextExcel':
                  dataCell.render = function (data, type) {
                     return this.renderBooleanTextExcel(data, type);
                  }.bind(this);
                  break;

               case 'booleanTextWarning':
                  dataCell.render = function (data, type) {
                     return this.renderBooleanTextWarning(data, type);
                  }.bind(this);
                  break;

               case 'fiscalRuleErrors':
                  dataCell.render = function (data, type) {
                     return this.renderFiscalRuleErrors(data, type);
                  }.bind(this);
                  break;

               case 'switch':
                  dataCell.render = function (data, type, row) {
                     return this.renderSwitchCheckbox(data, type, row);
                  }.bind(this);
                  break;

               case 'template':
                  dataCell.render = function (data, type, row) {
                     return this.renderTemplate(data, type, row, dc.useDataField ? dc.dataField : null, dc.template, dc.templateDataType);
                  }.bind(this);
                  break;

               case 'checkbox':
                  dataCell.render = function (data, type, row) {
                     return this.renderCheckbox(data, type, dc.dataField, dc.template);
                  }.bind(this);
                  break;

               case 'review':
                  dataCell.render = function (data, type, row) {
                     return this.renderReview(data, type, row);
                  }.bind(this);
                  break;

               case 'multiselect':
                  dataCell.render = function (data, type, row) {
                     return this.renderMultiSelectCheckbox(data, type, dc.dataField, dc.template);
                  }.bind(this);
                  break;

               case 'removeButton':
                  this.hasRemoveButton = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderRemoveButton(data, type, row);
                  }.bind(this);
                  break;

               case 'orderButton':
                  this.hasOrderButtons = true;
                  dataCell.render = function (data, type, row, meta) {
                     return this.renderOrderButton(data, type, row, meta);
                  }.bind(this);
                  break;

               case 'dateTime':
                  dataCell.render = function (data, type, row) {
                     return this.renderDateTime(data, dc.dataField, type, row, dc.textFormat, dc.ignoreTimeZone);
                  }.bind(this);
                  break;

               case 'elapsedTime':
                  dataCell.render = function (data, type, row) {
                     return this.renderElapsedTime(data, dc.dataField, type, row);
                  }.bind(this);
                  break;

               case 'uploadType':
                  dataCell.render = function (data) {
                     return this.i18nService.getTranslation(this.uploadTypeConvert(data));
                  }.bind(this);
                  break;

               case 'nfeStatus':
                  dataCell.render = function (data, type, row) {
                     return this.renderNfeStatus(data, dc.dataField, type, row);
                  }.bind(this);
                  break;

               case 'productActionButton':
                  this.hasProductActionButtons = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderProductActionButtons(data, dc.dataField, type, row);
                  }.bind(this);
                  break;

               case 'nfeActionButton':
                  this.hasNfeActionButtons = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderNfeActionButtons(data, dc.dataField, type, row);
                  }.bind(this);
                  break;

               case 'nfeEntryActionButton':
                  this.hasNfeEntryActionButtons = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderNfeEntryActionButtons(data, dc.dataField, type, row);
                  }.bind(this);
                  break;

               case 'nfeResumeActionButton':
                  this.hasNfeResumeActionButtons = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderNfeResumeActionButtons(data, dc.dataField, type, row);
                  }.bind(this);
                  break;

               case 'waitlistActionButton':
                  this.hasWaitlistActionButtons = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderWaitListActionButtons(data, type, row);
                  }.bind(this);
                  break;

               case 'accountantRemoveButton':
                  this.hasAccountantRemoveButton = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderAccountantRemoveButton(data, type, row);
                  }.bind(this);
                  break;

               case 'orderCheckActionButton':
                  this.hasOrderCheckActionButtons = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderOrderCheckActionButtons(data, dc.dataField, type, row);
                  }.bind(this);
                  break;

               case 'userActionButton':
                  this.hasUserActionButtons = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderUserActionButtons(data, dc.dataField, type, row);
                  }.bind(this);
                  break;

               case 'productPriceHistoryButton':
                  this.hasProductPriceHistoryButton = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderProductPriceHistoryButton(data, type, row);
                  }.bind(this);
                  break;

               case 'orderCenterListOrderImported':
                  dataCell.render = function (data, type, row) {
                     return this.renderOrderCenterListOrderImported(data, type, row);
                  }.bind(this);
                  break;

               case 'booleanImage':
                  dataCell.render = function (data, type) {
                     return this.renderBooleanImage(data, dc.dataField, type, dc.useYesOrNo);
                  }.bind(this);
                  break;

               case 'showChildrenButton':
                  dataCell.render = function (data, type, row) {
                     return this.renderShowChildrenButton(data, type, row);
                  }.bind(this);
                  break;

               case 'addEditRemoveButton':
                  this.hasAddEditRemoveButton = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderAddEditRemoveButton(data, type, row);
                  }.bind(this);
                  break;

               case 'bankAccountActionButtons':
                  this.hasBankAccountActionButtons = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderBankAccountActionButtons(data, type, row);
                  }.bind(this);
                  break;

               case 'costCenterActionButtons':
                  this.hasCostCenterActionButtons = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderCostCenterActionButtons(data, type, row);
                  }.bind(this);
                  break;

               case 'financeAccountActionButtons':
                  this.hasFinanceAccountActionButtons = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderFinanceAccountActionButtons(data, type, row);
                  }.bind(this);
                  break;

               case 'orderCheckPrinterQrCode':
                  this.hasOrderCheckPrinterQrCode = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderOrderCheckPrinterQrCodeButton(data, type, row);
                  }.bind(this);
                  break;

               case 'orderCheckDownloadQrCode':
                  this.hasOrderCheckDownloadQrCode = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderOrderCheckDownloadQrCodeButton(data, type, row);
                  }.bind(this);
                  break;

               case 'pixActionButtons':
                  this.hasPixActionButtons = true;
                  dataCell.render = function (data, type, row) {
                     return this.renderPixActionButton(data, dc.dataField, type, row);
                  }.bind(this);
                  break;
            }
            columns.push(dataCell);
         });

         let ajaxUrl = null;

         if (this.customFilters) {
            sessionStorage.setItem('sSearch_CustomFilters', this.functionService.encodeString(JSON.stringify(this.customFilters)));
         }

         if (this.apiAddress) {
            ajaxUrl = this.apiAddress;
         } else if (this.domain) {
            if (!this.isOData) {
               ajaxUrl = this.baseApiUrl + 'api/' + this.domain;
            } else {
               ajaxUrl = this.baseApiUrl + 'odata/' + this.domain;
            }

            const filters: Filter[] = [];

            if (this.companyID != null) {
               filters.push({
                  Key: 'CompanyId',
                  Value: this.companyID,
                  ComparisonType: FilterComparisonType.Equal,
               });
            }

            ajaxUrl += this.apiService.getFilters(filters, null);
         }

         this.options = {
            data: this.data,
            processing: ajaxUrl ? true : false,
            serverSide: ajaxUrl ? true : false,
            paging: this.hasPagination,
            pageLength: this.pageLength && this.pageLength > 0 ? parseInt(this.pageLength.toString(), 10) : 10,
            ordering: this.ordering,
            order: this.order,
            columns: columns,
         };

         if (this.addRowClass && this.onRowCreating) {
            let fnRowCreating = this.onRowCreating;
            this.options.createdRow = function (row, data, dataIndex) {
               fnRowCreating.emit({ row: row, data: data });
            };
         }

         if (this.loadOnFilter) {
            this.options.deferLoading = 0;
         }

         this.options = $.extend(this.options, {
            ajaxUrl: ajaxUrl,
         });
      }
   }

   uploadTypeConvert(data) {
      switch (data) {
         case 1:
            return (data = 'FullF');
         case 2:
            return (data = 'SalesAndFiles');
         case 3:
            return (data = 'Sales');
         case 4:
            return (data = 'CustomF');
      }
   }

   formatDecimal(value, format) {
      if (!value || !isFinite(value) || isNaN(value)) {
         value = 0;
      }
      return this.decimalPipe.transform(value, format);
   }

   formatCurrency(value, format) {
      const formatConfig = format.split(', ');
      if (!isFinite(value) || isNaN(value)) {
         value = 0;
      }

      return this.currencyPipe.transform(value, formatConfig[0], 'symbol', formatConfig[2]);
   }

   renderString(data, type, row, translateText, icon) {
      let text = data;

      if (translateText) {
         text = this.i18nService.getTranslation(data);
      }

      if (icon && type === 'display') {
         if (icon === 'IsPriority' && row['IsPriority']) {
            return '<b class="icon-priority far fa-star"></b> ' + text;
         }
      }

      return text;
   }

   renderStandardCheckbox(data, type, row) {
      if (type === 'display') {
         return (
            "<div class='smart-form'><label class='checkbox state-disabled'>" +
            "<input type='checkbox' " +
            (data ? 'checked' : '') +
            " disabled='disabled'><i></i></label></div>"
         );
      }
      return data;
   }

   renderBooleanText(data, dataField, type, useYesOrNo) {
      return this.i18nService.getTranslation(this.getBooleanText(dataField, data, useYesOrNo));
   }

   renderBooleanTextExcel(data, type) {
      return this.i18nService.getTranslation(this.getBooleanTextExcel(data));
   }

   renderBooleanTextWarning(data, type) {
      let ErrorWarning = this.i18nService.getTranslation('ErrorWarning');

      if (data) {
         return `<i class="fa-solid fa-triangle-exclamation" style="color: #dc7031; font-size: 1.5rem" title="${ErrorWarning}"></i>`;
      }
   }

   renderFiscalRuleErrors(data, type, template) {
      let messageError = '';

      if (data) {
         data.forEach((codError) => {
            messageError += `${this.i18nService.getTranslation(codError)}<br>`;
         });
      }
      return messageError;
   }

   renderDateTime(data, dataField, type, row, textFormat, ignoreTimeZone) {
      if (type === 'display') {
         if (!data) {
            if (dataField === 'LastModifiedOn') {
               data = row['CreatedOn'];
            } else {
               return '';
            }
         }
         if (!textFormat) {
            textFormat = 'L HH:mm';
         }
         if (ignoreTimeZone) {
            return moment(data).utc().format(textFormat);
         }
         return moment(data).format(textFormat);
      }
      return data;
   }

   setTimeElapsedTick(id) {
      setTimeout(() => {
         const dom = $('#grid-elapsed-' + id);
         const hiddenDom = $('#grid-elapsed-hidden-' + id);
         if (dom.length > 0) {
            const time = moment('1970-01-01 ' + hiddenDom.val()).add(1, 'second');
            dom.text(time.format('HH:mm'));
            hiddenDom.val(time.format('HH:mm:ss'));

            this.setTimeElapsedTick(id);
         }
      }, 1000);
   }

   renderElapsedTime(data, dataField, type, row) {
      if (type === 'display') {
         if (data <= 0) {
            return '-';
         }

         if (this.tickIds.indexOf(row.Id) < 0) {
            this.tickIds.push(row.Id);
            this.setTimeElapsedTick(row.Id);
         }

         return (
            '<div id="grid-elapsed-' +
            row.Id +
            '">' +
            moment(data).utc().format('HH:mm') +
            '</div>' +
            '<input type="hidden" id="grid-elapsed-hidden-' +
            row.Id +
            '" value="' +
            moment(data).utc().format('HH:mm:ss') +
            '">'
         );
      }
      return data;
   }

   renderCheckbox(data, type, row) {
      if (type === 'display') {
         return (
            "<div class='smart-form'><label class='checkbox'>" +
            '<input type="hidden" class="grid-checkbox-property" value="' +
            row +
            '">' +
            "<input class='grid-checkbox' type='checkbox' " +
            (data ? 'checked' : '') +
            '><i></i></label></div>'
         );
      }
      return data;
   }

   renderReview(data, type, row) {
      if (type === 'display') {
         let starDiv = '<i class="fas fa-star" aria-hidden="true" />';
         let allStars = '';

         for (let i = 0; i <= row.Rating - 1; i++) {
            allStars += starDiv;
         }

         return `(${row.Rating}) ${allStars}`;
      }
      return data;
   }

   renderMultiSelectCheckbox(data, type, row) {
      if (type === 'display') {
         return (
            "<div class='smart-form'><label class='checkbox'>" + "<input class='multiselect-checkbox' type='checkbox'><i></i></label></div>"
         );
      }
      return data;
   }

   renderSwitchCheckbox(data, type, row) {
      if (type === 'display') {
         return (
            "<span class='onoffswitch'>" +
            "<input type='checkbox' class='onoffswitch-checkbox' id='input" +
            row.Id +
            "' " +
            (data ? 'checked' : '') +
            '/> ' +
            "<label class='onoffswitch-label' for='input" +
            row.Id +
            "'> " +
            "<span class='onoffswitch-inner' data-swchon-text='' data-swchoff-text=''></span> " +
            "<span class='onoffswitch-switch'></span> </label></span>"
         );
      }
      return data;
   }

   renderRemoveButton(data, type, row) {
      if (type === 'display') {
         return "<i class='far fa-times remove-button' aria-hidden='true'></i>";
      }
      return data;
   }

   renderOrderCheckPrinterQrCodeButton(data, type, row) {
      if (type === 'display') {
         return "<i class='fa-regular fa-print'></i>";
      }
      return data;
   }

   renderOrderCheckDownloadQrCodeButton(data, type, row) {
      if (type === 'display') {
         return "<i class='fa-regular fa-download'></i>";
      }
      return data;
   }

   renderOrderButton(data, type, row, meta) {
      if (type === 'display') {
         let cell = '';
         if (meta.row > 0) {
            cell += "<i class='far fa-arrow-up order-up-button' aria-hidden='true'></i> ";
         }
         if (meta.row < this.data.length - 1) {
            cell += "<i class='far fa-arrow-down order-down-button' aria-hidden='true'></i>";
         }
         return cell.trim();
      }
      return data;
   }

   renderWaitListActionButtons(data, type, row) {
      if (type === 'display') {
         let cell = '';
         if (row.StatusCode === 1) {
            cell += "<i class='far fa-envelope waitlist-action-message-button' aria-hidden='true' ";
            cell += "title='" + this.i18nService.getTranslation('WaitlistCustomerMessageIconTitle') + "'></i>&nbsp;&nbsp;";
         }

         if (row.StatusCode <= 2) {
            cell += "<i class='far fa-check waitlist-action-complete-button' aria-hidden='true' ";
            cell += "title='" + this.i18nService.getTranslation('WaitlistCustomerCompleteIconTitle') + "'></i>&nbsp;&nbsp;";
            cell += "<i class='far fa-times waitlist-action-cancel-button' aria-hidden='true' ";
            cell += "title='" + this.i18nService.getTranslation('WaitlistCustomerCancelIconTitle') + "'></i>";
         }

         return cell.trim();
      }
      return data;
   }

   renderTemplate(data, type, row, dataField, template, templateDataType) {
      if (template) {
         if (type === 'display' || (type === 'filter' && template)) {
            const lstProperties = [];

            if (dataField) {
               this.getProperties(lstProperties, data, dataField);
            } else {
               this.getProperties(lstProperties, row, '');
            }

            lstProperties.forEach((prop) => {
               if (templateDataType) {
                  const formatConfig = templateDataType.find(function (item) {
                     return item.field === prop.property;
                  });

                  if (formatConfig) {
                     switch (formatConfig.type) {
                        case 'decimal':
                           prop.data = this.formatDecimal(prop.data, formatConfig.format);
                     }
                  }
               }
               template = template.replace('{{' + prop.property + '}}', prop.data);
            });

            if (templateDataType) {
               templateDataType.forEach((elem) => {
                  template = template.replace('{{' + elem.field + '}}', elem.placeholder);
               });
            }
            return template;
         }
      }

      return data;
   }

   renderProductActionButtons(data, datafield, type, row) {
      if (type === 'display') {
         let menuItem = `
        <li><a id="${row.Id}" class="product-action-button" data-name="${row.Name}" data-companyId="${
            row.CompanyId
         }" data-action="cloneProduct"><i class="far fa-copy"></i> ${this.i18nService.getTranslation('Clone')}</a></li>`;

         const permissionType = this.authService.getPermissionType('Product', AccessType.Update, row.CompanyId);
         if (permissionType == PermissionType.Owner) {
            menuItem += `
          <li><a id="${row.Id}" class="product-action-button" data-name="${row.Name}" data-companyId="${
               row.CompanyId
            }" data-action="removeProduct"><i style="margin-left: 2px; margin-right: 1px;" class="far fa-times"></i> ${this.i18nService.getTranslation(
               'Remove'
            )}</a></li>`;
         }

         const cell = this.buildDropdownActionButton(menuItem);
         return cell.trim();
      }
      return data;
   }

   renderOrderCheckActionButtons(data, datafield, type, row) {
      if (type === 'display' && row.IsMenuServiceEnabled && row.MenuServiceFriendlyUrl) {
         const menuItem = `
        <li>
          <a id="${row.Id}" class="order-check-action-button" data-isgroupcheck="${row.OrderCheckType.toUpperCase() === 'MESA'}"
            data-startnumber="${row.StartNumber}" data-endnumber="${row.EndNumber}" data-companyId="${row.CompanyId}"
            data-friendlyUrl="${row.MenuServiceFriendlyUrl}" data-action="generateQrCode">${this.i18nService.getTranslation(
            'PrintTableQrCode'
         )}
          </a>
        </li>
        <li>
          <a id="${row.Id}" class="order-check-action-button" data-isgroupcheck="${row.OrderCheckType.toUpperCase() === 'MESA'}"
              data-startnumber="${row.StartNumber}" data-endnumber="${row.EndNumber}" data-companyId="${row.CompanyId}"
              data-friendlyUrl="${row.MenuServiceFriendlyUrl}" data-action="downloadQrCode">${this.i18nService.getTranslation(
            'DownloadQrcode'
         )}
          </a>
        <li>
        `;
         const cell = this.buildDropdownActionButton(menuItem);
         return cell.trim();
      }
      return data;
   }

   renderNfeStatus(data, type, row) {
      const translated = this.i18nService.getTranslation(data);
      return `<div style="display: flex; font-size: 0.8rem;"><i class=\'fas fa-circle nfestatus ${data.toLowerCase()}\' aria-hidden=\'true\'></i> ${translated}</div>`;
   }

   renderPixActionButton(data, datafield, type, row): string {
      let menuItems = '';
      if (type === 'display') {
         let cell = '';

         menuItems += `
         <li>
               <a class="pix-action-button" 
                  id="${row.TransactionId}" 
                  data-emissionDate="${row.EmissionDate}" 
                  data-type="${row.Type}" 
                  data-description="${row.Description}"
                  data-comment="${row.Comment}"
                  data-totalValue="${row.TotalValue}"
                  data-code="${row.Code}" 
                  data-action="pixView">
                     ${this.i18nService.getTranslation('TextActionsButtonPixView')}
               </a>
         </li>
                  `;

         // if (row.Type === 'C' && row.Code === '2016') {
         //    menuItems += `
         //       <li>
         //             <a class="pix-action-button"
         //                id="${row.TransactionId}"
         //                data-emissionDate="${row.EmissionDate}"
         //                data-type="${row.Type}"
         //                data-description="${row.Description}"
         //                data-comment="${row.Comment}"
         //                data-totalValue="${row.TotalValue}"
         //                data-code="${row.Code}"
         //                data-action="refundReasons">
         //                   ${this.i18nService.getTranslation('TextActionsButtonPixRefundReasons')}
         //             </a>
         //       </li>
         //       `;
         // }

         cell = this.buildDropdownActionButton(menuItems, false);

         return cell.trim();
      }
      return data;
   }

   renderNfeActionButtons(data, datafield, type, row) {
      let menuItems = '';
      if (type === 'display') {
         let cell = '';

         if (row.StatusCode == NfeStatus.Authorized) {
            let menuComplementary = `<li><a id="${row.Id}" class="nfe-action-button" data-companyId="${
               row.CompanyId
            }" data-action="complementary">${this.i18nService.getTranslation('Complementary')}</a></li>`;
            if (row.IsComplementaryNfe) {
               menuComplementary = '';
            }
            menuItems = `
        <li><a id="${row.Id}" class="nfe-action-button" data-companyId="${
               row.CompanyId
            }" data-action="danfe">${this.i18nService.getTranslation('DanfeDownload')}</a></li>
        <li><a id="${row.Id}" class="nfe-action-button" data-companyId="${
               row.CompanyId
            }" data-action="xml">${this.i18nService.getTranslation('XmlDownload')}</a></li>
            <li><a id="${row.Id}" class="nfe-action-button" data-companyId="${
               row.CompanyId
            }" data-action="sendMail">${this.i18nService.getTranslation('ResendMail')}</a></li>
            <li><a id="${row.Id}" class="nfe-action-button" data-companyId="${
               row.CompanyId
            }" data-action="correctionLetter">${this.i18nService.getTranslation('CorrectionLetter')}</a></li>
            ${menuComplementary}
            <li><a id="${row.Id}" class="nfe-action-button" data-companyId="${
               row.CompanyId
            }" data-action="devolution">${this.i18nService.getTranslation('Return')}</a></li>
            <li role="separator" class="divider"></li>
            <li><a id="${row.Id}" class="nfe-action-button" data-companyId="${
               row.CompanyId
            }" data-action="cancel">${this.i18nService.getTranslation('Cancel')}</a></li>
          `;
            cell = this.buildDropdownActionButton(menuItems, true);
         } else if (row.StatusCode == NfeStatus.NotSent || row.StatusCode == NfeStatus.Rejected) {
            menuItems = `
        <li><a id="${row.Id}" class="nfe-action-button" data-companyId="${
               row.CompanyId
            }" data-action="sendNfe">${this.i18nService.getTranslation('Send')}</a></li>
          <li><a id="${row.Id}" class="nfe-action-button" data-companyId="${
               row.CompanyId
            }" data-action="invalidate">${this.i18nService.getTranslation('Invalidate')}</a></li>
        `;
            cell = this.buildDropdownActionButton(menuItems, true);
         } else if (
            row.StatusCode == NfeStatus.WaitingForCancelation ||
            row.StatusCode == NfeStatus.WaitingForAuthorization ||
            row.StatusCode == NfeStatus.WaitingForInvalidate
         ) {
            cell += `<i id="${row.Id}" class=\'far fa-cloud-upload nfe-action-button\' data-companyId="${row.CompanyId}" data-action="sendNfe"`;
            cell += "title='" + this.i18nService.getTranslation('NfeTransmissionIconTitle') + "'></i>&nbsp;&nbsp;";
         }

         return cell.trim();
      }
      return data;
   }

   renderNfeEntryActionButtons(data, datafield, type, row) {
      let menuItems = '';
      if (type === 'display') {
         let cell = '';

         menuItems += this.addNfeEntryActionRow(row, 'xml', 'XmlDownload');

         menuItems += this.addNfeEntryActionRow(row, 'danfe', 'DanfeDownload');

         if (!row.HasUnlinkedProducts && row.StatusCode == NfeEntryStatus.NfeEntryPending) {
            menuItems += this.addNfeEntryActionRow(row, 'close', 'NfeEntryCloseOptionTitle');
         }

         cell = this.buildDropdownActionButton(menuItems, false);

         return cell.trim();
      }
      return data;
   }

   addNfeEntryActionRow(row, action, title): string {
      const newAction = `
            <li>
               <a id="${row.Id}" class="nfe-entry-action-button" data-companyId="${row.CompanyId}"
                  data-accesskey="${row.AccessKey}" data-action="${action}">
                  ${this.i18nService.getTranslation(title)}
               </a>
            </li>
            `;
      return newAction;
   }

   renderNfeResumeActionButtons(data, datafield, type, row) {
      let menuItems = '';
      if (type === 'display') {
         let cell = '';

         if (row.IsNfeEntryAvailable) {
            menuItems += this.addNfeResumeActionRow(row, 'import', 'NfeResumeImportNfeEntry');
         }

         cell = this.buildDropdownActionButton(menuItems, false);

         return cell.trim();
      }
      return data;
   }

   addNfeResumeActionRow(row, action, title): string {
      const newAction = `
            <li>
               <a id="${row.Id}" class="nfe-resume-action-button" data-companyId="${row.CompanyId}"
                  data-action="${action}">
                  ${this.i18nService.getTranslation(title)}
               </a>
            </li>
            `;
      return newAction;
   }

   renderUserActionButtons(data, datafield, type, row) {
      if (type === 'display') {
         const hasPermission = this.authService.hasPermission('UserProfile', AccessType.Update);
         let menuItem: string;
         if (hasPermission) {
            menuItem = `
          <li><a id="${row.Id}" class="user-action-button" data-name="${row.Name}" data-companyId="${
               row.CompanyId
            }" data-action="generatePasswordRedefinitionLink"><i class="far fa-key"></i> ${this.i18nService.getTranslation(
               'GeneratePasswordRedefinitionLink'
            )}</a></li>`;
         } else {
            menuItem = '<li>' + this.i18nService.getTranslation('NoActionsForThisRegister') + '</li>';
         }

         const cell = this.buildDropdownActionButton(menuItem);
         return cell.trim();
      }
      return data;
   }

   public buildDropdownActionButton(menuItems: string, dropup: boolean = false) {
      return `
    <div class="${dropup ? 'dropup' : 'dropdown'}">
      <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        ${this.i18nService.getTranslation('Actions')}
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu dropdown-menu-grid dropdown-menu-right table-dropdown-menu">
        ${menuItems}
      </ul>
    </div>
    `;
   }

   renderAccountantRemoveButton(data, type, row) {
      if (type === 'display') {
         return "<i class='far fa-times remove-button' aria-hidden='true'></i>";
      }
      return data;
   }

   renderProductPriceHistoryButton(data, type, row) {
      if (type === 'display') {
         return (
            "<i class='fas fa-history product-price-history-button' data-priceTableId=\"" +
            row.PriceTableId +
            '" data-companyId="' +
            row.CompanyId +
            "\" aria-hidden='true'></i>"
         );
      }
      return data;
   }

   renderOrderCenterListOrderImported(data, type, row) {
      if (type === 'display') {
         if (row.SaleId) {
            return `${row.StatusName}
                <span class="kcms-tag-component kcms-tag-component-list-row">POS</span>
                `;
         } else {
            return `${row.StatusName}`;
         }
      }
      return data;
   }

   renderBooleanImage(data, dataField, type, useYesOrNo) {
      return data ? `<i class="far fa-check" aria-hidden="true" />` : '';
   }

   renderAddEditRemoveButton(data, type, row) {
      if (type === 'display') {
         let hasCreatePermission = this.authService.hasPermission(this.accessDomain, AccessType.Create);
         let hasUpdatePermission = this.authService.hasPermission(this.accessDomain, AccessType.Update);

         let addIcon = row.Level < 3 && hasCreatePermission ? 'add-button' : 'ignore-button';
         let editIcon = hasUpdatePermission ? 'fa-edit edit-button' : 'fa-search edit-button';
         let removeIcon = false && hasUpdatePermission ? 'remove-button' : 'ignore-button';

         return `
        <div class="add-edit-remove-buttons flex">
          <i class=\'fa-light fa-plus ${addIcon}\' aria-hidden=\'true\'></i>
          <i class=\'fa-light fa-pen-to-square ${editIcon}\' aria-hidden=\'true\'></i>
          <i class=\'fa-light fa-xmark ${removeIcon}\' aria-hidden=\'true\'></i>
        </div>
      `;
      }
      return data;
   }

   renderShowChildrenButton(data, type, row) {
      if (type === 'display') {
         let icon = row.HasChild ? 'open-close' : 'ignore-button';
         let iconClick = row.HasChild ? 'onclick="openCloseRowChildren(this);"' : '';

         return `
        <span class='show-children-button' data-is-closed='false' data-id='${row.Id}' data-parent-id='${row.ParentId}'>
          <span class='level-${row.Level}'></span>
          <i class=\'fas fa-minus-circle ${icon}\' aria-hidden=\'true\' ${iconClick}></i>
          ${data}
        </span>
      `;
      }
      return data;
   }

   getProperties(lstProperties, data, parentProp) {
      if (typeof data === 'object') {
         for (const prop in data) {
            if (Object.prototype.hasOwnProperty.call(data, prop)) {
               if (typeof data === 'object') {
                  this.getProperties(lstProperties, data[prop], parentProp ? parentProp + '.' + prop : prop);
               }
            }
         }
      } else {
         lstProperties.push({ property: parentProp, data: data });
      }
   }

   renderBankAccountActionButtons(data, type, row) {
      if (type === 'display') {
         let updatePermission = this.authService.getPermissionType(this.accessDomain, AccessType.Update, row.CompanyId);
         let balance = row.CurrentBalance !== undefined && row.CurrentBalance !== null ? row.CurrentBalance : 0;

         let menuUpdate = !updatePermission
            ? ''
            : `
        <li class="bank-account-action-button"><a id="${row.Id}" data-name="${row.Name}" data-companyId="${
                 row.CompanyId
              }" data-balance="${balance}" data-action="edit-initial-balance"><i class="far fa-dollar-sign"></i> ${this.i18nService.getTranslation(
                 'BankAccountEditInitialBalance'
              )}</a></li>
        <li class="bank-account-action-button"><a id="${row.Id}" data-name="${row.Name}" data-companyId="${
                 row.CompanyId
              }" data-balance="${balance}" data-action="edit-current-balance"><i class="far fa-check"></i> ${this.i18nService.getTranslation(
                 'BankAccountEditCurrentBalance'
              )}</a></li>
      `;

         let menuTransf = `<li class="bank-account-action-button"><a id="${row.Id}" data-name="${row.Name}" data-companyId="${
            row.CompanyId
         }" data-balance="${balance}" data-action="transaction"><i class="far fa-list"></i> ${this.i18nService.getTranslation(
            'BankAccountTransactionList'
         )}</a></li>`;
         let menuItem = menuUpdate + menuTransf;

         const cell = this.buildDropdownActionButton(menuItem);
         return cell.trim();
      }
      return data;
   }

   renderCostCenterActionButtons(data, type, row) {
      if (type === 'display') {
         let updatePermission = this.authService.getPermissionType(this.accessDomain, AccessType.Update, row.CompanyId);

         let menuItem = !updatePermission
            ? ''
            : `
        <li class="cost-center-action-button"><a id="${row.Id}" data-name="${row.Name}" data-companyId="${
                 row.CompanyId
              }" data-action="remove"><i class="far fa-times"></i> ${this.i18nService.getTranslation('Remove')}</a></li>
      `;

         const cell = this.buildDropdownActionButton(menuItem);
         return cell.trim();
      }
      return data;
   }

   renderFinanceAccountActionButtons(data, type, row) {
      if (type === 'display') {
         const updatePermission = this.authService.getPermissionType(this.accessDomain, AccessType.Update, row.CompanyId);

         const editMenu = this.buildFinanceAccountEditMenu(row, updatePermission);
         const removeMenu = this.buildFinanceAccountRemoveMenu(row, updatePermission);
         const efetivarMenu = this.buildFinanceAccountEfetivarMenu(row, updatePermission);

         const canPrint = false; // row.IsClosed && !row.Origin && (row.CustomerId || row.SupplierId);

         const menuImprimir = !canPrint
            ? ''
            : `<li class="finance-account-action-button"><a id="${row.Id}" data-name="${row.Name}" data-companyId="${
                 row.CompanyId
              }" data-transaction="${row.Transaction}" data-origin="${
                 row.Origin
              }" data-action="print"><i class="far fa-print"></i> ${this.i18nService.getTranslation('PrintReceipt')}</a></li>`;

         const menuItem = editMenu + removeMenu + efetivarMenu + menuImprimir;

         const cell = this.buildDropdownActionButton(menuItem);
         return cell.trim();
      }
      return data;
   }

   private buildFinanceAccountEditMenu(row, updatePermission) {
      if (!updatePermission) {
         return '';
      }

      const editLabel = row.IsClosed || row.Origin ? 'FinanceAccountSee' : 'FinanceAccountEdit';

      const editOption = `
         <li class="finance-account-action-button">
            <a id="${row.Id}" data-name="${row.Name}"
               data-companyId="${row.CompanyId}"
               data-transaction="${row.Transaction}"
               data-origin="${row.Origin}"
               data-action="edit">
                  <i class="far fa-edit"></i>
                  ${this.i18nService.getTranslation(editLabel)}
            </a>
         </li>`;

      return editOption;
   }

   private buildFinanceAccountRemoveMenu(row, updatePermission) {
      if (!updatePermission || row.Origin) {
         return '';
      }

      const removeOption = `
         <li class="finance-account-action-button">
            <a id="${row.Id}" data-name="${row.Name}"
               data-companyId="${row.CompanyId}"
               data-transaction="${row.Transaction}"
               data-origin="${row.Origin}"
               data-action="remove">
               <i class="far fa-times"></i>
               ${this.i18nService.getTranslation('Remove')}
            </a>
         </li>`;

      return removeOption;
   }

   private buildFinanceAccountEfetivarMenu(row, updatePermission) {
      if (!updatePermission || row.IsClosed) {
         return '';
      }

      const efetivarOption = `
            <li class="finance-account-action-button">
               <a id="${row.Id}" data-name="${row.Name}"
                  data-companyId="${row.CompanyId}"
                  data-transaction="${row.Transaction}"
                  data-origin="${row.Origin}" data-action="effective">
                  <i class="far fa-check"></i>
                     ${this.i18nService.getTranslation('Effective')}
               </a>
            </li>`;

      return efetivarOption;
   }

   getRowClicked(data) {
      this.onClickRow.emit(data);
   }

   getDataToRemove(data) {
      this.onClickRemove.emit(data);
   }

   getDataToPrinter(data) {
      this.onClickPrinter.emit(data);
   }

   getDataToDownload(data) {
      this.onClickDownload.emit(data);
   }

   getDataToOrder(data) {
      this.onClickOrder.emit(data);
   }

   getDataToChangeProductAction(data) {
      this.onGridClickProductAction.emit(data);
   }

   getDataToChangeNfeAction(data) {
      this.onGridClickNfeAction.emit(data);
   }

   getDataToChangeNfeEntryAction(data) {
      this.onGridClickNfeEntryAction.emit(data);
   }

   getDataToChangePix(data) {
      this.onGridClickPix.emit(data);
   }

   getDataToChangeNfeResumeAction(data) {
      this.onGridClickNfeResumeAction.emit(data);
   }

   getDataToChangeOrderCheckAtion(data) {
      this.onClickOrderCheckAction.emit(data);
   }

   getDataToChangeWaitlistAction(data) {
      this.onClickWaitlistAction.emit(data);
   }

   getDataToRemoveAccountant(data) {
      this.onClickAccountantRemove.emit(data);
   }

   getDataToChangeUserAction(data) {
      this.onGridClickUserAction.emit(data);
   }

   getProductPriceHistoryData(data) {
      this.onGridClickProductPriceHistory.emit(data);
   }

   refreshList(): void {
      this.datatableComponent.refreshList();
   }

   search(delay: number = 0): void {
      setTimeout(() => {
         this.createGrid();
      }, delay);
   }

   redrawList(): void {
      this.datatableComponent.redrawList();
   }

   getBooleanText(field: string, value: boolean, useYesOrNo: boolean): string {
      if (useYesOrNo) {
         return value ? 'Yes' : 'No';
      }

      switch (field) {
         case 'IsEntry':
            return value ? 'Entry' : 'Exit';
         case 'IsActive':
            return value ? 'Active' : 'Inactive';
         case 'IsProdEnvironment':
            return value ? 'Production' : 'Homologation';
         case 'UploadOnlySalesData':
            return value ? 'SalesOnly' : 'FullF';
         case 'IsDelivery':
            return value ? 'Delivery' : 'Pickup';
         case 'IsToGo':
            return value ? 'Pickup' : 'Delivery';
         case 'IsPosWaiter':
            return value ? 'PosWaiter' : 'Pos';
         case 'IsEnabled':
         case 'IsMenuServiceEnabled':
            return value ? 'Enabled' : 'Disabled';

         default:
            return value ? 'Yes' : 'No';
      }
   }

   getBooleanTextExcel(value: boolean): string {
      return value ? 'Active' : 'Inactive';
   }

   getDataToChangeBankAccountAction(data) {
      this.onGridClickBankAccountAction.emit(data);
   }

   getDataToChangeCostCenterAction(data) {
      this.onGridClickCostCenterAction.emit(data);
   }

   getDataToChangeFinanceAccountAction(data) {
      this.onGridClickFinanceAccountAction.emit(data);
   }

   getDataToAddEditRemove(data) {
      this.onClickAddEditRemove.emit(data);
   }
}
