import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { FormsModule } from '@angular/forms';
import { KcmsDecimalInputComponent } from './decimal-input.component';
import { CurrencyMaskModule } from "ng2-currency-mask";


@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    FormsModule,
    CurrencyMaskModule,
  ],
  declarations: [
    KcmsDecimalInputComponent,
  ],
  exports: [
    KcmsDecimalInputComponent,
  ],
  providers: [
    CurrencyPipe
  ]
})
export class KcmsDecimalInputModule { }
