import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from 'app/api/api.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Plan } from '../models/plan.model';
import { Trial } from '../models/trial.model';
import { PlanService } from './plan.service';

@Injectable()
export class TrialService {
   domainName = 'Trial';
   addedMessage = 'TrialAdded';
   updatedMessage = 'TrialUpdated';
   endedMessage = 'TrialEnded';
   trialEndedMessage = 'TrialEndedUser';

   listPlansEventEmitter = new EventEmitter();

   constructor(private apiService: ApiService, private planService: PlanService) { }

   listPlansTrial(form: any) {
      this.planService.getAll().subscribe((ret) => {
         let list = this.filterPlans(ret)
            .filter((item) => item.ShortName !== 'Start')
            .sort((a, b) => {
               if (a.Price > b.Price) {
                  return 1;
               }
               if (a.Price < b.Price) {
                  return -1;
               }
               return 0;
            });

         form.listPlans = list;
         this.listPlansEventEmitter.emit(form.listPlans);
      });
   }

   listPlans(): Observable<Plan[]> {
      return this.planService.getAll().pipe(map((res) => this.filterPlans(res)));
   }

   private filterPlans(plans: Plan[]): Plan[] {
      if (plans) {
         let Description = '';
         let ShortName = '';
         plans.forEach((plan) => {
            switch (plan.Name) {
               case 'KCMS One':
                  Description = 'Ideal para pessoa física ou MEI';
                  ShortName = 'One';
                  break;
               case 'KCMS Essential':
                  Description = 'Iniciando o controle do negócio';
                  ShortName = 'Essential';
                  break;
               case 'KCMS Small':
                  Description = 'Ideal para negócios em crescimento';
                  ShortName = 'Small';
                  break;
               case 'KCMS Pro':
                  Description = 'Ideal para negócios com maior demanda';
                  ShortName = 'Pro';
                  break;
               case 'KCMS Start':
                  Description = 'Ideal para quem está começando';
                  ShortName = 'Start';
                  break;
            }
            plan.TargetAudience = Description;
            plan.ShortName = ShortName;
            plan.CodePlanHirePlan = plan.IntegrationId;
            plan.IsEnabled = plan.Name === localStorage.getItem('company_plan') ? false : true;
         });
      }
      return plans;
   }

   save(trial: Trial): Observable<any> {
      if (trial.Id && trial.Id.length > 0) {
         return this.apiService.put(this.domainName, trial, this.updatedMessage);
      } else {
         return this.apiService.post(this.domainName, trial, this.addedMessage);
      }
   }

   getTrialByCompanyId(companyId: Number): Observable<Trial> {
      return this.apiService.get(`${this.domainName}/Company/${companyId}`);
   }

   acknowledgeTrialEnded(trialId: string): Observable<any> {
      return this.apiService.put(`${this.domainName}/AcknowledgeTrialEnded/${trialId}`, null, this.trialEndedMessage);
   }

   getTrialRemainingNumberOfDays(trialEndDate: Date): number {
      const trialCurrentDate = new Date();
      const day = String(trialCurrentDate.getDate()).padStart(2, '0');
      const month = String(trialCurrentDate.getMonth() + 1).padStart(2, '0');
      const year = trialCurrentDate.getFullYear();
      const currentDate = day + '/' + month + '/' + year;

      const start = moment(currentDate, 'DD/MM/YYYY');
      const end = moment(trialEndDate, 'DD/MM/YYYY');

      const numberOfDays = end.diff(start, 'days');

      return numberOfDays;
   }

   listTrials(): Observable<Trial[]> {
      return this.apiService.get(`${this.domainName}/Trials`);
   }
}
