import { Injectable } from '@angular/core';
import { Address } from 'app/domain/models/address.model';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';

@Injectable()
export class AddressService {
   constructor() {}

   transform(address: GoogleAddress): Address {
      if (!address || !address.address_components) {
         return null;
      }

      const ret = new Address();

      if (address.geometry && address.geometry.location) {
         ret.Latitude = address.geometry.location.lat();
         ret.Longitude = address.geometry.location.lng();
      }

      ret.FormattedAddress = address.formatted_address;

      address.address_components.forEach((addressComponent) => {
         if (addressComponent.types && addressComponent.types.length > 0) {
            switch (addressComponent.types[0]) {
               case 'route':
                  ret.AddressLine1 = addressComponent.long_name;
                  break;
               case 'street_number':
                  ret.AddressNumber = addressComponent.long_name;
                  break;
               case 'sublocality_level_1':
               case 'political':
                  ret.Neighborhood = addressComponent.long_name;
                  break;
               case 'administrative_area_level_2':
                  ret.CityName = addressComponent.long_name;
                  break;
               case 'administrative_area_level_1':
                  ret.StateAcronym = addressComponent.short_name;
                  ret.StateName = addressComponent.long_name;
                  break;
               case 'country':
                  ret.CountryAcronym = addressComponent.short_name;
                  ret.CountryName = addressComponent.long_name;
                  break;
               case 'postal_code':
                  ret.ZipCode = addressComponent.long_name;
                  break;
            }
         }
      });

      return ret;
   }

   formatAddress(address): string {
      if (!address) {
         return null;
      }

      let formattedAddress = '';
      if (address.AddressLine1) {
         formattedAddress = address.AddressLine1;
      }
      if (address.AddressNumber) {
         formattedAddress += (formattedAddress.length > 0 ? ', ' : '') + address.AddressNumber;
      }
      if (formattedAddress.length > 0) {
         formattedAddress += ' - ';
      }
      if (address.Neighborhood) {
         formattedAddress += address.Neighborhood + ', ';
      }
      if (address.CityName && address.StateAcronym) {
         formattedAddress += address.CityName + ' - ' + address.StateAcronym + ', ';
      } else if (address.CityName) {
         formattedAddress += address.CityName + ', ';
      } else if (address.StateAcronym) {
         formattedAddress += address.StateAcronym + ', ';
      }
      if (address.ZipCode) {
         formattedAddress += address.ZipCode;
      }

      if (formattedAddress.length > 3 && formattedAddress.substr(formattedAddress.length - 2, 2) === ', ') {
         formattedAddress = formattedAddress.substr(0, formattedAddress.length - 2);
      } else if (formattedAddress.length > 3 && formattedAddress.substr(formattedAddress.length - 3, 3) === ' - ') {
         formattedAddress = formattedAddress.substr(0, formattedAddress.length - 3);
      }

      return formattedAddress;
   }

   formatAddressNotNumber(address): string {
      if (!address) {
         return null;
      }

      let formattedAddress = '';
      if (address.AddressLine1) {
         formattedAddress = address.AddressLine1;
      }
      if (formattedAddress.length > 0) {
         formattedAddress += ' - ';
      }
      if (address.Neighborhood) {
         formattedAddress += address.Neighborhood + ', ';
      }
      if (address.CityName && address.StateAcronym) {
         formattedAddress += address.CityName + ' - ' + address.StateAcronym + ', ';
      } else if (address.CityName) {
         formattedAddress += address.CityName + ', ';
      } else if (address.StateAcronym) {
         formattedAddress += address.StateAcronym + ', ';
      }
      if (address.ZipCode) {
         formattedAddress += address.ZipCode;
      }

      if (formattedAddress.length > 3 && formattedAddress.substr(formattedAddress.length - 2, 2) === ', ') {
         formattedAddress = formattedAddress.substr(0, formattedAddress.length - 2);
      } else if (formattedAddress.length > 3 && formattedAddress.substr(formattedAddress.length - 3, 3) === ' - ') {
         formattedAddress = formattedAddress.substr(0, formattedAddress.length - 3);
      }

      return formattedAddress;
   }
}
