import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
   selector: 'kcms-ifood-menu-how-to-modal',
   templateUrl: './ifood-menu-how-to-modal.component.html',
   styleUrls: ['./ifood-menu-how-to-modal.component.scss'],
})
export class IfoodMenuHowToModalComponent implements OnInit {
   public modalRef: BsModalRef;
   public isModalOpened: boolean;
   public step = 1;

   @ViewChild('importIfoodMenuHowToModal', { static: true }) importIfoodMenuHowToModal: TemplateRef<any>;

   constructor(private modalService: BsModalService) {}

   ngOnInit() {}

   openIfoodMenuHowToModal() {
      const config = new ModalOptions();
      config.class = 'modal-dialog-centered';
      this.modalRef = this.modalService.show(this.importIfoodMenuHowToModal, config);
      this.isModalOpened = true;
   }

   closeModal() {
      this.modalRef.hide();
   }

   nextStep() {
      this.step += 1;
   }

   previusStep() {
      this.step -= 1;
   }
}
