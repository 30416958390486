import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
   // tslint:disable-next-line: component-selector
   selector: 'kcms-hire-plan-logout',
   templateUrl: './hire-plan-logout.component.html',
   styleUrls: ['./hire-plan-logout.component.scss'],
})
export class HirePlanLogoutComponent implements OnInit {
 
    public modalRef: BsModalRef;

    @ViewChild('logoutModal', { static: true }) public logoutModal: TemplateRef<any>;

    constructor(
        private modalService: BsModalService,
        private authService: AuthService,
    ) { }

    ngOnInit() { }

    openModal() {
        const config = new ModalOptions();
        config.ignoreBackdropClick = true;
        config.keyboard = false;
        config.class = 'modal-dialog-centered'
        this.modalRef = this.modalService.show(this.logoutModal, config);
    }

    logout() {
        this.modalRef.hide(); 
        this.authService.logout();
    }
}