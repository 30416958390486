import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { AuthService, CompanyToken, CompanyTokenItem } from 'app/auth/auth.service';
import { Company } from 'app/domain/models/company.model';
import { Group } from 'app/domain/models/group.model';
import { CompanyService } from 'app/domain/services/company.service';
import { GridListComponent } from 'app/shared/kcms/grid-list/grid-list.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
   selector: 'kcms-company-select',
   templateUrl: 'company-select.component.html',
   styleUrls: ['./company-select.component.scss'],
})
export class CompanySelectComponent implements OnInit, OnDestroy {
   public showCompanySelection = false;
   public authorizationTokenObservable: Subscription;
   public shortCompanyName: boolean;
   public companiesName = new Array<string>();
   public names: any;
   public modalRef: BsModalRef;
   public selectedCompanies: CompanyToken;
   public allCompanies: CompanyTokenItem[];
   public isSaving = false;
   public isHidden: boolean = true;
   public hidenSelectCompany = false;

   gridConfig: any = {
      columns: [
         {
            dataField: 'SimplifiedId',
            headerText: '#',
            dataClass: 'center',
            className: 'dt-center',
            dataType: 'multiselect',
            columnStyle: { width: '5%' },
         },
         {
            dataField: 'SimplifiedId',
            headerText: 'Code',
            dataType: 'string',
            dataClass: 'center',
            className: 'dt-center',
            columnStyle: { width: '10%' },
         },
         {
            dataField: 'Name',
            headerText: 'Name',
            dataType: 'string',
            dataClass: 'expand',
            columnStyle: { width: '85%' },
         },
      ],
      data: [],
      dataCheck: {},
   };

   @Input() maxCompanyNames = 1;
   @Input() gridDataConfig: any[];
   @Input() companyGroup: Group;

   @Output() onCompanyChange = new EventEmitter();

   constructor(public modalService: BsModalService, public authService: AuthService, public companyService: CompanyService) {
      this.selectedCompanies = new CompanyToken();
   }

   ngOnInit() {
      this.authorizationTokenObservable = this.authService.authorizationTokenObservable.subscribe((val) => {
         if (val) {
            this.updateCompanyNames();
         }
      });
   }

   ngOnDestroy() {
      this.authorizationTokenObservable?.unsubscribe();
   }

   updateCompanyNames(): void {
      const companies = this.authService.getCompanies();

      this.buildCompanyNames(companies.CompanyItems);
      this.showCompanySelection = companies.ShowCompanySelection;
   }

   buildCompanyNames(companyItems) {
      this.companiesName = [];

      for (let i = 0; i < companyItems.length; i++) {
         this.companiesName.push(companyItems[i].Name);
      }

      this.shortCompanyName = this.companiesName.length > this.maxCompanyNames ? true : false;
      this.names = this.companiesName.slice(0, this.maxCompanyNames).join(' , ');
   }

   public createDataCheckObject(): any {
      const companyIds = new Array<number>();

      this.selectedCompanies.CompanyItems.forEach((company) => {
         companyIds.push(company.CompanyId);
      });

      return {
         checkBy: 'SimplifiedId',
         values: companyIds,
      };
   }

   openCompaniesModal(template: TemplateRef<any>, companyModal: TemplateRef<GridListComponent>) {
      this.companyService.getUserCompanies().subscribe((ret) => {
         this.allCompanies = ret;
      });

      this.selectedCompanies = this.authService.getCompanies();
      this.gridConfig.dataCheck = this.createDataCheckObject();

      const config = new ModalOptions();
      config.ignoreBackdropClick = true;
      config.class = 'modal-lg';

      this.modalRef = this.modalService.show(template, config);
   }

   confirmModal() {
      this.isSaving = true;
      this.authService.setCompanies(this.selectedCompanies, true).then((ret) => {
         this.onCompanyChange.emit();
         this.modalRef.hide();
         this.isSaving = false;
      });
   }

   onClickRow(rowData) {
      this.addRemoveCompany(rowData, rowData.IsChecked);
      this.gridConfig.dataCheck = this.createDataCheckObject();
   }

   addRemoveCompany(company: Company, isChecked: boolean) {
      let currCompanyIndex = null;

      for (let i = 0; i < this.selectedCompanies.CompanyItems.length; i++) {
         if (this.selectedCompanies.CompanyItems[i].CompanyId === company.SimplifiedId) {
            currCompanyIndex = i;
            break;
         }
      }

      if (isChecked) {
         // Add Company (if not exists)
         if (currCompanyIndex == null) {
            this.selectedCompanies.CompanyItems.push(
               new CompanyTokenItem(company.SimplifiedId, company.Name, company.HasChildren, company.ParentId)
            );
         }
      } else {
         // Remove Company (if exists)
         if (currCompanyIndex != null) {
            this.selectedCompanies.CompanyItems.splice(currCompanyIndex, 1);
         }
      }
   }

   checkAllCompanies(isCheck: boolean) {
      this.selectedCompanies.CompanyItems = [];

      if (isCheck && this.allCompanies) {
         this.allCompanies.forEach((company) => {
            this.selectedCompanies.CompanyItems.push(company);
         });
      }

      this.gridConfig.dataCheck = this.createDataCheckObject();
   }
}
