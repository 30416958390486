import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DatatableComponent } from "./datatable.component";
import { LoadingModule } from "app/shared/kcms/loading/loading.module";

// require('smartadmin-plugins/bower_components/datatables.net-colreorder-bs/css/colReorder.bootstrap.min.css');

@NgModule({
  imports: [CommonModule, LoadingModule],
  declarations: [DatatableComponent],
  exports: [DatatableComponent],
})
export class SmartadminDatatableModule {}
