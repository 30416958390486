import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PixAccountStatementSummaryResponse } from 'app/domain/models/pix-integration.model.js';
import { NotificationService } from 'app/shared/utils/notification.service';
import * as jqueryOdata from '../../../../assets/js/jquery.dataTables.odata.js';
declare var $: any;

@Component({
   selector: 'sa-datatable',
   templateUrl: './datatable.component.html',
})
export class DatatableComponent implements OnInit {
   @Input() filter: any;
   @Input() keepFilters = false;
   @Input() hasFooter = true;
   @Input() detailsFormat: any;
   @Input() hasRemoveButton = false;

   @Input() hasOrderCheckPrinterQrCode = false;
   @Input() hasOrderCheckDownloadQrCode = false;
   @Input() hasPixActionButtons = false;

   @Input() hasAddEditRemoveButton = false;
   @Input() hasBankAccountActionButtons = false;
   @Input() hasCostCenterActionButtons = false;
   @Input() hasFinanceAccountActionButtons = false;
   @Input() hasOrderButtons = false;
   @Input() hasProductActionButtons = false;
   @Input() hasNfeActionButtons = false;
   @Input() hasNfeEntryActionButtons = false;
   @Input() hasNfeResumeActionButtons = false;
   @Input() hasWaitlistActionButtons = false;
   @Input() hasAccountantRemoveButton = false;
   @Input() hasOrderCheckActionButtons = false;
   @Input() hasUserActionButtons = false;
   @Input() hasProductPriceHistoryButton = false;
   @Input() removeButtonConfirmTitle: string;
   @Input() removeButtonConfirmMessage: string;
   @Input() detailUrl: string;
   @Input() paginationLength = true;
   @Input() loadOnInit = false;
   @Input() loadOnFilter = false;
   @Input() columnsHide: boolean;
   @Input() tableClass: string;
   @Input() width = '100%';
   @Input() sds: boolean;
   @Input() multiSelect: boolean;
   @Input() idForExportXLS: string;

   _dataCheck: any;
   @Input()
   get dataCheck() {
      return this._dataCheck;
   }
   set dataCheck(val: any) {
      this._dataCheck = val;
      this.checkAllMultiselect();
   }

   _options: any;
   @Input()
   get options() {
      return this._options;
   }
   set options(val) {
      this._options = val;

      if (val && this.scriptLoaded) {
         if (this._datatables) {
            this.redrawList();
         } else {
            this.render();
         }
      }

      this.optionsLoaded = true;
   }

   @Output() onClickRow = new EventEmitter();
   @Output() onClickRemove = new EventEmitter();

   @Output() onClickPrinter = new EventEmitter();
   @Output() onClickDownload = new EventEmitter();

   @Output() onClickAddEditRemove = new EventEmitter();
   @Output() onClickBankAccountAction = new EventEmitter();
   @Output() onClickCostCenterAction = new EventEmitter();
   @Output() onClickFinanceAccountAction = new EventEmitter();
   @Output() onClickOrder = new EventEmitter();
   @Output() onClickProductAction = new EventEmitter();
   @Output() onClickNfeAction = new EventEmitter();
   @Output() onClickNfeEntryAction = new EventEmitter();
   @Output() onClickNfeResumeAction = new EventEmitter();
   @Output() onClickWaitlistAction = new EventEmitter();
   @Output() onClickAccountantRemove = new EventEmitter();
   @Output() onClickOrderCheckAction = new EventEmitter();
   @Output() onClickProductPriceHistory = new EventEmitter();
   @Output() onClickUserAction = new EventEmitter();
   @Output() onClickPixAction = new EventEmitter();
   @Output() datatablesOnInit = new EventEmitter();
   @Output() onLoaded = new EventEmitter();

   public _datatables: any;
   public scriptLoaded = false;
   public optionsLoaded = false;

   constructor(
      public el: ElementRef,
      private cdr: ChangeDetectorRef,
      public router: Router,
      public notificationService: NotificationService
   ) {}

   ngOnInit() {
      Promise.all([import('smartadmin-plugins/datatables/datatables.min.js')]).then(() => {
         if (this.optionsLoaded && (this.loadOnInit || this.options)) {
            this.render();
         }
         this.scriptLoaded = true;
      });
   }

   render(option?) {
      const self = this;

      const element = $(this.el.nativeElement.children[0]);
      let options = this.options || {};
      if (option) {
         options = option;
      }

      let toolbar = '';
      if (options.buttons) {
         toolbar += 'B';
      }
      if (this.paginationLength) {
         toolbar += 'l';
      }
      if (this.columnsHide) {
         toolbar += 'C';
      }

      if (typeof options.ajaxUrl === 'string') {
         const url = options.ajaxUrl;
         options.iODataVersion = 4;
         options.sAjaxSource = url;
         options.fnServerData = jqueryOdata; // required
         options.bServerSide = true;
      }

      if (this.keepFilters) {
         const initialPage = sessionStorage.getItem('sSearch_PageIndex');
         if (initialPage) {
            options.displayStart = parseInt(initialPage, 10) * 10;
         }
      }

      if (this.multiSelect) {
         options = $.extend(options, {
            select: {
               style: 'multi',
            },
         });
      }

      let $toolbarFooter = '';

      if (this.hasFooter) {
         if (this.paginationLength.toString() === 'true') {
            // tslint:disable-next-line:max-line-length
            $toolbarFooter = "<'dt-toolbar-footer '<'info-footer'li><'info-footer dtatablesfooter'p>>";
         } else {
            $toolbarFooter = "<'dt-toolbar-footer '<'info-footer'i><'info-footer dtatablesfooter'p>>";
         }
      }

      options = $.extend(options, {
         dom: 'tr' + $toolbarFooter,
         oLanguage: {
            sEmptyTable: this.loadOnFilter ? 'Preencha um ou mais filtros para buscar os registros.' : 'Nenhum registro encontrado.',
            sInfo: '_START_ até _END_ de _TOTAL_ registros',
            sInfoEmpty: '0 até 0 de 0 registros',
            sInfoFiltered: '(Filtrados de _MAX_ registros)',
            sInfoPostFix: '',
            sInfoThousands: '.',
            sLengthMenu: '_MENU_',
            sLoadingRecords: 'Carregando...',
            sProcessing: " <i class='fa-light fa-spinner-third  margin-right-md fa-spin'></i>",
            sZeroRecords: this.loadOnFilter ? 'Preencha um ou mais filtros para buscar os registros.' : 'Nenhum registro encontrado.',
            sSearch: "<span class='input-group-addon'><i class='glyphicon glyphicon-search'></i></span> ",
            oPaginate: {
               sNext: '>',
               sPrevious: '<',
               sFirst: '<<',
               sLast: '>>',
            },
            oAria: {
               sSortAscending: ': Ordenar colunas de forma ascendente',
               sSortDescending: ': Ordenar colunas de forma descendente',
            },
            select: {
               rows: '',
            },
         },

         autoWidth: true,
         retrieve: true,
         responsive: false,
         bScrollCollapse: true,

         fixedHeader: true,
         initComplete: (settings, json) => {
            element.parent().find('.input-sm').removeClass('input-sm').addClass('input-md');
            this.datatablesOnInit.emit();
         },
         fnRowCallback: function (nRow, aData, iDisplayIndex) {
            // Callback for each row created
            if (self.dataCheck && self.multiSelect) {
               // Check all rows by default if none row selected
               const checkProp = self.dataCheck['checkBy'];
               // Append the grade to the default row class name
               const $ch = $(nRow).find('input:checkbox');
               const $tr = $(nRow).closest('tr');
               if (aData.RowStyle) {
                  $tr.addClass(aData.RowStyle);
               }
               if (self.dataCheck.values.includes(aData[checkProp])) {
                  $ch.prop('checked', true);
                  $tr.addClass('selected');
               } else {
                  $ch.prop('checked', false);
                  $tr.removeClass('selected');
               }
            }

            this.onLoaded.emit();
         }.bind(this),
      });

      const pageLength = sessionStorage.getItem('sSearch_PageLength');
      if (pageLength) {
         options.pageLength = pageLength;
      }

      const _dataTable = element.DataTable(options);
      const _keepFilters = this.keepFilters;
      this._datatables = _dataTable;

      if (this.loadOnFilter) {
         setTimeout(() => {
            _dataTable.settings()[0].oLanguage.sEmptyTable = 'Nenhum registro encontrado.';
         }, 500);
      }

      element.off('draw.dt');
      if (this.keepFilters) {
         element.on(
            'draw.dt',
            function () {
               const info = this._datatables.page.info();
               sessionStorage.setItem('sSearch_PageIndex', info.page);
            }.bind(this)
         );
      }

      if (this.filter) {
         const delay = (function () {
            let timer: any = 0;
            return function (callback, ms) {
               clearTimeout(timer);
               timer = setTimeout(callback, ms);
            };
         })();

         // Apply the filter
         element.off('keyup change', 'thead th input[type=text]');
         element.on('keyup change', 'thead th input[type=text]', function (event) {
            if (event && (!event.keyCode || event.keyCode === 9 || event.keyCode === 11)) {
               return;
            }

            if (_keepFilters) {
               sessionStorage.setItem('sSearch_' + $(this).parent().index(), this.value);
            }

            $(this).on('blur', function () {
               _dataTable.column($(this).parent().index() + ':visible').search(this.value);
            });

            delay(
               function () {
                  $(this).off('blur');
                  _dataTable
                     .column($(this).parent().index() + ':visible')
                     .search(this.value)
                     .draw();
               }.bind(this),
               1200
            );
         });

         element.off('change', 'thead th .dropdown-filter');
         element.on('change', 'thead th .dropdown-filter', function () {
            if (_keepFilters) {
               sessionStorage.setItem('sSearch_' + $(this).parent().index(), this.value);
            }
            _dataTable
               .column($(this).parent().index() + ':visible')
               .search(this.value)
               .draw();
         });

         element.off('length.dt');
         if (_keepFilters) {
            element.on('length.dt', function (e, settings, len) {
               sessionStorage.setItem('sSearch_PageLength', len);
            });
         }
      }
      if (!toolbar) {
         // tslint:disable-next-line:max-line-length
         element
            .parent()
            .find('.dt-toolbar')
            .append(
               '<div class="text-right"><img src="assets/img/logo2-white.png" alt="SmartAdmin" style="height: 22px; margin-top: 3px; margin-right: 10px;"></div>'
            );
      }
      element.on('mouseenter', 'td', function () {
         const dt = _dataTable.cell(this).index();
         if (dt) {
            const rowIdx = dt.row;
            $(_dataTable.rows().nodes()).removeClass('dataTable-highlight');
            $(_dataTable.row(rowIdx).nodes()).addClass('dataTable-highlight');
         }
      });

      if (this.detailsFormat) {
         const format = this.detailsFormat;
         element.off('click', 'td.details-control');
         element.on('click', 'td.details-control', function () {
            const tr = $(this).closest('tr');
            const row = _dataTable.row(tr);
            if (row.child.isShown()) {
               row.child.hide();
               tr.removeClass('shown');
            } else {
               row.child(format(row.data())).show();
               tr.addClass('shown');
            }
         });
      }

      if (this.hasRemoveButton) {
         element.off('click', 'tbody tr[role] td .remove-button');
         element.on('click', 'tbody tr[role] td .remove-button', (elem) => {
            this.notificationService
               .showConfirmBox(
                  this.removeButtonConfirmTitle ? this.removeButtonConfirmTitle : 'DataRemoveRegisterTitle',
                  this.removeButtonConfirmMessage ? this.removeButtonConfirmMessage : 'DataRemoveRegisterMsg',
                  'warning'
               )
               .then((ret) => {
                  if (ret) {
                     const rowData = _dataTable.row($(elem.target).closest('tbody tr[role]')).data();
                     this.onClickRemove.emit(rowData);
                  }
               });
         });
      }

      if (this.hasOrderCheckPrinterQrCode) {
         element.off('click', 'tbody tr[role] td .fa-print');
         element.on('click', 'tbody tr[role] td .fa-print', (elem) => {
            const rowData = _dataTable.row($(elem.target).closest('tbody tr[role]')).data();
            this.onClickPrinter.emit({ item: rowData, action: 'generateQrCode' });
         });
      }

      if (this.hasOrderCheckDownloadQrCode) {
         element.off('click', 'tbody tr[role] td .fa-download');
         element.on('click', 'tbody tr[role] td .fa-download', (elem) => {
            const rowData = _dataTable.row($(elem.target).closest('tbody tr[role]')).data();
            this.onClickPrinter.emit({ item: rowData, action: 'downloadQrCode' });
         });
      }

      if (this.hasAddEditRemoveButton) {
         element.off('click', 'tbody tr[role] td .remove-button');
         element.on('click', 'tbody tr[role] td .remove-button', (elem) => {
            const rowData = _dataTable.row($(elem.target).closest('tbody tr[role]')).data();
            this.onClickAddEditRemove.emit({ item: rowData, action: 'remove' });
         });

         element.off('click', 'tbody tr[role] td .edit-button');
         element.on('click', 'tbody tr[role] td .edit-button', (elem) => {
            const rowData = _dataTable.row($(elem.target).closest('tbody tr[role]')).data();
            this.onClickAddEditRemove.emit({ item: rowData, action: 'edit' });
         });

         element.off('click', 'tbody tr[role] td .add-button');
         element.on('click', 'tbody tr[role] td .add-button', (elem) => {
            const rowData = _dataTable.row($(elem.target).closest('tbody tr[role]')).data();
            this.onClickAddEditRemove.emit({ item: rowData, action: 'add' });
         });
      }

      if (this.hasOrderButtons) {
         element.off('click', 'tbody tr[role] td .order-up-button');
         element.on('click', 'tbody tr[role] td .order-up-button', (elem) => {
            const rowData = _dataTable.row($(elem.target).closest('tbody tr[role]')).data();
            this.onClickOrder.emit({ item: rowData, isUp: true });
         });

         element.off('click', 'tbody tr[role] td .order-down-button');
         element.on('click', 'tbody tr[role] td .order-down-button', (elem) => {
            const rowData = _dataTable.row($(elem.target).closest('tbody tr[role]')).data();
            this.onClickOrder.emit({ item: rowData, isUp: false });
         });
      }

      if (this.hasBankAccountActionButtons) {
         element.off('click', 'tbody tr[role] td .bank-account-action-button > a');
         element.on('click', 'tbody tr[role] td .bank-account-action-button > a', (elem) => {
            const Id = $(elem.target).attr('id');
            const Name = $(elem.target).data('name');
            const CompanyId = $(elem.target).data('companyid');
            const Balance = $(elem.target).data('balance');
            const action = $(elem.target).data('action');

            this.onClickBankAccountAction.emit({ item: { Id, Name, CompanyId, Balance }, action });
         });
      }

      if (this.hasCostCenterActionButtons) {
         element.off('click', 'tbody tr[role] td .cost-center-action-button > a');
         element.on('click', 'tbody tr[role] td .cost-center-action-button > a', (elem) => {
            const Id = $(elem.target).attr('id');
            const Name = $(elem.target).data('name');
            const CompanyId = $(elem.target).data('companyid');
            const action = $(elem.target).data('action');

            this.onClickCostCenterAction.emit({ item: { Id, Name, CompanyId }, action });
         });
      }

      if (this.hasFinanceAccountActionButtons) {
         element.off('click', 'tbody tr[role] td .finance-account-action-button > a');
         element.on('click', 'tbody tr[role] td .finance-account-action-button > a', (elem) => {
            const Id = $(elem.target).attr('id');
            const Name = $(elem.target).data('name');
            const CompanyId = $(elem.target).data('companyid');
            const Transaction = $(elem.target).data('transaction');
            const Origin = $(elem.target).data('origin');
            const action = $(elem.target).data('action');

            this.onClickFinanceAccountAction.emit({ item: { Id, Name, CompanyId, Transaction, Origin }, action });
         });
      }

      if (this.hasProductActionButtons) {
         element.off('click', 'tbody tr[role] td .product-action-button');
         element.on('click', 'tbody tr[role] td .product-action-button', (elem) => {
            const id = $(elem.target).attr('id');
            const name = $(elem.target).data('name');
            const companyId = $(elem.target).data('companyid');
            const action = $(elem.target).data('action');
            this.onClickProductAction.emit({ id, name, companyId, action });
         });
      }

      if (this.hasNfeActionButtons) {
         element.off('click', 'tbody tr[role] td .nfe-action-button');
         element.on('click', 'tbody tr[role] td .nfe-action-button', (elem) => {
            const id = $(elem.target).attr('id');
            const companyId = $(elem.target).data('companyid');
            const action = $(elem.target).data('action');
            this.onClickNfeAction.emit({ id, companyId, action });
         });
      }

      if (this.hasPixActionButtons) {
         element.off('click', 'tbody tr[role] td .pix-action-button');
         element.on('click', 'tbody tr[role] td .pix-action-button', (elem) => {
            const id = $(elem.target).attr('id');
            const emissionDate = $(elem.target).data('emissiondate');
            const type = $(elem.target).data('type');
            const description = $(elem.target).data('description');
            const comment = $(elem.target).data('comment');
            const totalValue = $(elem.target).data('totalvalue');
            const code = $(elem.target).data('code');
            const action = $(elem.target).data('action');

            let transactions = new PixAccountStatementSummaryResponse();
            transactions.TransactionId = id;
            transactions.EmissionDate = emissionDate;
            transactions.Type = type;
            transactions.Description = description;
            transactions.Comment = comment;
            transactions.TotalValue = totalValue;
            transactions.Code = String(code);

            this.onClickPixAction.emit({ transactions, action });
         });
      }

      if (this.hasNfeEntryActionButtons) {
         element.off('click', 'tbody tr[role] td .nfe-entry-action-button');
         element.on('click', 'tbody tr[role] td .nfe-entry-action-button', (elem) => {
            const id = $(elem.target).attr('id');
            const companyId = $(elem.target).data('companyid');
            const accessKey = $(elem.target).data('accesskey');
            const action = $(elem.target).data('action');
            this.onClickNfeEntryAction.emit({ id, companyId, accessKey, action });
         });
      }

      if (this.hasNfeResumeActionButtons) {
         element.off('click', 'tbody tr[role] td .nfe-resume-action-button');
         element.on('click', 'tbody tr[role] td .nfe-resume-action-button', (elem) => {
            const id = $(elem.target).attr('id');
            const companyId = $(elem.target).data('companyid');
            const action = $(elem.target).data('action');
            this.onClickNfeResumeAction.emit({ id, companyId, action });
         });
      }

      if (this.hasWaitlistActionButtons) {
         element.off('click', 'tbody tr[role] td .waitlist-action-message-button');
         element.on('click', 'tbody tr[role] td .waitlist-action-message-button', (elem) => {
            this.notificationService.showConfirmBox('WaitlistCustomerMessageTitle', 'WaitlistCustomerMessageMsg', 'warning').then((ret) => {
               if (ret) {
                  const rowData = _dataTable.row($(elem.target).closest('tbody tr[role]')).data();
                  $(elem.target).hide();
                  this.onClickWaitlistAction.emit({ item: rowData, statusCode: 2 });
               }
            });
         });

         element.off('click', 'tbody tr[role] td .waitlist-action-complete-button');
         element.on('click', 'tbody tr[role] td .waitlist-action-complete-button', (elem) => {
            this.notificationService
               .showConfirmBox('WaitlistCustomerCompleteTitle', 'WaitlistCustomerCompleteMsg', 'warning')
               .then((ret) => {
                  if (ret) {
                     const rowData = _dataTable.row($(elem.target).closest('tbody tr[role]')).data();
                     $(elem.target).siblings('.waitlist-action-message-button').hide();
                     $(elem.target).siblings('.waitlist-action-cancel-button').hide();
                     $(elem.target).hide();
                     this.onClickWaitlistAction.emit({ item: rowData, statusCode: 3 });
                  }
               });
         });

         element.off('click', 'tbody tr[role] td .waitlist-action-cancel-button');
         element.on('click', 'tbody tr[role] td .waitlist-action-cancel-button', (elem) => {
            this.notificationService.showConfirmBox('WaitlistCustomerCancelTitle', 'WaitlistCustomerCancelMsg', 'warning').then((ret) => {
               if (ret) {
                  const rowData = _dataTable.row($(elem.target).closest('tbody tr[role]')).data();
                  $(elem.target).siblings('.waitlist-action-message-button').hide();
                  $(elem.target).siblings('.waitlist-action-complete-button').hide();
                  $(elem.target).hide();
                  this.onClickWaitlistAction.emit({ item: rowData, statusCode: 4 });
               }
            });
         });
      }

      if (this.hasAccountantRemoveButton) {
         element.off('click', 'tbody tr[role] td .remove-button');
         element.on('click', 'tbody tr[role] td .remove-button', (elem) => {
            this.notificationService
               .showConfirmBox(
                  this.removeButtonConfirmTitle ? this.removeButtonConfirmTitle : 'DataRemoveRegisterTitle',
                  this.removeButtonConfirmMessage ? this.removeButtonConfirmMessage : 'DataRemoveRegisterMsg',
                  'warning'
               )
               .then((ret) => {
                  if (ret) {
                     const rowData = _dataTable.row($(elem.target).closest('tbody tr[role]')).data();
                     $(elem.target).hide();
                     this.onClickAccountantRemove.emit(rowData);
                  }
               });
         });
      }

      if (this.hasOrderCheckActionButtons) {
         element.off('click', 'tbody tr[role] td .order-check-action-button');
         element.on('click', 'tbody tr[role] td .order-check-action-button', (elem) => {
            const id = $(elem.target).attr('id');
            const companyId = $(elem.target).data('companyid');
            const action = $(elem.target).data('action');
            const isGroupCheck = $(elem.target).data('isgroupcheck') == true || $(elem.target).data('isgroupcheck') == 'true';
            const startNumber = $(elem.target).data('startnumber');
            const endNumber = $(elem.target).data('endnumber');
            const friendlyUrl = $(elem.target).data('friendlyurl');
            this.onClickOrderCheckAction.emit({
               id,
               companyId,
               startNumber,
               endNumber,
               friendlyUrl,
               action,
               isGroupCheck,
            });
         });
      }

      if (this.hasUserActionButtons) {
         element.off('click', 'tbody tr[role] td .user-action-button');
         element.on('click', 'tbody tr[role] td .user-action-button', (elem) => {
            const id = $(elem.target).attr('id');
            const action = $(elem.target).data('action');
            this.onClickUserAction.emit({ id, action });
         });
      }

      if (this.hasProductPriceHistoryButton) {
         element.off('click', 'tbody tr[role] td .product-price-history-button');
         element.on('click', 'tbody tr[role] td .product-price-history-button', (elem) => {
            const companyId = $(elem.target).data('companyid');
            const priceTableId = $(elem.target).data('pricetableid');
            this.onClickProductPriceHistory.emit({ companyId, priceTableId });
         });
      }

      if (this.detailUrl && this.detailUrl.length > 0) {
         element.off('click', 'tbody tr[role]');
         element.on('click', 'tbody tr[role]', (elem) => {
            const rowData = _dataTable.row($(elem.target)).data();

            if (rowData) {
               const startElement = ':';
               const endElements = ['/', '?'];

               const params = [];
               const tempParams = this.detailUrl.split(startElement);
               tempParams.splice(0, 1); // Getting only parameters, excluding the base URL

               if (tempParams.length > 0) {
                  tempParams.forEach((tempParam) => {
                     let endIndex = tempParam.length;

                     // Getting the first occurence of any of the end elements.
                     endElements.forEach((item) => {
                        const index = tempParam.indexOf(item);

                        if (index >= 0 && index < endIndex) {
                           endIndex = index;
                        }
                     });

                     // Adding the real param name
                     params.push(tempParam.substring(0, endIndex));
                  });
               }

               if (params && params.length > 0) {
                  const row = _dataTable.row($(elem.target));

                  // Getting the data value of the row using the params and replacing the tag from Detail URL with this data value
                  params.forEach((param) => {
                     const value = rowData[param];

                     if (value) {
                        this.detailUrl = this.detailUrl.replace(startElement + param, value);
                     }
                  });
               }

               this.router.navigateByUrl(this.detailUrl);
            }
         });
      } else if (this.multiSelect) {
         element.off('click', 'tbody tr[role]');
         element.on('click', 'tbody tr[role]', (elem) => {
            const $tr = $(elem.currentTarget);
            const checkbox = $tr.find('td:first-child input:checkbox');

            if (checkbox) {
               const checked = checkbox.is(':checked');

               if (checked) {
                  $tr.removeClass('selected');
               } else {
                  $tr.addClass('selected');
               }

               $tr.find('input:checkbox').prop('checked', !checked);

               const rowData = _dataTable.row($(elem.currentTarget)).data();
               rowData.IsChecked = !checked;
               this.onClickRow.emit(rowData);
            }
         });
      } else {
         element.off('click', 'tbody tr[role]');
         element.on('click', 'tbody tr[role]', (elem) => {
            const rowData = _dataTable.row($(elem.target.nodeName === 'P' ? elem.target.parentElement : elem.target)).data();

            if (rowData) {
               this.onClickRow.emit(rowData);
            }
         });

         element.off('click', 'tbody tr[role] .grid-checkbox');
         element.on('click', 'tbody tr[role] .grid-checkbox', (elem) => {
            const inputProperty = $(elem.target.parentElement).find('.grid-checkbox-property');
            const td = $(elem.target.parentElement.parentElement.parentElement);
            const rowData = _dataTable.row(td).data();
            const checked = elem.target.checked;

            rowData[inputProperty[0].value] = checked;
         });
      }
   }

   refreshList(): void {
      this._datatables.ajax.reload();
   }

   redrawList(): void {
      // $(this.el.nativeElement.children[0]).DataTable().destroy(true)
      try {
         this._datatables.destroy();
      } catch (e) {}
      this.render();
   }

   search(option) {
      try {
         this._datatables.destroy();
      } catch (e) {}
      this.render(option);
      this._datatables.ajax.reload();
   }

   checkAllMultiselect(): void {
      if (this._datatables) {
         const self = this;
         this._datatables.rows().every(function (rowIdx, tableLoop, rowLoop) {
            const data = this.data();
            const node = this.node();

            const checkProp = self.dataCheck.checkBy;
            // Append the grade to the default row class name
            const $ch = $(node).find('input:checkbox');
            const $tr = $(node).closest('tr');

            if (self.dataCheck.values.includes(data[checkProp])) {
               $ch.prop('checked', true);
               $tr.addClass('selected');
            } else {
               $ch.prop('checked', false);
               $tr.removeClass('selected');
            }
         });
      }
   }
}
