import { ApplicationRef, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ToastrModule } from 'ngx-toastr';

/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from './app.routing';
// App is our top level component
import ptBr from '@angular/common/locales/pt';
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState, InternalStateType } from './app.service';
// Core providers
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { ApiService } from 'app/api/api.service';
import { ExportService } from 'app/api/export.service';
import { ConnectionResolver } from 'app/api/signalr.connectionresolver';
import { AuthGuard } from 'app/auth/auth.guard';
import { AuthService } from 'app/auth/auth.service';
import { AuthorizationTokenService } from 'app/domain/services/authorization-token.service';
import { UiValidateService } from 'app/shared/forms/validation/ui-validate.service';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { config } from 'app/shared/smartadmin.config';
import { CryptoService } from 'app/shared/utils/crypto.service';
import { FunctionService } from 'app/shared/utils/function.service';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskModule } from 'ng2-currency-mask';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxMaskModule } from 'ngx-mask';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CanDeactivateGuard } from './can-deactivate-guard.service';
import { CoreModule } from './core/core.module';
import { OrderCenterNotificationService } from './domain/services/order-center-notification.service';
import { HirePlanModule } from './hire-plan/hire-plan.module';
import { SmartadminLayoutModule } from './shared/layout/layout.module';
import { ErrorHandlerService } from './domain/services/error-handler.service';

registerLocaleData(ptBr);
// Application wide providers
const APP_PROVIDERS = [...APP_RESOLVER_PROVIDERS, AppState];

interface StoreType {
   state: InternalStateType;
   restoreInputValues: () => void;
   disposeOldHosts: () => void;
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
   suppressScrollX: true,
};

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
   align: 'right',
   allowNegative: true,
   decimal: ',',
   precision: 2,
   prefix: '',
   suffix: '',
   thousands: '.',
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
   bootstrap: [AppComponent],
   declarations: [AppComponent],
   imports: [
      // import Angular's modules
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      HttpClientModule,
      CoreModule,
      SmartadminLayoutModule,
      routing,
      I18nModule,
      GooglePlaceModule,
      ToastrModule.forRoot({
         progressAnimation: 'increasing',
         preventDuplicates: false,
      }),
      NgxMaskModule.forRoot(),
      PerfectScrollbarModule,
      CurrencyMaskModule,
      HirePlanModule,
   ],
   exports: [],
   providers: [
      // expose our Services and Providers into Angular's dependency injection
      // ENV_PROVIDERS,
      {
         provide: LOCALE_ID,
         useValue: config.defaultLocale,
      },
      {
         provide: PERFECT_SCROLLBAR_CONFIG,
         useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      },
      {
         provide: CURRENCY_MASK_CONFIG,
         useValue: CustomCurrencyMaskConfig,
      },
      { provide: ErrorHandler, useClass: ErrorHandlerService },
      AuthGuard,
      AuthService,
      FunctionService,
      AuthorizationTokenService,
      OrderCenterNotificationService,
      CryptoService,
      CanDeactivateGuard,
      BsModalService,
      ApiService,
      UiValidateService,
      ExportService,
      ConnectionResolver,
      APP_PROVIDERS,
      CurrencyPipe,
   ],
})
export class AppModule {
   constructor(public appRef: ApplicationRef, public appState: AppState) {}
}
