import { Component, OnInit } from '@angular/core';
import { AccessType, AuthService } from 'app/auth/auth.service';

@Component({
   selector: 'kcms-support',
   templateUrl: './support.component.html',
})
export class SupportComponent implements OnInit {
   public hasSupportPermission = false;

   constructor(public authService: AuthService) {}

   ngOnInit() {
      this.hasSupportPermission = this.authService.hasPermission('Support', AccessType.Read);
   }
}
