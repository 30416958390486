// jQuery
declare var jQuery: any;
// RxJS



// Smartadmin Dependencies
window['jQuery'] = require('jquery');
window['$'] = window['jQuery'];
import 'jquery-validation'
// import 'smartadmin-plugins/bower_components/jquery-nestable'


import 'jquery-ui-npm/jquery-ui.min.js'

require('datatables.net')
require('bootstrap/js/dist/tooltip.js'); // required for X-editable
require('bootstrap/js/dist/popover.js'); // required for X-editable
require('bootstrap/js/dist/dropdown.js'); // required for bootstrap-colorpicker
require('bootstrap/js/dist/tab.js'); //
require('bootstrap/js/dist/modal.js'); //
require('nestable2/jquery.nestable.js')
import 'smartadmin-plugins/bower_components/jquery-nestable/jquery.nestable.js'
window['moment'] = require('moment');


import 'imports-loader?jQuery=jquery!jquery-color/jquery.color.js'

require('smartadmin-plugins/notification/SmartNotification.min.js');
