import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ApplicationInsightsService } from './application-insights.service';

@Injectable({
   providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
   constructor(private applicationInsightsService: ApplicationInsightsService) {
      super();
   }

   handleError(error: Error) {
      try {
         this.applicationInsightsService.logException(error); // Manually log exception
         if (!environment.production) {
            console.error(error);
         }
      } catch (error) {}
   }
}
