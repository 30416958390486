import { Component, Input, OnInit } from '@angular/core';

@Component({
   selector: 'kcms-loading',
   templateUrl: './loading.component.html',
   styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
   @Input() width = 60;
   @Input() text: string = 'Loading';
   @Input() isTextSimpleText = true;
   @Input() hasText = true;

   constructor() {}

   ngOnInit() {}
}
