import { Component, Input, OnInit } from '@angular/core';

@Component({
   selector: 'sa-big-breadcrumbs',
   template: `
      <div class="header-pages">
         <div class="page-title title-details">
            {{ items[0] | i18n }}
            <span class="sub-title" *ngFor="let item of items.slice(1)"> / {{ item | i18n }}</span>
         </div>
      </div>
   `,
})
export class BigBreadcrumbsComponent implements OnInit {
   @Input() public icon: string;
   @Input() public items: Array<string>;

   constructor() {}

   ngOnInit() {}
}
