import { BehaviorSubject, Observable, throwError as observableThrowError, timer as observableTimer } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationToken } from 'app/domain/models/authorization-token.model';
import { UserLogin, UserProfile } from 'app/domain/models/user-profile.model';
import { FunctionService } from 'app/shared/utils/function.service';
import * as auth0 from 'auth0-js';
import { environment } from 'environments/environment';
import { catchError, map } from 'rxjs/operators';
declare var mixpanel: any;

export class CompanyToken {
   public ShowCompanySelection: boolean;
   public CompanyItems = new Array<CompanyTokenItem>();
}

export class AuthUser {
   public Name: string;
   public Nickname: string;
   public Picture: string;
   public Email: string;
   public IsVerifiedEmail: boolean;

   constructor(name: string, nickname: string, picture: string, email: string, isVerifiedEmail: boolean) {
      this.Name = name;
      this.Nickname = nickname;
      this.Email = email?.toLowerCase();
      this.Picture = picture;
      this.IsVerifiedEmail = isVerifiedEmail;
   }
}

export class CompanyTokenItem {
   public CompanyId: number;
   public Name: string;
   public ParentId: number;
   public HasChildren: boolean;

   constructor(companyId: number, name: string, hasChildren: boolean, parentId?: number) {
      this.CompanyId = companyId;
      this.Name = name;
      this.HasChildren = hasChildren;
      this.ParentId = parentId ? parentId : companyId;
   }
}

export enum AccessType {
   Read = 'Read',
   Create = 'Create',
   Update = 'Update',
}

export enum PermissionType {
   Owner = 1,
   Child = 2,
   None = 3,
}

@Injectable()
export class AuthService {
   get nativeWindow(): any {
      return window;
   }

   private refreshSubscription: Observable<number>;
   private changedCompany: BehaviorSubject<boolean>;
   private authorizationTokenCreated: BehaviorSubject<boolean>;
   private isAuthenticating = false;
   private auth0 = new auth0.WebAuth(environment.auth0Config);
   private lastRoles: string[];

   constructor(private router: Router, private http: HttpClient, private functionService: FunctionService) {
      this.changedCompany = new BehaviorSubject(false);
      this.authorizationTokenCreated = new BehaviorSubject(localStorage.getItem('authorization_token') !== null);
   }

   get authorizationTokenObservable() {
      return this.authorizationTokenCreated.asObservable();
   }

   login(): void {
      // Sending user to the login page
      this.router.navigate(['/login']);
   }

   renewAuthentication(): void {
      this.auth0.checkSession({}, (err, result) => {
         if (err) {
            this.logout();
         } else {
            this.setLocalStorage(result, true);
         }
      });
   }

   logout(): void {
      // Removing user info from LocalStorage
      localStorage.removeItem('access_token');
      localStorage.removeItem('authorization_token');
      localStorage.removeItem('company_token');
      localStorage.removeItem('company_ids_token');
      localStorage.removeItem('company_setup');
      localStorage.removeItem('company_plan');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
      localStorage.removeItem('id');

      this.unscheduleRenewal();
      this.login();
   }

   setAuthorizationTokenCreated(val) {
      this.authorizationTokenCreated.next(val);
   }

   getCompaniesNeedsSetup(): number[] {
      const companies: number[] = [];
      const setupToken = localStorage.getItem('company_setup');
      if (setupToken) {
         const spltitedCompanies = setupToken.split(',');
         spltitedCompanies.forEach((company) => {
            companies.push(parseInt(company, 10));
         });
      }
      return companies;
   }

   setCompanySetupCompleted(companyId: number) {
      const setupToken = localStorage.getItem('company_setup');
      if (setupToken) {
         let newSetupToken = '';
         const spltitedCompanies = setupToken.split(',');
         spltitedCompanies.forEach((company) => {
            const currCompanyId = parseInt(company, 10);
            if (currCompanyId !== companyId) {
               newSetupToken += ',' + currCompanyId;
            }
         });
         if (newSetupToken.length > 0) {
            newSetupToken = newSetupToken.substring(1);
         }
         localStorage.setItem('company_setup', newSetupToken);
      }
   }

   isAuthenticated(queryParams?: any, redirectToLogin: boolean = true): boolean {
      if (this.isAuthenticating) {
         // If the "handleAuthentication" method is running, do nothing. That method will redirect the user to the right page.
         return false;
      }

      const accessToken = localStorage.getItem('access_token');
      const expiresAt = parseInt(localStorage.getItem('expires_at'), 10);

      if (accessToken && expiresAt > new Date().getTime()) {
         const user = this.getUserInfo();
         try {
            mixpanel.identify(user.Email);
            mixpanel.people.set({ $email: user.Email, name: user.Name });
            mixpanel.track('Authentication', {
               Email: user.Email,
            });
         } catch (err) {}
         return true;
      }

      if (redirectToLogin) {
         this.logout();
      }

      return false;
   }

   isAuthorized(roles: string[]): boolean {
      this.lastRoles = roles;

      if (roles && roles.length > 0) {
         let hasValidRole = false;
         const permissions = this.getPermissions();

         // Checking if the user has any of the given permissions
         roles.forEach((role) => {
            if (permissions.includes(role)) {
               hasValidRole = true;
            }
         });
         return hasValidRole;
      }
      return true;
   }

   getUserInfo(): AuthUser {
      const idToken = this.functionService.decodeToken(localStorage.getItem('id_token'));
      let user: AuthUser;

      if (idToken) {
         user = new AuthUser(idToken.name, idToken.nickname, idToken.picture, idToken.email, idToken.email_verified);
      }
      return user;
   }

   isKcmsUser(): boolean {
      const idToken = this.functionService.decodeToken(localStorage.getItem('id_token'));
      if (idToken && idToken.email && idToken.email.includes('@kcms.com.br')) {
         return true;
      }
      return false;
   }

   isUserCompanyAdmin(): boolean {
      const authToken = localStorage.getItem('authorization_token');

      if (authToken) {
         const decodedAuthToken: AuthorizationToken = JSON.parse(this.functionService.decodeString(authToken));

         if (decodedAuthToken) {
            return decodedAuthToken.IsCompanyAdmin;
         }
      }

      return false;
   }

   getPermissions(): string[] {
      const authToken = localStorage.getItem('authorization_token');

      if (authToken) {
         const decodedAuthToken: AuthorizationToken = JSON.parse(this.functionService.decodeString(authToken));

         if (decodedAuthToken) {
            return decodedAuthToken.Permissions.split(',');
         }
      }

      return [];
   }

   getCompaniesFromGroups(domain: string, accessType?: string): number[] {
      const authToken = localStorage.getItem('authorization_token');
      const companyIds: number[] = [];

      if (authToken) {
         const decodedAuthToken: AuthorizationToken = JSON.parse(this.functionService.decodeString(authToken));

         if (decodedAuthToken) {
            const groups = decodedAuthToken.Groups.split(',');

            // Checking if the given domain/access type exists on the user groups. If so, gets its CompanyId
            groups.forEach((group) => {
               const splittedGroup = group.split('-');

               if (
                  splittedGroup.length === 3 &&
                  splittedGroup[1] === domain &&
                  (!accessType || (accessType && splittedGroup[2] === accessType))
               ) {
                  const companyId = parseInt(splittedGroup[0], 10);

                  if (!companyIds.includes(companyId)) {
                     companyIds.push(companyId);
                  }
               }
            });
         }
      }

      return companyIds;
   }

   async setCompanies(companies: CompanyToken, recreateAuthToken: boolean = true): Promise<boolean> {
      if (companies) {
         const encodedCompanies = this.functionService.encodeString(JSON.stringify(companies));
         localStorage.setItem('company_token', encodedCompanies);

         let companyIds = '';
         const kcmsCompany = companies.CompanyItems.find((n) => n.CompanyId === 1);

         if (kcmsCompany) {
            companyIds = kcmsCompany.ParentId.toString() + '-' + kcmsCompany.CompanyId.toString();
         } else {
            for (let i = 0; i < companies.CompanyItems.length; i++) {
               if (i > 0) {
                  companyIds += ',';
               }
               companyIds += companies.CompanyItems[i].ParentId.toString() + '-' + companies.CompanyItems[i].CompanyId.toString();
            }
         }

         localStorage.setItem('company_ids_token', this.functionService.encodeString(companyIds));
      } else {
         localStorage.removeItem('company_token');
         localStorage.removeItem('company_ids_token');
      }

      if (recreateAuthToken) {
         return await this.createAuthorizationToken(false);
      }

      return true;
   }

   getCompanies(): CompanyToken {
      const encodedCompanies = localStorage.getItem('company_token');
      if (encodedCompanies) {
         return JSON.parse(this.functionService.decodeString(encodedCompanies));
      }
      return null;
   }

   getCompany(companyId: number): CompanyTokenItem {
      const encodedCompanies = localStorage.getItem('company_token');
      if (encodedCompanies) {
         const companies: CompanyToken = JSON.parse(this.functionService.decodeString(encodedCompanies));
         if (companies !== null && companies.CompanyItems !== null) {
            return companies.CompanyItems.find((n) => n.CompanyId === Number(companyId));
         }
      }
      return null;
   }

   hasPermission(domain: string, accessType: AccessType, companyId?: number): boolean {
      if (companyId) {
         const authToken = localStorage.getItem('authorization_token');
         if (authToken) {
            const decodedAuthToken: AuthorizationToken = JSON.parse(this.functionService.decodeString(authToken));
            const splittedGroups = decodedAuthToken.Groups.split(',');
            const companies = this.getCompanies();

            for (let i = 0; i < splittedGroups.length; i++) {
               if (splittedGroups[i] === companyId.toString() + '-' + domain + '-' + accessType) {
                  return true;
               } else if (splittedGroups[i] === '1-' + domain + '-' + accessType) {
                  for (let n = 0; n < companies.CompanyItems.length; n++) {
                     if (
                        companies.CompanyItems[n].CompanyId.toString() === companyId.toString() ||
                        companies.CompanyItems[n].CompanyId === 1
                     ) {
                        return true;
                     }
                  }
               }
            }
         }
      } else {
         const permissions = this.getPermissions();
         return permissions.includes(domain + '-' + accessType);
      }

      return false;
   }

   getPermissionType(domain: string, accessType: AccessType, companyId: number): PermissionType {
      let hasChildPermission = false;
      const authToken = localStorage.getItem('authorization_token');

      if (authToken) {
         const decodedAuthToken: AuthorizationToken = JSON.parse(this.functionService.decodeString(authToken));
         let hasCompanySelected = false;
         const childrenCompanies: number[] = [];
         const companies = this.getCompanies();
         companies.CompanyItems.forEach((company) => {
            if (company.CompanyId === companyId) {
               hasCompanySelected = true;
            }
            if (company.ParentId === companyId) {
               childrenCompanies.push(company.CompanyId);
            }
         });
         const splittedGroups = decodedAuthToken.Groups.split(',');
         for (let i = 0; i < splittedGroups.length; i++) {
            const splittedGroup = splittedGroups[i].split('-');
            const groupCompanyId = parseInt(splittedGroup[0], 10);
            const groupPermission = splittedGroup[1] + '-' + splittedGroup[2];
            if (groupPermission === domain + '-' + accessType) {
               if (groupCompanyId === companyId) {
                  return PermissionType.Owner;
               } else if (groupCompanyId === 1) {
                  if (hasCompanySelected || childrenCompanies.includes(companyId) || childrenCompanies.includes(1)) {
                     return PermissionType.Owner;
                  } else {
                     for (let x = 0; x < companies.CompanyItems.length; x++) {
                        if (
                           companies.CompanyItems[x].ParentId === companyId &&
                           childrenCompanies.includes(companies.CompanyItems[x].CompanyId)
                        ) {
                           hasChildPermission = true;
                           break;
                        }
                     }
                  }
               } else if (childrenCompanies.includes(groupCompanyId)) {
                  hasChildPermission = true;
               }
            }
         }
      }
      return hasChildPermission ? PermissionType.Child : PermissionType.None;
   }

   getCompanyIdsByPermission(domain: string, accessType: AccessType, onlyParentCompanies = false): number[] {
      const authToken = localStorage.getItem('authorization_token');
      const companies: number[] = [];

      if (authToken) {
         const decodedAuthToken: AuthorizationToken = JSON.parse(this.functionService.decodeString(authToken));
         const splittedGroups = decodedAuthToken.Groups.split(',');
         for (let i = 0; i < splittedGroups.length; i++) {
            const splittedGroup = splittedGroups[i].split('-');
            const groupCompanyId = parseInt(splittedGroup[0], 10);
            const groupPermission = splittedGroup[1] + '-' + splittedGroup[2];
            if (groupPermission === domain + '-' + accessType && !companies.includes(groupCompanyId)) {
               companies.push(groupCompanyId);
            }
         }

         if (onlyParentCompanies) {
            const encodedCompanies = localStorage.getItem('company_token');
            const companyToken: CompanyToken = JSON.parse(this.functionService.decodeString(encodedCompanies));
            for (let i = 0; i < companyToken.CompanyItems.length; i++) {
               const index = companies.indexOf(companyToken.CompanyItems[i].CompanyId);
               if (index >= 0 && companyToken.CompanyItems[i].ParentId !== companyToken.CompanyItems[i].CompanyId) {
                  companies.splice(index, 1);
               }
            }
         }
      }

      return companies;
   }

   handleAuthentication(authResult: any, invitationId?: string, goToCompletePage = false): void {
      // If this method is still running while "canActivate" method is called, this variable won't let the user go to the login page again.
      this.isAuthenticating = true;
      if (authResult && authResult.accessToken && authResult.idToken) {
         window.location.hash = '';
         this.setLocalStorage(authResult, false, invitationId, goToCompletePage);
      }
      this.isAuthenticating = false;
   }

   private setLocalStorage(authResult: any, isSilentAuthentication: boolean, invitationId?: string, goToCompletePage = false): void {
      // Setting the time that the access token will expire at
      const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());

      // Setting login info on localStorage
      localStorage.setItem('access_token', authResult.accessToken);
      localStorage.setItem('id_token', authResult.idToken);
      localStorage.setItem('expires_at', expiresAt);

      this.updateUserLastLogin(authResult.accessToken).subscribe((ret) => {
         this.scheduleRenewal();
      });

      if (!isSilentAuthentication) {
         localStorage.removeItem('company_token');
         localStorage.removeItem('company_ids_token');
         localStorage.removeItem('company_setup');
         localStorage.removeItem('company_plan');
         sessionStorage.removeItem('sSearch_CustomFilters');
         sessionStorage.removeItem('sSearch_PageIndex');
         sessionStorage.removeItem('sSearch_PageLength');
         for (let i = 0; i < 10; i++) {
            sessionStorage.removeItem('sSearch_' + i);
         }

         this.createAuthorizationToken(true, invitationId, goToCompletePage);
      }
   }

   public async createAuthorizationToken(redirectToHome: boolean, invitationId?: string, goToCompletePage = false): Promise<boolean> {
      this.setAuthorizationTokenCreated(false);

      try {
         let companies = this.getCompanies();
         const authToken = await this.getAuthorizationToken(companies, invitationId).toPromise();
         const encodedAuthToken = this.functionService.encodeString(JSON.stringify(authToken));
         localStorage.setItem('authorization_token', encodedAuthToken);
         if (authToken.CompaniesWithoutConfig) {
            localStorage.setItem('company_setup', authToken.CompaniesWithoutConfig);
         }
         if (authToken.Plan) {
            localStorage.setItem('company_plan', authToken.Plan);
         }

         if (!companies || companies.CompanyItems.length === 0) {
            const userCompanies = await this.getUserCompanies().toPromise();
            companies = {
               ShowCompanySelection: userCompanies.length > 1,
               CompanyItems: [],
            };

            userCompanies.forEach((item) => {
               companies.CompanyItems.push(item);
            });

            await this.setCompanies(companies, false);
         }

         if (goToCompletePage) {
            this.router.navigate(['/assinatura/sucesso']);
         } else if (!this.isAuthorized(this.lastRoles) || redirectToHome) {
            this.router.navigate(['/dashboard']);
         }

         this.setAuthorizationTokenCreated(true);
         return true;
      } catch (error) {
         this.setAuthorizationTokenCreated(true);
         return false;
      }
   }

   scheduleRenewal() {
      this.unscheduleRenewal();

      const currentTimestamp = new Date().getTime();
      const expiresAt = parseInt(localStorage.getItem('expires_at'), 10);
      if (expiresAt && !isNaN(expiresAt) && expiresAt > currentTimestamp) {
         const delay = expiresAt - currentTimestamp - 600000;
         this.refreshSubscription = observableTimer(delay > 0 ? delay : 0);
         this.refreshSubscription.subscribe((t) => {
            this.renewAuthentication();
         });
      }
   }

   unscheduleRenewal() {
      this.refreshSubscription = null;
   }

   authenticateUser(userLogin: UserLogin, callbackFunction) {
      this.auth0.client.login(
         {
            username: userLogin.Email,
            password: userLogin.Password,
            realm: 'Username-Password-Authentication',
         },
         (err, response) => {
            callbackFunction(err, response);
         }
      );
   }

   signupUser(userProfile: UserProfile, callbackFunction) {
      this.auth0.signup(
         {
            email: userProfile.Email,
            username: userProfile.Email,
            password: userProfile.Password,
            connection: 'Username-Password-Authentication',
         },
         (err, response) => {
            callbackFunction(err, response);
         }
      );
   }

   getAuthorizationToken(companyToken: CompanyToken, invitationToken?: string): Observable<AuthorizationToken> {
      const url = environment.apiBaseUrl + 'api/AuthorizationToken';
      const companyIds: number[] = [];

      if (companyToken && companyToken.CompanyItems) {
         companyToken.CompanyItems.forEach((item) => {
            companyIds.push(item.CompanyId);
         });
      }

      const headers = new HttpHeaders({
         Authorization: 'Bearer ' + localStorage.getItem('access_token'),
         'Content-Type': 'application/json',
      });

      const body = {
         InvitationId: invitationToken,
         CompanyIds: companyIds,
      };

      return this.http.post<any>(url, body, { headers: headers }).pipe(
         map((result) => {
            return JSON.parse(this.functionService.decodeString(result));
         }),
         catchError((error) => {
            return observableThrowError(error.json());
         })
      );
   }

   updateUserLastLogin(accessToken): Observable<boolean> {
      const url = environment.apiBaseUrl + 'api/UserLogin/LastLogin';

      const headers = new HttpHeaders({
         Authorization: 'Bearer ' + accessToken,
         'Content-Type': 'application/json',
      });

      return this.http.post<any>(url, null, { headers: headers }).pipe(
         catchError((error) => {
            return observableThrowError(error.json());
         })
      );
   }

   getUserCompanies(): Observable<Array<CompanyTokenItem>> {
      const url = environment.apiBaseUrl + 'api/UserCompany';

      const headers = new HttpHeaders({
         Authorization: 'Bearer ' + localStorage.getItem('access_token'),
         'Company-Token': localStorage.getItem('company_ids_token') ? localStorage.getItem('company_ids_token') : '',
         'Content-Type': 'application/json',
      });

      return this.http.get<any>(url, { headers: headers }).pipe(
         catchError((error) => {
            return observableThrowError(error.json());
         })
      );
   }
}
