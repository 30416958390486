import { Directive, Input, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';

declare var $: any;

@Directive({
  selector: '[saColorpicker]'
})
export class ColorpickerDirective implements OnInit {

  @Input() saColorpicker: any;
  @Input() ngModel;
  @Output() ngModelChange = new EventEmitter();

  constructor(private el: ElementRef) { }

  ngOnInit() {
    import('bootstrap-colorpicker/dist/js/bootstrap-colorpicker.js').then(() => {
      this.render()
    })
  }

  render() {
    $(this.el.nativeElement).colorpicker(this.saColorpicker || {});

    $(this.el.nativeElement).off('changeColor');
    $(this.el.nativeElement).on('changeColor', function (e) {
      if (e.value) {
        this.ngModel = e.value;
        this.ngModelChange.emit(this.ngModel);
      }
    }.bind(this));
  }
}
