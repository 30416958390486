import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AddressService } from 'app/domain/services/address.service';
import { CompanyLocationTypeService } from 'app/domain/services/company-location-type.service';
import { CompanyService } from 'app/domain/services/company.service';
import { DeliveryServiceConfigService } from 'app/domain/services/delivery-service-config.service';
import { IfoodService } from 'app/domain/services/ifood.service';
import { SalesChannelService } from 'app/domain/services/sales-channel.service';
import { TrialService } from 'app/domain/services/trial.service';
import { TrialTimeModule } from 'app/trial/trial-time/trial-time.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SmartadminInputModule } from '../forms/input/smartadmin-input.module';
import { I18nModule } from '../i18n/i18n.module';
import { KcmsAddressModule } from '../kcms/address/address.module';
import { AlertBoxModule } from '../kcms/alert-box/alert-box.module';
import { ComboboxModule } from '../kcms/combobox/combobox.module';
import { KcmsDecimalInputModule } from '../kcms/decimal-input/decimal-input.module';
import { HourInputModule } from '../kcms/hour-input/hour-input.module';
import { IfoodMenuHowToModalModule } from '../kcms/ifood-menu-how-to-modal/ifood-menu-how-to-modal.module';
import { LoadingModule } from '../kcms/loading/loading.module';
import { WootricModule } from '../kcms/wootric/wootric.module';
import { UtilsModule } from '../utils/utils.module';
import { AuthLayoutComponent } from './app-layouts/auth-layout.component';
import { MainLayoutComponent } from './app-layouts/main-layout.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderModule } from './header/header.module';
import { NavigationModule } from './navigation/navigation.module';
import { RibbonComponent } from './ribbon/ribbon.component';
import { RouteBreadcrumbsComponent } from './ribbon/route-breadcrumbs.component';

@NgModule({
   imports: [
      CommonModule,
      HeaderModule,
      NavigationModule,
      FormsModule,
      RouterModule,
      UtilsModule,
      TooltipModule,
      BsDropdownModule,
      AlertBoxModule,
      PerfectScrollbarModule,
      WootricModule,
      I18nModule,
      ComboboxModule,
      KcmsDecimalInputModule,
      KcmsAddressModule,
      NgxMaskModule,
      SmartadminInputModule,
      GooglePlaceModule,
      HourInputModule,
      LoadingModule,
      TrialTimeModule,
      IfoodMenuHowToModalModule,
   ],
   declarations: [FooterComponent, RibbonComponent, MainLayoutComponent, AuthLayoutComponent, RouteBreadcrumbsComponent],
   exports: [HeaderModule, NavigationModule, FooterComponent, RibbonComponent],
   providers: [
      AddressService,
      CompanyService,
      DeliveryServiceConfigService,
      SalesChannelService,
      CompanyLocationTypeService,
      CurrencyPipe,
      IfoodService,
      TrialService,
   ],
})
export class SmartadminLayoutModule {}
