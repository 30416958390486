import { Injectable } from '@angular/core';
declare var $: any;

@Injectable()
export class UiValidateService {
   constructor() {}

   addValidationError(elementName) {
      $("[name='" + elementName + "']")
         .parent()
         .find('.select2-container')
         .addClass('state-error-select2')
         .removeClass('state-success-select2');
      $("[name='" + elementName + "']")
         .addClass('invalid')
         .removeClass('valid');
      $("[name='" + elementName + "']")
         .parent()
         .addClass('state-error')
         .removeClass('state-success');
   }

   removeValidation(elementName) {
      const element = $('[name=' + elementName + ']').get(0);
      if (element) {
         $(element).find('.select2-container').removeClass('state-error-select2').removeClass('state-success-select2');
         $(element).find('.state-success').removeClass('state-success');
         $(element).find('.state-error').removeClass('state-error');
         $(element).find('.valid').removeClass('valid');
         $(element).find('.invalid').removeClass('invalid');
         $(element).find('em').remove();
      }
   }
}
