import './lib';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
   enableProdMode();
}

const googleKey = environment.googleApiKey;
const script = document.createElement('script');
script.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`);
document.getElementsByTagName('body')[0].appendChild(script);

platformBrowserDynamic().bootstrapModule(AppModule);
