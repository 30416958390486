import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'sa-header',
   templateUrl: './header.component.html',
   styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
   greetingMessage: string;
   constructor() {
      this.greetingMessage = this.getGreetingMessage();
   }

   ngOnInit() {}

   getGreetingMessage() {
      let h = Number(new Date().toLocaleTimeString('pt-BR', { hour: 'numeric', hour12: false }));
      if (h >= 0 && h <= 5) {
         return 'GoodDawn';
      } else if (h >= 6 && h < 12) {
         return 'GoodMorning';
      } else if (h >= 12 && h < 18) {
         return 'GoodAfternoon';
      } else if (h >= 18 && h <= 23) {
         return 'GoodNight';
      }
   }
}
