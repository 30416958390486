import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { ApiService } from 'app/api/api.service';
import { environment } from 'environments/environment';

@Injectable()
export class OrderCenterNotificationService {

  domainName = 'OrderCenterPanel';
  notificationObtainedMessage = 'OrderCenterNotificationObtained';
  storePausedMessage = 'OrderCenterOrderStorePaused';
  storeUnpausedMessage = 'OrderCenterOrderStoreUnpaused';

  private orderStatusChanged: BehaviorSubject<boolean>;
  private notificationCreated: BehaviorSubject<boolean>;

  constructor(private apiService: ApiService) {
    this.orderStatusChanged = new BehaviorSubject(false);
    this.notificationCreated = new BehaviorSubject(false);
  }

  get orderStatusObservable() {
    return this.orderStatusChanged.asObservable();
  }

  get notificationObservable() {
    return this.notificationCreated.asObservable();
  }

  setOrderStatusChanged() {
    this.orderStatusChanged.next(true);
  }

  setNotificationCreated() {
    this.notificationCreated.next(true);
  }

  getNotifications(utcOffset: number): Observable<string> {
    const request = {
      UtcOffset: utcOffset
    };
    return this.apiService.post('Notification', request, this.notificationObtainedMessage, environment.orderCenterBaseUrl);
  }

  pauseStore(companyId: number, orderCenterPausePeriod: number): Observable<Date> {
    const request = {
      CompanyId: companyId,
      OrderCenterPausePeriod: orderCenterPausePeriod
    };
    return this.apiService.post('Notification/pausestore', request, orderCenterPausePeriod ? this.storePausedMessage : this.storeUnpausedMessage, environment.orderCenterBaseUrl);
  }
}
