import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { NgxMaskModule } from 'ngx-mask';
import { SmartadminInputModule } from '../../forms/input/smartadmin-input.module';
import { ComboboxModule } from '../combobox/combobox.module';
import { HourInputComponent } from './hour-input.component';

@NgModule({
   imports: [CommonModule, FormsModule, I18nModule, ComboboxModule, SmartadminInputModule, NgxMaskModule],
   declarations: [HourInputComponent],
   exports: [HourInputComponent],
   entryComponents: [HourInputComponent],
})
export class HourInputModule {}
