import { AfterViewInit, Directive, ElementRef, forwardRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject ,  fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

// tslint:disable:directive-selector
@Directive({
  selector: '[debounceTime]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DebounceTimeDirective),
    multi: true
  }]
})
export class DebounceTimeDirective implements ControlValueAccessor, AfterViewInit, OnDestroy {
  protected destroyed$ = new Subject<boolean>();

  @Input()
  debounceTime: number;

  onChange = (_) => { };
  onTouched = () => { };

  constructor(private _elementRef: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    fromEvent(this._elementRef.nativeElement, 'keyup')
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(this.debounceTime)
      )
      .subscribe((event: any) => {
        this.onChange(event.target.value);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  writeValue(value: any): void {
    const normalizedValue = value === null || value === undefined ? '' : value;
    this.renderer.setProperty(this._elementRef.nativeElement, 'value', normalizedValue);
  }

  registerOnChange(fn: () => any): void { this.onChange = fn; }
  registerOnTouched(fn: () => any): void { this.onTouched = fn; }
}
