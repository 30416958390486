export const languages: Language[] = [
  {
    Key: 'pt-BR',
    Name: 'Português (BR)',
    Country: 'Brazil'
  },
  {
    Key: 'en-US',
    Name: 'English (US)',
    Country: 'United States'
  },
  {
    Key: 'es',
    Name: 'Español',
    Country: 'Spain'
  },
];

export class Language {
  public Key: string;
  public Name: string;
  public Country: string;
}
