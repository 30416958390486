import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, Filter, FilterComparisonType } from 'app/api/api.service';
import { State } from 'app/domain/models/state.model';

@Injectable()
export class StateService {

  domainName = 'State';

  constructor(private apiService: ApiService) { }

  getBrazilianStates(): Observable<State[]> {
    const filters: Filter[] = [{
      Key: 'IsActive',
      Value: true,
      ComparisonType: FilterComparisonType.Equal
    }];

    return this.apiService.getCustom(this.domainName + '/country/brazil', filters);
  }

  getBrazilianStatesWithoutAuthentication(): Observable<State[]> {
    return this.apiService.getCustom('CompanySignup/select/country/brazil');
 }
}
