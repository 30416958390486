import { Directive, AfterViewInit, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'app/auth/auth.service';
import { environment } from 'environments/environment';

@Directive({
  selector: '[appWootric]'
})
export class WootricDirective implements AfterViewInit {

    authorizationTokenObservable: Subscription;

  constructor(private elementRef: ElementRef, private authService: AuthService) {
    const user = this.authService.getUserInfo();
    // window['wootric_no_surveyed_cookie'] = true; // Shows survey immediately for testing purposes.
    // window['wootric_survey_immediately'] = true; // Bypass cookie based throttle for testing purposes.
    window['wootricSettings'] = {
      email: user.Email,
      created_at: Math.round((new Date()).getTime() / 1000),
      account_token: environment.wootricAccountToken // This is your unique account token.
    };
  }

  ngAfterViewInit() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.wootric.com/wootric-sdk.js';
    script.async = true;
    script.onload = function() {
      window['WootricSurvey'].run();
    };

    this.elementRef.nativeElement.appendChild(script);
  }
}
