import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CompanySelectComponent } from 'app/shared/kcms/company-select/company-select.component';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { GridListModule } from 'app/shared/kcms/grid-list/grid-list.module';
import { CompanyService } from 'app/domain/services/company.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    GridListModule
  ],
  exports: [
    CompanySelectComponent
  ],
  declarations: [
    CompanySelectComponent
  ],
  providers: [
    CompanyService
  ]
})

export class CompanySelectModule { }
