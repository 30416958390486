import { Injectable, ApplicationRef } from '@angular/core';
import { Observable, Subject, Subscription, BehaviorSubject } from 'rxjs';
import { config } from '../smartadmin.config';
import { languages, Language } from './languages.model';
import { JsonApiService } from '../../core/api/json-api.service';

@Injectable()
export class I18nService {
   public state;
   public data: {};
   public currentLanguage: Language;
   private subs;
   private i18nLoaded: BehaviorSubject<boolean>;

   constructor(private jsonApiService: JsonApiService, private ref: ApplicationRef) {
      this.state = new Subject();
      this.i18nLoaded = new BehaviorSubject(false);
      this.initLanguage(config.defaultLocale || 'pt-BR');
      this.fetch(this.currentLanguage.Key);
   }

   private fetch(locale: any) {
      this.subs = this.jsonApiService.fetch(`/langs/${locale}.json`);
      this.subs.subscribe((data: any) => {
         this.data = data;
         this.state.next(data);
         this.ref.tick();
         this.i18nLoaded.next(true);
      });
   }

   private initLanguage(locale: string) {
      const language = languages.find((it) => {
         return it.Key === locale;
      });
      if (language) {
         this.currentLanguage = language;
      } else {
         throw new Error(`Incorrect locale used for I18nService: ${locale}`);
      }
   }

   setLanguage(language: Language): void {
      this.currentLanguage = language;
      this.fetch(language.Key);
   }

   getLanguage(locale?: string): Language {
      if (locale) {
         for (let i = 0; i < languages.length; i++) {
            if (languages[i].Key === locale) {
               return languages[i];
            }
         }
      }
      return this.currentLanguage;
   }

   subscribe(sub: any, err: any) {
      return this.state.subscribe(sub, err);
   }

   public getTranslation(phrase: string, params?: string[]): string {
      let translatedText = this.data && this.data[phrase] ? this.data[phrase] : phrase;

      if (params && params.length > 0) {
         params.forEach((param, index) => {
            if (translatedText.includes(`#${index + 1}`)) {
               translatedText = translatedText.replace(`#${index + 1}`, param);
            }
         });
      }

      return translatedText;
   }

   get i18nServiceObservable() {
      return this.i18nLoaded.asObservable();
   }
}
