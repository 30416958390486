import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { AlertBox } from 'app/domain/models/alert-box.model';
import { AlertBoxService } from 'app/domain/services/alert-box.service';
import { Subscription } from 'rxjs';

@Component({
   selector: 'kcms-alert-box',
   templateUrl: './alert-box.component.html',
})
export class AlertBoxComponent implements OnInit, OnDestroy {
   authorizationTokenObservable: Subscription;
   alertBoxes: AlertBox[];

   constructor(public alertBoxService: AlertBoxService, public authService: AuthService) {}

   ngOnInit() {
      this.authorizationTokenObservable = this.authService.authorizationTokenObservable.subscribe((val) => {
         if (val) {
            this.loadAlerts();
         }
      });
   }

   loadAlerts() {
      this.alertBoxService.getActives().subscribe((ret) => {
         this.alertBoxes = ret;
      });
   }

   ngOnDestroy() {
      this.authorizationTokenObservable?.unsubscribe();
   }
}
