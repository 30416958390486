import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { UtilsModule } from '../../utils/utils.module';
import { I18nModule } from '../../i18n/i18n.module';
import { UserModule } from '../../user/user.module';
import {  ModalModule } from 'ngx-bootstrap/modal';
import { CollapseMenuComponent } from './collapse-menu/collapse-menu.component'
import { CompanySelectModule } from 'app/shared/kcms/company-select/company-select.module';
import { SupportModule } from '../../kcms/support/support.module';
import { OrderCenterNotificationModule } from 'app/shared/kcms/order-center-notification/order-center-notification.module';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule,
    UtilsModule,
    I18nModule,
    UserModule,
    ModalModule,
    CompanySelectModule,
    SupportModule,
    OrderCenterNotificationModule
  ],
  declarations: [
    HeaderComponent,
    CollapseMenuComponent,
  ],
  exports: [
    HeaderComponent
  ],
})
export class HeaderModule { }
