import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService, Filter, FilterComparisonType, Ordering } from 'app/api/api.service';
import { ComboboxService } from 'app/domain/services/combobox.service';
import { Select2Directive } from 'app/shared/forms/input/select2/select2.directive';
import { I18nService } from 'app/shared/i18n';
import { Observable } from 'rxjs';
declare var $: any;

@Component({
   selector: 'kcms-combobox',
   templateUrl: './combobox.component.html',
   styleUrls: ['./combobox.component.scss'],
})
export class ComboboxComponent implements OnInit, OnDestroy {
   data: any;
   isLoaded = false;

   @Input() apiUrl?: string;
   @Input() isOData = false;
   @Input() disabled = true;
   @Input() comboText = 'Name';
   @Input() comboId = 'Id';
   @Input() textSeparator = ' - ';
   @Input() placeholder: string;
   @Input() emptyPlaceholder = 'NoRegisters';
   @Input() domain: string;
   @Input() getMethod: string;
   @Input() isLoading: boolean;
   @Input() isMultiple = false;
   @Input() onlyActives = false;
   @Input() loadOnInit = false;
   @Input() name: string;
   @Input() accessType: string;
   @Input() accessDomain: string;
   @Input() orderBy = 'Asc';
   @Input() showClearButton = true;
   @Input() setUniqueChoice = false;
   @Input() dataText: string;
   @Input() validate = true;
   @Input() onDemand = false;

   @Output() onChange = new EventEmitter();
   @Output() onDataLoaded = new EventEmitter<any[]>();
   @Output() dataValueChange = new EventEmitter<any>();
   @Output() dataTextChange = new EventEmitter<string>();

   public _items: any[];
   @Input()
   get items() {
      return this._items;
   }
   set items(val) {
      this._items = val;

      if (this.isLoaded) {
         this.loadItems();
      }
   }

   public _companyID: number;
   @Input()
   get companyID() {
      return this._companyID;
   }
   set companyID(val) {
      this._companyID = val;
      if (this.isLoaded) {
         if (!val) {
            this._dataValue = '';
            this.reloadSelect([]);
         } else if (!this.onDemand) {
            this.loadData();
         }
      }
   }

   public _dataValue: any;
   @Input()
   get dataValue() {
      return this._dataValue;
   }
   set dataValue(val) {
      this._dataValue = val;
      if (this.data || this.items) {
         this.selectItem(val);
      } else if (this.onDemand && this.isLoaded) {
         this.createSingleItem();
      }
   }

   select2Directive: any;
   @ViewChild(Select2Directive, { static: true })
   set select2(directive: Select2Directive) {
      this.select2Directive = directive;
   }

   @ViewChild('divcomboboxcomponent', { static: true }) divComboComponent: ElementRef;

   constructor(
      public el: ElementRef,
      public apiService: ApiService,
      public comboboxService: ComboboxService,
      public i18nService: I18nService
   ) {}

   ngOnInit() {
      if (!this.placeholder) {
         this.placeholder = this.isMultiple ? 'SelectOneOrMoreRegisters' : 'SelectARegister';
      }
   }

   ngOnDestroy() {
      if ($('.select2-container--open').length) {
         $('.select2-container--open').remove();
      }
   }

   loadData(filters?: Filter[], isReload: boolean = true) {
      if (this.getMethod) {
         this.comboboxService[this.getMethod](this.accessDomain, this.accessType).subscribe((ret) => {
            this.addDataToSelect(ret, isReload);
         });
      } else if (this.domain) {
         const self = this;
         this.isLoading = true;
         if (!this.onDemand) {
            this.createSingleItem();
         }

         if (!filters) {
            filters = [];
         }
         const order = new Ordering();
         order.Field = self.comboText.split(';')[0];
         order.By = self.orderBy;
         const orders = ([] = [order]);

         if (this.onlyActives) {
            filters.push({
               Key: 'IsActive',
               Value: true,
               ComparisonType: FilterComparisonType.Equal,
            });
         }

         if (this.companyID != null) {
            filters.push({
               Key: this.domain.toLowerCase().indexOf('company/') >= 0 ? 'ParentId' : 'CompanyId',
               Value: this.companyID,
               ComparisonType: FilterComparisonType.Equal,
            });
         }

         if (this.apiUrl) {
            this.apiService
               .getByGroups(
                  this.domain,
                  filters,
                  this.accessDomain,
                  this.accessType,
                  orders,
                  null,
                  this.isOData,
                  null,
                  null,
                  null,
                  this.apiUrl
               )
               .subscribe(
                  (ret) => {
                     self.addDataToSelect(ret, isReload);
                     this.onDataLoaded.emit(ret);
                  },
                  (err) => {
                     self.isLoading = false;
                  }
               );
         } else {
            this.apiService.getByGroups(this.domain, filters, this.accessDomain, this.accessType, orders).subscribe(
               (ret) => {
                  self.addDataToSelect(ret, isReload);
                  this.onDataLoaded.emit(ret);
               },
               (err) => {
                  self.isLoading = false;
               }
            );
         }
      }
   }

   public addDataToSelect(list: any[], isReload: boolean = true) {
      this.isLoading = false;
      this.data = this.map(list);

      if (isReload) {
         this.reloadSelect(this.data);
      } else {
         this.addData(this.data);
      }
      if (this.setUniqueChoice) {
         this.selectUniqueChoice(this.data);
      }

      if (this.dataValue) {
         if (this.isMultiple) {
            let hasChanges = false;

            for (let i = 0; i < this.dataValue.length; i++) {
               let hasCurrentValue = false;
               for (let j = 0; j < this.data.length; j++) {
                  if (this.data[j].id === this.dataValue[i]) {
                     hasCurrentValue = true;
                     break;
                  }
               }
               if (!hasCurrentValue) {
                  this.dataValue.splice(i, 1);
                  let tempText = this.dataText.split(';');
                  tempText.splice(i, 1);
                  this.dataText = '';
                  tempText.forEach((elem) => {
                     this.dataText += elem + ';';
                  });
                  this.dataText = this.dataText.substr(0, this.dataText.length - 1);
                  i--;
                  hasChanges = true;
               }
            }

            if (hasChanges) {
               this.dataValue = this.dataValue.slice();
               this.dataValueChange.emit(this.dataValue);
            }
         } else {
            let hasValueInData = false;

            for (let i = 0; i < this.data.length; i++) {
               if (this.data[i].id === this.dataValue) {
                  hasValueInData = true;
                  break;
               }
            }

            if (!hasValueInData) {
               this.dataValue = null;
               this.dataText = null;
               this.dataValueChange.emit(null);
            }
         }
      }
   }

   loadItems() {
      const items = this.map(this.items);
      this.reloadSelect(items);

      if (this.setUniqueChoice) {
         this.selectUniqueChoice(items);
      }
   }

   selectUniqueChoice(data) {
      const self = this;
      if (data && data.length === 1) {
         const id = data[0].id;
         self.selectItem(id);
         self.automaticChangeSelect(data[0]);
      }
   }

   getData(): Observable<any> {
      return this.apiService.get(this.domain, null, this.apiUrl);
   }

   map(res) {
      if (!res) {
         res = [];
      }
      const lastItemId = res && res.length > 0 ? res[res.length - 1][this.comboId] : null;
      const map = res.map(
         function (item) {
            const fields = this.comboText.split(';');
            let dataText = '';
            fields.forEach((field) => {
               dataText += item[field] + this.textSeparator;
            });
            dataText = dataText.substring(0, dataText.length - this.textSeparator.length);
            return {
               text: dataText,
               id: item[this.comboId],
            };
         }.bind(this)
      );
      return map;
   }

   getDataCustom(key: string, value: string, comparisonType: FilterComparisonType = FilterComparisonType.Equal) {
      const filters: Filter[] = [
         {
            Key: key,
            Value: value,
            ComparisonType: comparisonType,
         },
      ];

      this.loadData(filters);
   }

   onChangeSelect(value) {
      const dataArray = [];
      let dataIds = null;
      let dataTexts = null;

      if (value && value.length > 0) {
         if (this.isMultiple) {
            dataIds = [];
            dataTexts = '';
            value.forEach((element) => {
               dataArray.push(element);
               dataIds.push(element.id);
               dataTexts += element.text + '; ';
            });
            dataTexts = dataTexts.substr(0, dataTexts.length - 2);
         } else {
            dataIds = value[0].id;
            dataTexts = value[0].text;
         }
      }

      this._dataValue = dataIds;
      this.dataValueChange.emit(this._dataValue);
      this.dataText = dataTexts;
      this.dataTextChange.emit(this.dataText);

      if (value && value.length > 0) {
         if (this.isMultiple) {
            this.onChange.emit(dataArray);
         } else {
            this.onChange.emit(value[0]);
         }
      } else {
         this.onChange.emit({ id: null, text: null });
      }

      if (this.validate) {
         setTimeout(() => {
            if ($('input[name=' + this.name + ']').length > 0) {
               $('input[name=' + this.name + ']').valid();
            }
         }, 20);
      }
   }

   onOpeningSelect(event) {
      if (!this.isLoading && this.onDemand && !this.data) {
         this.removeClearButton();
         this.loadData(null);
      }
   }

   automaticChangeSelect(value, valid = true) {
      this._dataValue = value.id;
      this.dataValueChange.emit(this._dataValue);
      this.dataText = value.text;
      this.dataTextChange.emit(this.dataText);
      this.onChange.emit(value);
   }

   reloadSelect(data: any[]): void {
      this.select2Directive.reloadSelect(data, this.dataValue);
      if (!this.isMultiple && this.showClearButton && this.dataValue && this.dataValue !== '') {
         this.drawClearButton();
      } else {
         this.removeClearButton();
      }
   }

   addData(data): void {
      this.select2Directive.addData(data, this.dataValue);
      if (!this.isMultiple && this.showClearButton && this.dataValue && this.dataValue !== '') {
         this.drawClearButton();
      }
   }

   drawClearButton(): void {
      for (let i = 0; i < this.divComboComponent.nativeElement.children.length; i++) {
         const element = this.divComboComponent.nativeElement.children[i];
         if (element.firstElementChild && element.firstElementChild.className === 'selection') {
            for (let j = 0; j < element.children.length; j++) {
               const jelement = element.children[j];
               if (jelement.className === 'icon-append far fa-times') {
                  return;
               }
            }
            const e = document.createElement('div');
            e.innerHTML = `<span class="icon-append far fa-times" style="
            margin-right: 26px;
            margin-top: 1px;
            padding: 0 3px;
            border: 0px;
            z-index: 1;
            color:#514b3e;
            cursor: pointer;"
            onclick="this.offsetParent.offsetParent.children[1].children[0].children[0].children[3].click();"
            onmouseover="this.style.color='#808080';"
            onmouseout="this.style.color='#514b3e';"></span>`;

            element.appendChild(e.firstChild);
            return;
         }
      }
   }

   clearSelectedItem(): void {
      this.select2Directive.selectItem('');
      this.automaticChangeSelect('');

      if (this.validate) {
         setTimeout(() => {
            if ($('input[name=' + this.name + ']').length > 0) {
               $('input[name=' + this.name + ']').valid();
            }
         }, 20);
      }

      this.removeClearButton();
   }

   removeClearButton(): void {
      for (let i = 0; i < this.divComboComponent.nativeElement.children.length; i++) {
         const element = this.divComboComponent.nativeElement.children[i];
         if (element.firstElementChild && element.firstElementChild.className === 'selection') {
            for (let j = 0; j < element.children.length; j++) {
               const jelement = element.children[j];
               if (jelement.className === 'icon-append far fa-times') {
                  jelement.parentNode.removeChild(jelement);
                  return;
               }
            }
         }
      }
   }

   selectItem(val: any): void {
      this.select2Directive.selectItem(val);
      if (!this.isMultiple && this.showClearButton && val && val !== '') {
         this.drawClearButton();
      } else {
         this.removeClearButton();
      }
   }

   createSingleItem() {
      const items = [];
      if (this.dataValue && this.dataText) {
         items.push({
            text: this.dataText,
            id: this.dataValue,
         });
      }

      items.push({
         text: this.i18nService.getTranslation('LoadingItems'),
         id: 'loading-0',
         disabled: true,
      });
      this.reloadSelect(items);
   }

   afterInitSelect2(): void {
      $(document).on('focus', '.select2', function (e) {
         if (e.originalEvent) {
            $(this).siblings('select').select2('open');
         }
      });

      this.isLoaded = true;

      if (this.items) {
         this.loadItems();
      } else if (this.onDemand) {
         this.createSingleItem();
      } else if (this.loadOnInit || (this.companyID && (this.domain || this.getMethod))) {
         this.loadData();
      }
   }
}
