import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {

  public showPage = false;

  constructor(public authService: AuthService, public router: Router) {
    if (this.authService.isAuthenticated(null, false)) {
      this.router.navigate(['/dashboard']);
    } else {
      this.showPage = true;
    }
  }

  ngOnInit() { }

}
