import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { IfoodMenuHowToModalComponent } from './ifood-menu-how-to-modal.component';

@NgModule({
   imports: [CommonModule, I18nModule],
   declarations: [IfoodMenuHowToModalComponent],
   exports: [IfoodMenuHowToModalComponent],
})
export class IfoodMenuHowToModalModule {}
