import { Injectable } from '@angular/core';
import { ConnectionResolver } from 'app/api/signalr.connectionresolver';
import { BaseData } from 'app/domain/models/base.model';
import { I18nService } from 'app/shared/i18n';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';

export class Filter {
   public Key: string;
   public Value: any;
}

export class Ordering {
   public Field: string;
   public By: any;
}

@Injectable()
export class ExportService {
   constructor(private signalRConnection: ConnectionResolver, private i18nService: I18nService) {}

   exportToFile(format: ExportFormat, domain: string, param?: any): Observable<Object[]> {
      const url = environment.apiBaseUrl + 'api/' + domain;
      return Observable.create((observer) => {
         const xhr = new XMLHttpRequest();
         xhr.open('POST', url, true);
         xhr.setRequestHeader('Content-type', 'application/json');
         xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
         xhr.setRequestHeader('Company-Token', localStorage.getItem('company_ids_token') ? localStorage.getItem('company_ids_token') : '');
         xhr.setRequestHeader('ClientId', this.signalRConnection.getConnectionId());
         xhr.setRequestHeader('ClientMessage', domain);
         xhr.responseType = 'blob';

         xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
               if (xhr.status === 200) {
                  if (xhr.response.size == 0) {
                     observer.next();
                     observer.complete();
                  } else {
                     const contentType =
                        format === ExportFormat.Pdf
                           ? 'application/pdf'
                           : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                     const blob = new Blob([xhr.response], { type: contentType });
                     const blobUrl = URL.createObjectURL(blob);
                     const fileName =
                        moment(new Date()).format('DDMMYYYYhhmmss').toString() + (format === ExportFormat.Pdf ? '.pdf' : '.xls');
                     const a = document.createElement('a');
                     a.href = blobUrl;
                     a.download = fileName;
                     // start download
                     a.click();
                     observer.next(blob);
                     observer.complete();
                  }
               } else {
                  observer.error(xhr.response);
               }
            }
         };
         xhr.send(JSON.stringify(param));
      });
   }

   exportToExcel(domain: string, fileName: string, id: string) {
      let file = domain ? this.i18nService.getTranslation(domain) + '.xlsx' : this.i18nService.getTranslation(fileName) + '.xlsx';

      let element = document.getElementById(id);
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, domain ? this.i18nService.getTranslation(domain) : this.i18nService.getTranslation(fileName));

      XLSX.writeFile(wb, file);
   }

   getFilterTypes(): BaseData[] {
      const res: BaseData[] = [
         { Id: '1', Name: 'Dt. Movimento' },
         { Id: '2', Name: 'Dt. Emissão' },
      ];
      return res;
   }
}

export enum ExportFormat {
   Excel,
   Pdf,
}
