import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';
import { OrderCenterNotificationComponent } from './order-center-notification.component';

@NgModule({
  imports: [
    FormsModule,
    RouterModule,
    CommonModule,
    I18nModule,
  ],
  declarations: [
    OrderCenterNotificationComponent,
  ],
  exports: [
    OrderCenterNotificationComponent,
  ]
})
export class OrderCenterNotificationModule { }
