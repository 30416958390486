
import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from 'app/api/api.service';
import { AuthService } from 'app/auth/auth.service';
import { Company } from 'app/domain/models/company.model';

@Injectable()
export class ComboboxService {

  constructor(private apiService: ApiService, private authService: AuthService) { }

  company(accessDomain: string, accessType: string): Observable<any[]> {
    const list = [];
    const companyToken = this.authService.getCompanies();
    if (companyToken && companyToken.CompanyItems && companyToken.CompanyItems.length > 0) {
      const allowedCompanies = this.authService.getCompaniesFromGroups(accessDomain, accessType);
      companyToken.CompanyItems.forEach(company => {
        if (allowedCompanies.includes(1) || allowedCompanies.includes(company.CompanyId)) {
          const companyQueryModel = new Company();
          companyQueryModel.SimplifiedId = company.CompanyId;
          companyQueryModel.Name = company.Name;
          list.push(companyQueryModel);
        }
      });
    }

    list.sort((a, b) => {
      return this.compare(a, b, 'Name');
    })

    return observableOf(list);
  }

  getCompanyToResource(): Observable<any[]> {
    const list = [];
    const companyToken = this.authService.getCompanies();
    if (companyToken && companyToken.CompanyItems && companyToken.CompanyItems.length > 0) {
      companyToken.CompanyItems.forEach(company => {
        const companyQueryModel = new Company();
        companyQueryModel.SimplifiedId = company.CompanyId;
        companyQueryModel.Name = company.Name;
        list.push(companyQueryModel);
      });
    }

    list.sort((a, b) => {
      return this.compare(a, b, 'Name');
    })

    return observableOf(list);
  }

  parentCompany(accessDomain: string, accessType: string): Observable<any[]> {
    return this.getParentCompanies(accessDomain, accessType, false);
  }

  parentCompanyWithChildren(accessDomain: string, accessType: string): Observable<any[]> {
    return this.getParentCompanies(accessDomain, accessType, true);
  }

  getParentCompanies(accessDomain: string, accessType: string, hasChildrenOnly: boolean): Observable<any[]> {
    const list = [];
    const companyToken = this.authService.getCompanies();
    if (companyToken && companyToken.CompanyItems && companyToken.CompanyItems.length > 0) {
      const allowedCompanies = this.authService.getCompaniesFromGroups(accessDomain, accessType);
      companyToken.CompanyItems.forEach(company => {
        if ((allowedCompanies.includes(1) || allowedCompanies.includes(company.CompanyId)) && company.ParentId === company.CompanyId && (!hasChildrenOnly || company.HasChildren)) {
          const companyQueryModel = new Company();
          companyQueryModel.SimplifiedId = company.CompanyId;
          companyQueryModel.Name = company.Name;
          list.push(companyQueryModel);
        }
      });
    }

    list.sort((a, b) => {
      return this.compare(a, b, 'Name');
    })

    return observableOf(list);
  }

  private compare(a, b, property) {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  }
}
