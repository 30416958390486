import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {NotificationService} from '../../utils/notification.service';
import { I18nService } from '../../i18n/i18n.service';
import { AuthService } from 'app/auth/auth.service';

declare var $:any;

@Component({
  selector: 'sa-logout',
  templateUrl: './logout.component.html',
  styles: []
})
export class LogoutComponent implements OnInit {

  constructor(public router: Router,
              public notificationService: NotificationService,
              public i18nService: I18nService,
              public authService: AuthService) { }

  showPopup(){
    this.notificationService.showConfirmBox('DataLogoutTitle', 'DataLogoutMsg', 'warning').then(ret => {
      if (ret) {
        this.logout();
      }
    })
  }

  logout() {
      this.authService.logout();
  }

  ngOnInit() {

  }
}
