export class Address {
   public Id: string;
   public FormattedAddress: string;
   public AddressLine1: string;
   public AddressLine2: string;
   public AddressNumber: string;
   public Neighborhood: string;
   public CityName: string;
   public StateAcronym: string;
   public StateName: string;
   public CountryAcronym: string;
   public CountryName: string;
   public ZipCode: string;
   public Latitude: number;
   public Longitude: number;

   constructor(
      addressLine1: string = null,
      addressLine2: string = null,
      addressNumber: string = null,
      neighborhood: string = null,
      cityName: string = null,
      stateAcronym: string = null,
      stateName: string = null,
      countryAcronym: string = null,
      countryName: string = null,
      zipCode: string = null,
      latitude: number = null,
      longitude: number = null
   ) {
      this.AddressLine1 = addressLine1;
      this.AddressLine2 = addressLine2;
      this.AddressNumber = addressNumber;
      this.Neighborhood = neighborhood;
      this.CityName = cityName;
      this.StateAcronym = stateAcronym;
      this.StateName = stateName;
      this.CountryAcronym = countryAcronym;
      this.CountryName = countryName;
      this.ZipCode = zipCode;
      this.Latitude = latitude;
      this.Longitude = longitude;
   }
}
