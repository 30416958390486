import { Component } from '@angular/core';
import { LayoutService } from '../layout.service';
declare var $: any;

@Component({
   selector: 'sa-minify-menu',
   template: `<span
      class="button-menu-minify"
      (click)="toggle()"
      title="{{ (!icon ? 'MinifyMenu' : 'MinifyMenuOpen') | i18n }}"
   >
      <i *ngIf="!icon" class="fa-light fa-angle-left" aria-hidden="true"></i>
      <i *ngIf="icon" class="fa-light fa-angle-right" aria-hidden="true"></i>
   </span>`,
   styleUrls: ['./minify-menu.component.scss'],
})
export class MinifyMenuComponent {
   public icon = false;

   constructor(public layoutService: LayoutService) {}

   toggle() {
      this.icon = !this.icon ? true : false;
      this.layoutService.onMinifyMenu();
   }
}
