import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { LoadingComponent } from './loading.component';

@NgModule({
   imports: [CommonModule, FormsModule, I18nModule],
   declarations: [LoadingComponent],
   exports: [LoadingComponent],
   entryComponents: [LoadingComponent],
})
export class LoadingModule {}
