import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'environments/environment';

@Injectable({
   providedIn: 'root',
})
export class ApplicationInsightsService {
   appInsights: ApplicationInsights;
   constructor() {
      this.appInsights = new ApplicationInsights({
         config: {
            instrumentationKey: environment.appInsights.instrumentationKey,
            enableAutoRouteTracking: false, // option to log all route changes
         },
      });
      this.appInsights.loadAppInsights();
   }

   logException(exception: any, severityLevel?: number) {
      this.appInsights.trackException({ exception, severityLevel });
   }
}
