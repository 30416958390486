export class Person {
   public CompanyId: number;
   public CompanyName: string;
   public Id: string;
   public Name: string;
   public PersonTypeId: string;
   public Observation: string;
   public BirthDate: Date;
   public BirthDateFormatted: string;
   public GenderId: number;
   public GenderName: string;
   public BusinessName: string;
   public BusinessTypeId: number;
   public BusinessTypeName: string;
   public FiscalRegimeTypeId: number;
   public FiscalRegimeTypeName: string;
   public IsActive: boolean;
   public CreatedOn: Date;
   public LastModifiedOn: Date;
   public CreatedBy: string;
   public LastModifiedBy: string;

   public Email: string;
   public Phone: string;
   public IsPhysicalPerson: boolean;
   public Document1: string;
   public Document2: string;

   public TypeList: PersonTypeQueryModel[];
   public Documents: PersonDocumentQueryModel[];
   public Contacts: PersonContactQueryModel[];
   public Addresses: PersonAddressQueryModel[];

   constructor() {
      this.IsActive = true;
      this.IsPhysicalPerson = true;
   }
}

export class PersonTypeQueryModel {
   public Id: string;
   public Name: string;
}

export class PersonTypeAdd {
   public Id: string;
   public TypeId: string;
}

export class PersonDocumentQueryModel {
   public Id: string;
   public DocumentTypeId: string;
   public DocumentTypeName: string;
   public Value: string;
}

export class PersonContactQueryModel {
   public Id: string;
   public ContactTypeId: string;
   public ContactTypeName: string;
   public Value: string;
   public Name: string;
   constructor(tempId?: string) {
      this.Id = tempId;
   }
}

export class PersonContactAdd {
   public Id: string;
   public PersonContactId: string;
   public ContactTypeId: string;
   public Value: string;
   public Name: string;
}

export class PersonAddressQueryModel {
   public Id: string;
   public AddressTypeId: string;
   public AddressTypeName: string;
   public AddressLine1: string;
   public AddressLine2: string;
   public Neighborhood: string;
   public CityId: string;
   public CityName: string;
   public StateId: string;
   public StateCode: string;
   public StateName: string;
   public CountryId: string;
   public CountryName: string;
   public ZipCode: string;
   public IsMain: boolean;
   constructor(tempId?: string, isMain: boolean = false) {
      this.Id = tempId;
      this.IsMain = isMain;
   }
}

export class PersonAddressAdd {
   public Id: string;
   public PersonAddressId: string;
   public AddressTypeId: string;
   public AddressLine1: string;
   public AddressLine2: string;
   public Neighborhood: string;
   public CityId: string;
   public ZipCode: string;
   public IsMain: boolean;
}

export class PersonAddressWebService {
   public ZipCode: string;
   public AddressLine1: string;
   public AddressLine2: string;
   public Neighborhood: string;
   public CityName: string;
   public StateAcronym: string;
   public IbgeCode: string;
   public GiaCode: string;
   public CountryAcronym: string;
   public CountryName: string;
}

export class GetPersonRequest {
   public CompanyId: number;
   public Email: string;
   public Document: string;
}
