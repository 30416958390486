import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyHoursQueryModel } from 'app/domain/models/company.model';
import { I18nService } from 'app/shared/i18n';
import { NotificationService } from 'app/shared/utils/notification.service';
import * as moment from 'moment';

@Component({
   selector: 'kcms-hour-input',
   templateUrl: './hour-input.component.html',
   styleUrls: ['./hour-input.component.scss'],
})
export class HourInputComponent implements OnInit {
   public companyHours = new CompanyHoursQueryModel();
   public companyWeekHours = [];
   public listWeeks = [];

   @Input() disabled: boolean = false;
   @Input() isDateRange: boolean = false;
   @Input() hasButton: boolean = false;
   @Input() hasTable: boolean = false;
   @Input() hasWeek: boolean = false;
   @Input() prefix: string;
   @Input() isCenter: boolean = false;
   @Input() isRequired: boolean = false;

   @Input() isSunday: boolean = false;
   @Input() isMonday: boolean = false;
   @Input() isTueday: boolean = false;
   @Input() isWednesday: boolean = false;
   @Input() isThursday: boolean = false;
   @Input() isFriday: boolean = false;
   @Input() isSaturday: boolean = false;

   @Input() col = '2';

   //---WithRange
   @Input() startName = 'name';
   @Input() endName = 'endName';
   @Input() dataValueStart: string;
   @Input() dataValueEnd: string;

   //---WithoutRange
   @Input() name = 'name';
   @Input() inputTitle: string;
   @Input() dataValue: string;

   _weekHours: CompanyHoursQueryModel[];
   @Input()
   get weekHours() {
      return this._weekHours;
   }
   set weekHours(val) {
      if (val) {
         this._weekHours = val;
         this.companyWeekHours = val;
      }
   }

   @Output() onDataValueHours = new EventEmitter<any>();

   constructor(private notificationService: NotificationService, private i18nService: I18nService) {}

   ngOnInit() {
      this.setListWeek();
   }

   configHourRange() {
      if ((this.dataValueStart && !this.dataValueStart.includes(':')) || (this.dataValueStart && this.dataValueStart.length < 5)) {
         this.notificationService.showAlertBox('ValidateMessageInvalidHourFormat');
         this.dataValueStart = '';

         return;
      }

      if ((this.dataValueEnd && !this.dataValueEnd.includes(':')) || (this.dataValueEnd && this.dataValueEnd.length < 5)) {
         this.notificationService.showAlertBox('ValidateMessageInvalidHourFormat');
         this.dataValueEnd = '';

         return;
      }

      if (!this.validationHour(this.dataValueEnd)) {
         this.notificationService.showAlertBox('ValidateMessageInvalidHourEnd');
         this.dataValueEnd = '';
         return;
      }

      if (!this.validationHour(this.dataValueStart)) {
         this.notificationService.showAlertBox('ValidateMessageInvalidHourStart');
         this.dataValueStart = '';
         return;
      }

      if (this.dataValueStart && this.dataValueEnd) {
         const startTime = moment(this.dataValueStart, 'HH:mm A');
         const endTime = moment(this.dataValueEnd, 'HH:mm A');

         if (!startTime.isValid() || !endTime.isValid()) {
            if (!startTime.isValid()) {
               this.notificationService.showAlertBox('ValidateMessageInvalidHourStart');
               this.dataValueStart = '';
            } else if (!endTime.isValid()) {
               this.notificationService.showAlertBox('ValidateMessageInvalidHourEnd');
               this.dataValueEnd = '';
            } else {
               this.notificationService.showAlertBox('ValidateMessageInvalidHour');
            }
            return;
         }

         if (startTime.isSameOrAfter(endTime)) {
            this.notificationService.showAlertBox('ValidateMessageDateInvalid');
            this.dataValueStart = '';
            this.dataValueEnd = '';
            return;
         }
      }
   }

   configHourWithoutRange() {
      if ((this.dataValue && !this.dataValue.includes(':')) || (this.dataValue && this.dataValue.length < 5)) {
         this.notificationService.showAlertBox('ValidateMessageInvalidHourFormat');
         this.dataValue = '';

         return;
      }

      if (this.dataValue) {
         const dataValue = moment(this.dataValue, 'HH:mm A');

         if (!this.validationHour(this.dataValue)) {
            this.notificationService.showAlertBox('ValidateMessageInvalidHourSimple');
            this.dataValue = '';
            return;
         }

         if (!dataValue.isValid()) {
            if (!dataValue.isValid()) {
               this.notificationService.showAlertBox('ValidateMessageInvalidHourSimple');
               this.dataValue = '';
               return;
            }
         }

         this.onDataValueHours.emit(this.dataValue);
      }
   }

   validationHour(hour) {
      if (hour) {
         const regex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
         return regex.test(hour);
      } else {
         return true;
      }
   }

   //--selecting-day-of-the-week---------------------------------------------------------------------------------------
   handleAddBusinessHour(event) {
      const startTime = moment(this.dataValueStart, 'HH:mm A');
      let endTime = moment(this.dataValueEnd, 'HH:mm A');

      if (startTime.diff(endTime) >= 0) {
         endTime = endTime.add(1, 'days');
      }

      const selectedWeekdayCodes = this.getSelectedWeekdayCodes();

      for (let i = 0; i < this.companyWeekHours.length; i++) {
         for (let j = 0; j < selectedWeekdayCodes.length; j++) {
            if (this.companyWeekHours[i].WeekdayCode.toString() == selectedWeekdayCodes[j].toString()) {
               const currentItemStartTime = moment(this.companyWeekHours[i].StartTime, 'HH:mm A');
               let currentItemEndTime = moment(this.companyWeekHours[i].EndTime, 'HH:mm A');

               if (currentItemStartTime.diff(currentItemEndTime) >= 0) {
                  currentItemEndTime = currentItemEndTime.add(1, 'days');
                  return;
               }

               if (
                  startTime.isBetween(currentItemStartTime, currentItemEndTime) ||
                  endTime.isBetween(currentItemStartTime, currentItemEndTime) ||
                  currentItemStartTime.isBetween(startTime, endTime) ||
                  currentItemEndTime.isBetween(startTime, endTime)
               ) {
                  this.notificationService.showAlertBox('ValidateMessageDateAlreadyAdded');
                  return;
               }
            }
         }
      }

      for (let i = 0; i < selectedWeekdayCodes.length; i++) {
         let isAdded = false;
         const newObj = new CompanyHoursQueryModel();
         newObj.WeekdayCode = selectedWeekdayCodes[i];
         newObj.WeekdayName = this.getWeekdayName(selectedWeekdayCodes[i]);
         newObj.StartTime = this.dataValueStart;
         newObj.EndTime = this.dataValueEnd;

         if (this.companyWeekHours.length === 0) {
            this.companyWeekHours.push(newObj);
            isAdded = true;
         } else {
            for (let i = 0; i < this.companyWeekHours.length; i++) {
               if (
                  newObj.WeekdayCode < this.companyWeekHours[i].WeekdayCode ||
                  (newObj.WeekdayCode == this.companyWeekHours[i].WeekdayCode && newObj.StartTime < this.companyWeekHours[i].StartTime)
               ) {
                  this.companyWeekHours.splice(i, 0, newObj);
                  isAdded = true;
                  break;
               }
            }

            if (!isAdded) {
               this.companyWeekHours.push(newObj);
            }
         }
      }

      this.clearInputs();
      this.onDataValueHours.emit(this.companyWeekHours);
   }

   clearInputs() {
      this.dataValueEnd = '';
      this.dataValueStart = '';

      this.listWeeks.forEach((ret) => {
         if (ret.IsEnabled) ret.IsEnabled = false;
      });
   }

   getSelectedWeekdayCodes() {
      const codes: number[] = [];
      this.listWeeks.forEach((ret) => {
         if (ret.IsEnabled) codes.push(ret.Id);
      });

      return codes;
   }

   getWeekdayName(weekdayCode: number) {
      switch (weekdayCode) {
         case 0:
            return this.i18nService.getTranslation('Sunday');
         case 1:
            return this.i18nService.getTranslation('Monday');
         case 2:
            return this.i18nService.getTranslation('Tuesday');
         case 3:
            return this.i18nService.getTranslation('Wednesday');
         case 4:
            return this.i18nService.getTranslation('Thursday');
         case 5:
            return this.i18nService.getTranslation('Friday');
         case 6:
            return this.i18nService.getTranslation('Saturday');
         default:
            return null;
      }
   }

   handleRemoveBusinessHour(index) {
      this.companyWeekHours.splice(index, 1);
      this.onDataValueHours.emit(this.companyWeekHours);
   }

   setListWeek() {
      this.listWeeks = [
         {
            Id: 0,
            Code: this.prefix + 'Sunday',
            Name: 'SundayShort',
            IsEnabled: this.isSunday,
         },
         {
            Id: 1,
            Code: this.prefix + 'Mondaynday',
            Name: 'MondayShort',
            IsEnabled: this.isMonday,
         },
         {
            Id: 2,
            Code: this.prefix + 'Tueday',
            Name: 'TuesdayShort',
            IsEnabled: this.isTueday,
         },
         {
            Id: 3,
            Code: this.prefix + 'Wednesday',
            Name: 'WednesdayShort',
            IsEnabled: this.isWednesday,
         },
         {
            Id: 4,
            Code: this.prefix + 'Thursday',
            Name: 'ThursdayShort',
            IsEnabled: this.isThursday,
         },
         {
            Id: 5,
            Code: this.prefix + 'Friday',
            Name: 'FridayShort',
            IsEnabled: this.isFriday,
         },
         {
            Id: 6,
            Code: this.prefix + 'Saturday',
            Name: 'SaturdayShort',
            IsEnabled: this.isSaturday,
         },
      ];
   }
}
