import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CompanyPlanService } from 'app/domain/services/company-plan.service';
import { PlanService } from 'app/domain/services/plan.service';
import { TrialService } from 'app/domain/services/trial.service';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { NgxMaskModule } from 'ngx-mask';
import { HirePlanLogoutComponent } from './hire-plan-logout/hire-plan-logout.component';
import { HirePlanComponent } from './hire-plan.component';
import { hirePlanRoute } from './hire-plan.routing';

@NgModule({
   imports: [CommonModule, hirePlanRoute, FormsModule, NgxMaskModule.forRoot(), I18nModule],
   declarations: [HirePlanComponent, HirePlanLogoutComponent],
   exports: [],
   providers: [CompanyPlanService, TrialService, PlanService],
})
export class HirePlanModule {}
