import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'app/api/api.service';
import { SalesChannel } from 'app/domain/models/sales-channel.model';

@Injectable()
export class SalesChannelService {

  domainName = 'SalesChannel';
  addedMessage = 'SalesChannelAdded';
  updatedMessage = 'SalesChannelUpdated';

  constructor(private apiService: ApiService) { }

  get(id: string): Observable<SalesChannel> {
    return this.apiService.get(this.domainName, id);
  }

  getAll(): Observable<SalesChannel[]> {
    return this.apiService.getCustom(this.domainName + '/select');
  }

  save(obj: SalesChannel): Observable<any> {
    if (obj.Id && obj.Id.length > 0) {
      return this.apiService.post(this.domainName, obj, this.updatedMessage);
    } else {
      return this.apiService.put(this.domainName, obj, this.addedMessage);
    }
  }
}
