import { Injectable } from '@angular/core';
import { ApiService } from 'app/api/api.service';
import { CompanyToken, CompanyTokenItem } from 'app/auth/auth.service';
import {
   Company,
   CompanyAddressAdd,
   CompanyAddressQueryModel,
   CompanyBusinessHoursSet,
   CompanyConfig,
   CompanyContactAdd,
   CompanyDeliveryPeriodsSet,
   CompanyDigitalSertificateUpload,
   CompanyFranchiseRequest,
   CompanySignup,
   Companylogo,
} from 'app/domain/models/company.model';
import { CryptoService } from 'app/shared/utils/crypto.service';
import { FunctionService } from 'app/shared/utils/function.service';
import { Observable } from 'rxjs';
import { Address } from '../models/address.model';
import { CompanyLocationType } from '../models/company-location-type.model';
import { CompanySummary } from '../models/company-summary.modal';
import { OperationType } from '../models/operation-type.model';

@Injectable()
export class CompanyService {
   domainName = 'Company';
   addedMessage = 'CompanyAdded';
   updatedMessage = 'CompanyUpdated';
   signedUpMessage = 'CompanySignedUp';
   configuredMessage = 'CompanyConfigured';
   franchiseDataSetMessage = 'CompanyFranchiseDataSet';
   digitalCertificateUpdatedMessage = 'CompanyDigitalCertificateUpdated';
   contactAddedMessage = 'CompanyContactAdded';
   contactDeletedMessage = 'CompanyContactRemoved';
   addressAddedMessage = 'CompanyAddressAdded';
   addressEditedMessage = 'CompanyAddressEdited';
   addressDeletedMessage = 'CompanyAddressRemoved';
   validationCreateValidatedMessage = 'CompanyValidationCreateValidated';
   validationUpdateValidatedMessage = 'CompanyValidationUpdateValidated';
   logoUpdatedMessage = 'CompanyLogoUpdated';
   businessHoursSetMessage = 'CompanyBusinessHoursSet';
   deliveryPeriodsSetMessage = 'CompanyDeliveryPeriodsSet';

   constructor(private apiService: ApiService, private functionService: FunctionService, private cryptoService: CryptoService) {}

   get(id: string): Observable<Company> {
      return this.apiService.get(this.domainName, id);
   }

   getBySimplifiedId(simplifiedId: number): Observable<Company> {
      return this.apiService.get(this.domainName, simplifiedId);
   }

   getCompanySummaryBySimplifiedId(simplifiedId: number): Observable<CompanySummary> {
      return this.apiService.get(`${this.domainName}/${simplifiedId}/Summary`);
   }

   getDefaultPriceTable(id: number): Observable<string> {
      return this.apiService.get(this.domainName + '/' + id + '/PriceTable');
   }

   getAll(): Observable<Array<Company>> {
      return this.apiService.get(this.domainName);
   }

   getUserCompanies(): Observable<CompanyTokenItem[]> {
      return this.apiService.get('UserCompany', null);
   }

   getUserProfileName(): Observable<string> {
      return this.apiService.get('UserCompany/UserProfileName', null);
   }

   getAddresses(simplifiedId: number): Observable<CompanyAddressQueryModel[]> {
      return this.apiService.get(this.domainName + '/address/' + simplifiedId, null);
   }

   checkHasChildren(companyId: number): boolean {
      const companyToken = localStorage.getItem('company_token');
      if (companyToken && companyId) {
         const decodedCompanyToken: CompanyToken = JSON.parse(this.functionService.decodeString(companyToken));

         for (let i = 0; i < decodedCompanyToken.CompanyItems.length; i++) {
            if (decodedCompanyToken.CompanyItems[i].CompanyId.toString() === companyId.toString()) {
               return decodedCompanyToken.CompanyItems[i].HasChildren;
            }
         }
      }
      return false;
   }

   getParentChildren(parentCompanyId: number): Observable<Company[]> {
      return this.apiService.get(this.domainName + '/parentchildren', parentCompanyId);
   }

   getChildren(companyId: number): Observable<Company[]> {
      return this.apiService.get(this.domainName + '/children', companyId);
   }

   getConfiguration(companyId: number): Observable<CompanyConfig> {
      return this.apiService.get(this.domainName + '/config', companyId);
   }

   save(company: Company, address: Address = null): Observable<any> {
      if (company.Id && company.Id.length > 0) {
         return this.apiService.post(this.domainName, company, this.updatedMessage);
      } else {
         if (address) {
            company.AddressLine1 = address.AddressLine1;
            company.AddressLine2 = address.AddressLine2;
            company.AddressNumber = address.AddressNumber;
            company.Neighborhood = address.Neighborhood;
            company.CityName = address.CityName;
            company.StateAcronym = address.StateAcronym;
            company.StateName = address.StateName;
            company.CountryAcronym = address.CountryAcronym;
            company.CountryName = address.CountryName;
            company.ZipCode = address.ZipCode;
            company.Latitude = address.Latitude;
            company.Longitude = address.Longitude;
         }

         return this.apiService.put(this.domainName, company, this.addedMessage);
      }
   }

   saveBaseData(company: Company): Observable<any> {
      return this.apiService.post(this.domainName + '/basedata', company, this.updatedMessage);
   }

   signup(obj: CompanySignup): Observable<any> {
      const request = {
         Name: obj.Name,
         OwnerName: obj.OwnerName,
         DocumentNumber: obj.DocumentNumber,
         Email: obj.Email,
         Password: this.cryptoService.encryptToAes(obj.Password),
         PhoneNumber: obj.PhoneNumber,
         CompanyLocationTypeId: obj.CompanyLocationTypeId,
         BusinessLineId: obj.BusinessLineId,
         Address: obj.Address,
         OperationTypeIds: obj.OperationTypeIds,
      };
      return this.apiService.post('CompanySignup', request, this.signedUpMessage);
   }

   configure(companyId: number, obj: CompanyConfig): Observable<string> {
      return this.apiService.post(this.domainName + '/config/' + companyId, obj, this.configuredMessage);
   }

   configureImage(companyId: number, file: string): Observable<string> {
      const request = {
         CompanyLogoFile: file,
      };
      return this.apiService.post(this.domainName + '/configimage/' + companyId, request, this.configuredMessage);
   }

   saveDigitalCertificate(companyId: string, obj: CompanyDigitalSertificateUpload): Observable<boolean> {
      return this.apiService.post(this.domainName + '/DigitalCertificate/' + companyId, obj, this.digitalCertificateUpdatedMessage);
   }

   saveLogo(companyId: string, obj: Companylogo) {
      return this.apiService.post(this.domainName + '/Logo/' + companyId, obj, this.logoUpdatedMessage);
   }

   addContactItem(companyId: string, obj: CompanyContactAdd): Observable<any> {
      return this.apiService.put(this.domainName + '/' + companyId + '/contact', obj, this.contactAddedMessage);
   }

   deleteContactItem(companyId: string, companyContactId: string): Observable<any> {
      return this.apiService.delete(this.domainName + '/' + companyId + '/contact', companyContactId, this.contactDeletedMessage);
   }

   saveAddressItem(companyId: string, obj: CompanyAddressAdd, isEdit: boolean = false): Observable<any> {
      if (isEdit) {
         return this.apiService.post(this.domainName + '/' + companyId + '/address', obj, this.addressEditedMessage);
      } else {
         return this.apiService.put(this.domainName + '/' + companyId + '/address', obj, this.addressAddedMessage);
      }
   }

   deleteAddressItem(companyId: string, companyAddressId: string): Observable<any> {
      return this.apiService.delete(this.domainName + '/' + companyId + '/address', companyAddressId, this.addressDeletedMessage);
   }

   setCompanyFranchise(obj: Company): Observable<any> {
      const companyFranchise = new CompanyFranchiseRequest();
      companyFranchise.CompanyId = obj.SimplifiedId;
      companyFranchise.FundMarketingDueDay = obj.FundMarketingDueDay;
      companyFranchise.FundMarketingPercentage = obj.FundMarketingPercentage;
      companyFranchise.FundMarketingValue = obj.FundMarketingValue;
      companyFranchise.Id = obj.Id;
      companyFranchise.RoyaltiesDueDay = obj.RoyaltiesDueDay;
      companyFranchise.RoyaltiesPercentage = obj.RoyaltiesPercentage;
      companyFranchise.RoyaltiesTaxValue = obj.RoyaltiesTaxValue;

      return this.apiService.post(this.domainName + '/franchisedata', companyFranchise, this.franchiseDataSetMessage);
   }

   setBusinessHours(obj: CompanyBusinessHoursSet): Observable<any> {
      return this.apiService.post(this.domainName + '/businesshours', obj, this.businessHoursSetMessage);
   }

   setDeliveryPeriods(obj: CompanyDeliveryPeriodsSet): Observable<any> {
      return this.apiService.post(this.domainName + '/deliveryperiod', obj, this.deliveryPeriodsSetMessage);
   }

   getCompanyLocationType(): Observable<CompanyLocationType[]> {
      return this.apiService.getCustom('CompanySignup/select/company-location-type');
   }

   getCompanyReceitaWSApi(cnpj: string) {
      const url = `https://publica.cnpj.ws/cnpj/${cnpj}`;

      return this.apiService.getByThirdPartyUrl(url);
   }

   companyIsActive(simplifiedId: number): Observable<boolean> {
      return this.apiService.get(`${this.domainName}/IsActive/${simplifiedId}`);
   }

   getOperationTypeToNewCompany(): Observable<OperationType[]> {
      return this.apiService.getCustom('CompanySignup/select/operation-type');
   }

   getOperationType(): Observable<OperationType[]> {
      return this.apiService.get('OperationType/select');
   }
}
