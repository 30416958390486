import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CurrencyPipe } from 'app/shared/kcms/currency-formatter/currency-pipe.pipe';

@Component({
   selector: 'kcms-decimal-input',
   templateUrl: './decimal-input.component.html',
   styleUrls: ['./decimal-input.component.scss'],
})
export class KcmsDecimalInputComponent implements OnInit {
   public point = '';
   public mask: any;

   @Input() name: string;
   @Input() placeholder = '0,00';
   @Input() decimalDigits = 2;
   @Input() disabled = false;
   @Input() maxValue: number;
   @Input() minValue: number;

   @Output() dataValueChange = new EventEmitter<any>();
   @Output() dataValueStringChange = new EventEmitter<any>();
   @Output() onBlur = new EventEmitter<any>();

   _dataValue: any;
   @Input()
   get dataValue() {
      return this._dataValue;
   }
   set dataValue(val) {
      this._dataValueString = this.parseNumberToInput(val);
      this._dataValue = val;
   }

   _dataValueString: any;
   @Input()
   get dataValueString() {
      return this._dataValueString;
   }
   set dataValueString(val) {
      this._dataValueString = val;
      this._dataValue = this.parseNumberToApi(val);
   }

   @ViewChild('decElement', { static: true }) decElement: ElementRef;

   constructor(private currencyPipe: CurrencyPipe) {
      const value = this.currencyPipe.formatDecimal('0', '1.2');
      this.point = this.currencyPipe.getDecimalPoint(value);
   }

   ngOnInit() {}

   focus() {
      this.decElement.nativeElement.focus();
   }

   applyMask() {
      let number = this.parseNumberToApi(this._dataValueString);

      if (number && this.maxValue && number > this.maxValue) {
         number = this.maxValue;
      }

      if (number && this.minValue && number < this.minValue) {
         number = this.minValue;
      }

      this._dataValue = number;
      this._dataValueString = this.parseNumberToInput(this._dataValue);
      this._dataValue = this.parseNumberToApi(this._dataValueString);
      this.dataValueStringChange.emit(this._dataValueString);
      this.dataValueChange.emit(this._dataValue);

      this.onBlur.emit(this._dataValue);
   }

   parseNumberToInput(val) {
      if (val !== null && val !== undefined && val.toString().trim() !== '' && !isNaN(val) && isFinite(val)) {
         let value;
         const mask = this.buildMask();
         if (this.point === '.') value = val.toString().split('.').join(',');
         else value = val.toString().split(',').join('.');
         return this.currencyPipe.formatDecimal(value, mask);
      }

      return null;
   }

   parseNumberToApi(val) {
      if (val) {
         let number;
         if (this.point === '.') number = val.toString().split(',').join('');
         else number = val.toString().split('.').join('').split(',').join('.');
         return parseFloat(parseFloat(number).toFixed(this.decimalDigits || 2));
      }
      return val;
   }

   buildMask() {
      return `1.${this.decimalDigits}-${this.decimalDigits}`;
   }

   handleEnterKeyPress(event) {
      this.applyMask();
   }
}
