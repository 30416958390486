export class PixIntegration {
   public Id: string;
   public CreatedBy: string;
   public LastModifiedOn: string | null;
   public LastModifiedBy: string;
   public CreatedOn: string;
   public IntegrationId: string;
   public CompanyId: number;
   public CompanyName: string;
   public AccountId: string;
   public Status: string;
   public IsDeliveryEnabled: boolean;
   public IsActive: boolean;
}

export class PixIntegrationSetDeliveryStatusRequest {
   public Id: string;
   public IsDeliveryEnabled: boolean;
   public CompanyId: number;
}

export class PixAccountResponse {
   public Id: string;
   public CompanyId: number;
   public StatusCode: PixAccountStatus;
   public LegalName: string;
   public Cnpj: string;
   public PhoneNumber: string;
   public Email: string;
   public OpeningDate: string;
   public Address: PixAccountAddressInfo;
   public Representative: PixAccountRepresentative;
   public Documents: PixAccountCompanyDocument;
   public User: PixUserRequest;

   constructor() {
      this.Address = new PixAccountAddressInfo();
      this.Representative = new PixAccountRepresentative();
      this.Documents = new PixAccountCompanyDocument();
   }
}

export class PixUserRequest {
   public IpAddress: string;
   public DeviceId: string;
   public CityName: string;
   public StateName: string;
   public CountryName: string;
   public Latitude: string;
   public Logitude: string;
}

export class PixAccountAddressInfo {
   public AddressLine: string;
   public AddressNumber: string;
   public AddressLine2: string;
   public Neighborhood: string;
   public CityName: string;
   public ZipCode: string;
   public StateAcronym: string;
   public CountryAcronym: string;
}

export class PixAccountRepresentative {
   public Name: string;
   public Cpf: string;
   public BirthDate: string;
   public MothersName: string;
   public PhoneNumber: string;
   public Email: string;
   public Address: PixAccountAddressInfo;
   public DocumentImages: PixAccountDocumentImage;

   constructor() {
      this.Address = new PixAccountAddressInfo();
      this.DocumentImages = new PixAccountDocumentImage();
   }
}

export class PixAccountDocumentImage {
   public IdentityFront: string;
   public IdentityBack: string;
   public FacePicture: string;
}

export class PixAccountCompanyDocument {
   public SocialContract: string;
}

export class PixAccountBalanceResponse {
   public Id: string;
   public Date: Date;
   public AmountAvailable: number;
}

export class PixAccountListTransactionsRequest {
   public Id: string;
   public StartDate: Date;
   public EndDate: Date;
}

export class PixAccountListTransactionsResponse {
   public Id: string;
   public Statement: PixAccountStatementSummaryResponse[];
}

export class PixIntegrationAccountStatusResponse {
   public CompanyId: number;
   public Id: string;
   public StatusCode: number;
}

export class PixAccountStatementSummaryResponse {
   public TransactionId: string;
   public EmissionDate: Date;
   public Type: string;
   public Description: string;
   public Comment: string;
   public TotalValue: number;
   public Code: string;
}

export class PixAccountGetTransactiondRequest {
   public Id: string;
   public TransactionId: string;
}

export class PixIntegrationWithdrawRequest {
   public CompanyId: number;
   public BankAccountId: string;
   public Value: number;
}

export class GetPixIntegrationAccountStatementResponse {
   public Id: string;
   public TransactionId: string;
   public EmissionDate: string;
   public Status: string;
   public Description: string;
   public TotalValue: number;
   public Sender: PixAccountAccountInfo;
   public Recipient: PixAccountAccountInfo;
}

export class PixAccountAccountInfo {
   public Name: string;
   public MaskedDocument: string;
   public AccountBranch: string;
   public AccountId: string;
   public AccountType: string;
}

export enum PixAccountStatus {
   Regular = '1',
   Pending = '2',
   Closed = '3',
   Rejected = '4',
   Blocked = '5',
   Error = '6',
   Unknown = '9',
}

export class PixIntegrationRefundPaymentRequest {
   public CompanyId: number;
   public Comment: string;
   public ReasonCode: string;
   public ReasonDescription: string;
   public ReasonId: string;
   public TransactionId: string;
   public Value: number;
}

export class PixIntegrationRefundPaymentResponse {
   public Id: string;
   public Code: string;
   public Name: string;
   public Description: string;
}

export class PixIntegrationOperationFeeResponse {
   CompanyId: number;
   OperationFees: OperationFee[];
}

export class OperationFee {
   Id: string;
   OperationTypeName: string;
   PlatformTypeName: string;
   OperationFeeTypeName: string;
   OperationFeeValue: number;
   OperationMinValue: number;
}

export class PixIntegrationAccountSummaryResponse {
   IntegrationId: string;
   CompanyId: number;
   CompanyName: string;
   StatusCode: PixAccountStatus;
   StatusDescription: string;
   CreatedOn: Date;
   CreatedBy: string;
   LastModifiedOn?: Date;
   LastModifiedBy: string;
}

export class PixErrorResponse {
   Id: string;
   CompanyId: number;
   Domain: string;
   OccurenceDate: string;
   ErrorMessage: string;
}
