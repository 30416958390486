export class NfeAddress {
  public Id: string;
  public AddressTypeId: string;
  public AddressTypeName: string;
  public AddressLine1: string;
  public AddressLine2: string;
  public AddressNumber: string;
  public Neighborhood: string;
  public CountryCode: string;
  public CityId: string;
  public CityCode: string;
  public CityName: string;
  public StateId: string;
  public StateCode: string;
  public StateAcronym: string;
  public StateName: string;
  public CountryId: string;
  public CountryName: string;
  public ZipCode: string;
  public IsMain: boolean;
}

export class NfeIssuerInfo {
  public Id: string;
  public Name: string;
  public Cnpj: string;
  public Cpf: string;
  public StateRegistration: string;
  public SuframaRegistration: string;
  public MunicipalRegistration: string;
  public Email: string;
  public Phone: string;
  public TaxRegimeCode: number;
  public BusinessTypeId: string;
  public FiscalRegimeTypeName: string;
  public StateId: string;

  public Address: NfeAddress;

  constructor() {
    this.Address = new NfeAddress();
  }
}

export class NfeRecipientInfo {
  public Id: string;
  public Name: string;
  public PersonType: number;
  public BusinessTypeId: string;
  public FiscalRegimeTypeName: string;
  public Cnpj: string;
  public Cpf: string;
  public IeIndicator: number;
  public StateRegistration: string;
  public SuframaRegistration: string;
  public MunicipalRegistration: string;
  public Email: string;
  public Phone: string;

  public Address: NfeAddress;

  constructor() {
    this.Address = new NfeAddress();
  }
}

export class NfeShippingCompanyInfo {
  public Id: string;
  public Name: string;
  public Cnpj: string;
  public Cpf: string;
  public StateRegistration: string;
  public SuframaRegistration: string;
  public MunicipalRegistration: string;
  public Email: string;
  public Phone: string;

  public Address: NfeAddress;

  constructor() {
    this.Address = new NfeAddress();
  }
}

export class NfePaymentFormInstallment {
  public Number: number;
  public Value: number;
  public DueDay: number;
  public Quota: number;

  constructor(number: number, value: number, dueDay: number, quota: number) {
    this.Number = number;
    this.Value = value;
    this.DueDay = dueDay;
    this.Quota = quota;
  }
}

export class NfeProduct {
  public itemNumber: number;
  public ProductId: string;
  public ProductBarcode: string;
  public ProductName: string;
  public ProductUnitAcronym: string;
  public NcmId: string;
  public NcmCode: string;
  public NcmDisplayName: string;
  public NcmName: string;
  public CestId: string;
  public CestCode: string;
  public CestName: string;
  public CestDisplayName: string;
  public Quantity: number;
  public DiscountValue: number;
  public FreightValue: number;
  public InsuranceValue: number;
  public Value: number;
  public TotalValue: number;
  public NetTotalValue: number;
  public AncillaryExpenseValue: number;
  public IsRelevantScale: boolean;
  public ManufacturerCnpj: string;
  public RecipientCode: string;
  public Nve: string;
  public ExTipi: string;
  public CfopId: string;
  public CfopCode: string;
  public FiscalBenefitCode: string;
  public CstIcmsId: string;
  public CstIcmsCode: string;
  public CstOriginId: string;
  public CstOriginCode: string;
  public IcmsDeterminationMethodId: string;
  public IcmsDeterminationMethodCode: string;
  public IcmsCalculationBasis: number;
  public IcmsReduction: number;
  public IcmsAliquot: number;
  public IcmsValue: number;
  public IcmsOperationValue: number;
  public IcmsDeferral: number;
  public IcmsDeferredValue: number;
  public IcmsFcpCalculationBasis: number;
  public IcmsFcpAliquot: number;
  public IcmsFcpValue: number;
  public IcmsExemptionReasonId: string;
  public IcmsExemptionReasonCode: string;
  public IcmsExemptionValue: number;
  public SubstitutionIcmsDeterminationMethodId: string;
  public SubstitutionIcmsDeterminationMethodCode: string;
  public SubstitutionIcmsCalculationBasis: number;
  public SubstitutionIcmsReduction: number;
  public SubstitutionIcmsMargin: number;
  public SubstitutionIcmsAliquot: number;
  public SubstitutionIcmsValue: number;
  public SubstitutionIcmsFcpCalculationBasis: number;
  public SubstitutionIcmsFcpAliquot: number;
  public SubstitutionIcmsFcpValue: number;
  public SubstitutionIcmsWithheldCalculationBasis: number;
  public SubstitutionIcmsWithheldAliquot: number;
  public SubstitutionIcmsWithheldSubValue: number;
  public SubstitutionIcmsWithheldValue: number;
  public SubstitutionIcmsFcpWithheldCalculationBasis: number;
  public SubstitutionIcmsFcpWithheldAliquot: number;
  public SubstitutionIcmsFcpWithheldValue: number;
  public EffectiveIcmsCalculationBasis: number;
  public EffectiveIcmsReduction: number;
  public EffectiveIcmsAliquot: number;
  public EffectiveIcmsValue: number;
  public IcmsCreditAliquot: number;
  public IcmsCreditValue: number;
  public CstPisId: string;
  public CstPisCode: string;
  public PisCalculationBasis: number;
  public SubstitutionPisCalculationBasis: number;
  public PisValue: number;
  public SubstitutionPisValue: number;
  public PisAliquotPercent: number;
  public SubstitutionPisAliquotPercent: number;
  public PisAliquotValue: number;
  public SubstitutionPisAliquotValue: number;
  public PisQuantity: number;
  public SubstitutionPisQuantity: number;
  public IsCalculationByPisValue: boolean;
  public IsCalculationBySubstitutionPisValue: boolean;
  public IsCalculationByCofinsValue: boolean;
  public IsCalculationBySubstitutionCofinsValue: boolean;
  public CstCofinsId: string;
  public CstCofinsCode: string;
  public CofinsCalculationBasis: number;
  public SubstitutionCofinsCalculationBasis: number;
  public CofinsValue: number;
  public SubstitutionCofinsValue: number;
  public CofinsAliquotPercent: number;
  public SubstitutionCofinsAliquotPercent: number;
  public CofinsAliquotValue: number;
  public SubstitutionCofinsAliquotValue: number;
  public CofinsQuantity: number;
  public SubstitutionCofinsQuantity: number;
  public FederalAliquot: number;
  public MunicipalAliquot: number;
  public StateAliquot: number;
  public ImportAliquot: number;
  public FederalTaxValue: number;
  public StateTaxValue: number;
  public MunicipalTaxValue: number;
  public ImportTaxValue: number;
  public TaxValue: number;
  public IcmsCalculationBasisDestinationState: number;
  public IcmsInternalAliquotDestinationState: number;
  public IcmsInterstateAliquot: number;
  public IcmsFcpCalculationBasisDestinationState: number;
  public IcmsFcpDestinationState: number;
  public IcmsInterstateTotalDifferentialAliquot: number;
  public IcmsInterstatePartOfOriginState: number;
  public IcmsInterstateChosenDifalOriginState: number;
  public IcmsInterstatePartOfDestinationState: number;
  public IcmsInterstateChosenDifalDestinationState: number;
  public IcmsInterstateFcpDestinationState: number;


  constructor() {
    this.IsRelevantScale = true;
    this.AncillaryExpenseValue = 0;
    this.FreightValue = 0;
    this.DiscountValue = 0;
    this.InsuranceValue = 0;
    this.NetTotalValue = 0;
    this.IsCalculationByPisValue = false;
    this.IsCalculationByCofinsValue = false;
    this.IsCalculationBySubstitutionCofinsValue = false;
    this.IsCalculationBySubstitutionPisValue = false;
  }
}

export class NfePaymentForm {
  public PaymentFormId: string;
  public PaymentFormName: string;
  public Value: number;
  public PaymentTermId: string;
  public PaymentTermName: string;
  public Installments: NfePaymentFormInstallment[];
}

export class NfeCorrectionLeter {
  public ItemNumber: number;
  public CorrectionText: string;
  public ProcessingDate: string;

  constructor() {
    this.CorrectionText = "";
  }
}

export class NfeReferencedFiscalDocument {
  public AccessKey: string;
}

export class NfeTotal {
  public IcmsTotalCalculationBasis: number;
  public IcmsTotalValue: number;
  public SubstitutionIcmsTotalCalculationBasis: number;
  public SubstitutionIcmsTotalValue: number;
  public IcmsExemptedTotalValue: number;
  public FcpTotalValue: number;
  public SubstitutionFcpTotalValue: number;
  public SubstitutionFcpWithheldTotalValue: number;
  public ProductTotalValue: number;
  public FreightTotalValue: number;
  public InsuranceTotalValue: number;
  public DiscountTotalValue: number;
  public ImportTaxTotalValue: number;
  public IpiTotalValue: number;
  public PisTotalValue: number;
  public CofinsTotalValue: number;
  public AncillaryExpenseTotalValue: number;
  public NfeTotalValue: number;
  public TaxTotalValue: number;

  constructor() {
    this.IcmsTotalCalculationBasis = 0;
    this.IcmsTotalValue = 0;
    this.SubstitutionIcmsTotalCalculationBasis = 0;
    this.SubstitutionIcmsTotalValue = 0;
    this.IcmsExemptedTotalValue = 0;
    this.FcpTotalValue = 0;
    this.SubstitutionFcpTotalValue = 0;
    this.SubstitutionFcpWithheldTotalValue = 0;
    this.ProductTotalValue = 0;
    this.FreightTotalValue = 0;
    this.InsuranceTotalValue = 0;
    this.DiscountTotalValue = 0;
    this.ImportTaxTotalValue = 0;
    this.IpiTotalValue = 0;
    this.PisTotalValue = 0;
    this.CofinsTotalValue = 0;
    this.AncillaryExpenseTotalValue = 0;
    this.NfeTotalValue = 0;
    this.TaxTotalValue = 0;
  }
}

export class Nfe {
  public Id: string;
  public CompanyId: number;
  public CompanyName: string;

  public NfeIntegrationId: string;
  public AccessKey: string;
  public SefazResponse?: string;
  public StatusCode: NfeStatus;
  public StatusName: string;
  public FiscalDocumentSeriesId: string;
  public FiscalDocumentSeries: number;
  public FiscalDocumentNumber: number;
  public FiscalOperationTypeId: string;
  public FiscalOperationTypeName: string;
  public FiscalOperationTypeDetailId: string;
  public FiscalOperationTypeDetailName: string;
  public EmissionDate: Date;
  public EmissionDateView: string;
  public EntryExitDate: Date;
  public EntryExitDateView: string;
  public EntryExitTime: string;
  public ConsumerPresenceCode: string;
  public ConsumerPresenceName: string;
  public ContingencyJustification: string;
  public IsFinalConsumer: boolean;
  public IsEntry: boolean;
  public IsComplementaryNfe: boolean;
  public IsDevolutionNfe: boolean;
  public HasDifferentPickupPlace: boolean;
  public HasDifferentDeliveryPlace: boolean;
  public FreightModeCode: string;
  public FreightModeName: string;
  public FreightVolumeQuantity: number;
  public FreightVolumeType: string;
  public FreightVolumeGrossWeight: number;
  public FreightVolumeNetWeight: number;

  public CreatedOn: Date;
  public LastModifiedOn: Date;
  public CreatedBy: string;
  public LastModifiedBy: string;

  public NfeReferencedFiscalDocument: NfeReferencedFiscalDocument;
  public Issuer: NfeIssuerInfo;
  public Recipient: NfeRecipientInfo;
  public ShippingCompany: NfeShippingCompanyInfo;
  public PickupPlace: NfeAddress;
  public DeliveryPlace: NfeAddress;
  public Products: NfeProduct[];
  public PaymentForms: NfePaymentForm[];
  public CorrectionLetter: NfeCorrectionLeter;

  public ProductToAdd: NfeProduct;
  public ProductToEdit: NfeProduct;
  public PaymentFormToAdd: NfePaymentForm;

  public AdditionalInformation: string;
  public CancelationJustification: string;

  public Total: NfeTotal;

  constructor() {
    this.IsFinalConsumer = false;
    this.HasDifferentPickupPlace = false;
    this.HasDifferentDeliveryPlace = false;
    this.Issuer = new NfeIssuerInfo();
    this.Recipient = new NfeRecipientInfo();
    this.ShippingCompany = new NfeShippingCompanyInfo();
    this.PickupPlace = new NfeAddress();
    this.DeliveryPlace = new NfeAddress();
    this.NfeReferencedFiscalDocument = new NfeReferencedFiscalDocument();
    this.Products = [];
    this.PaymentForms = [];
    this.CorrectionLetter = new NfeCorrectionLeter();
    this.ProductToAdd = new NfeProduct();
    this.ProductToEdit = new NfeProduct();
    this.PaymentFormToAdd = new NfePaymentForm();
    this.Total = new NfeTotal();
    this.FreightModeCode = '9';
    this.IsComplementaryNfe = false;
    this.IsDevolutionNfe = false;
  }
}

export class NfeRecipientAutocomplete {
  public Id: string;
  public Name: string;
  public Document: string;
}

export class NfeShippingCompanyAutocomplete {
  public Id: string;
  public Name: string;
  public Document: string;
}

export class NfeRecipientAddressAutocomplete {
  public Id: string;
  public AddressLine1: string;
}

export class NfeTotalIcmsOutput {
  public IcmsTotalCalculationBasis: number;
  public IcmsTotalValue: number;
  public SubstitutionIcmsTotalCalculationBasis: number;
  public SubstitutionIcmsTotalValue: number;
  public IcmsExemptedTotalValue: number;

  constructor() {
    this.IcmsTotalCalculationBasis = 0;
    this.IcmsTotalValue = 0;
    this.SubstitutionIcmsTotalCalculationBasis = 0;
    this.SubstitutionIcmsTotalValue = 0;
    this.IcmsExemptedTotalValue = 0;
  }
}

export class NfeTotalFcpOutput {
  public IcmsFcpValue: number;
  public SubstitutionIcmsFcpValue: number;
  public SubstitutionIcmsFcpWithheldValue: number;

  constructor() {
    this.IcmsFcpValue = 0;
    this.SubstitutionIcmsFcpValue = 0;
    this.SubstitutionIcmsFcpWithheldValue = 0;
  }
}

export class CalculateTotalNfeRequest {
  public ProductTotalValue: number;
  public DiscountTotalValue: number;
  public IcmsExemptedTotalValue: number;
  public SubstitutionIcmsTotalValue: number;
  public SubstitutionFcpTotalValue: number;
  public FreightTotalValue: number;
  public InsuranceTotalValue: number;
  public AncillaryExpenseTotalValue: number;
  public ImportTaxTotalValue: number;
  public IpiTotalValue: number;
}

export class CalculateFcpRequest {
  public FcpAliquot: number;
  public FcpCalculationBasis: number;
  public SubstitutionIcmsFcpCalculationBasis: number;
  public SubstitutionIcmsFcpAliquot: number;
  public SubstitutionIcmsFcpWithheldCalculationBasis: number;
  public SubstitutionIcmsFcpWithheldAliquot: number;
}

export class CalculateIcmsCalculationBasis {
  public TotalGrossValue: number;
  public FreightValue: number;
  public InsuranceValue: number;
  public AncillaryExpenseValue: number;
  public DiscountValue: number;
}

export class CalculateIcmsRequest {
  public IcmsCalculationBasis: number;
  public IcmsReduction: number;
  public IcmsAliquot: number;
  public IcmsDefferedValue: number;
}

export class CalculateIcmsCreditRequest {
  public IcmsCalculationBasis: number;
  public IcmsAliquot: number;
}

export class CalculateIcmsStRequest {
  public SubstitutionIcmsCalculationBasis: number;
  public SubstitutionIcmsReduction: number;
  public SubstitutionIcmsMargin: number;
  public SubstitutionIcmsAliquot: number;
  public OwedIcmsValue: number;
}

export class CalculatePisRequest {
  public PisAliquot: number;
  public PisCalculationBasis: number;
  public SubstitutionPisAliquot: number;
  public SubstitutionPisCalculationBasis: number;
}

export class CalculateCofinsRequest {
  public CofinsAliquot: number;
  public CofinsCalculationBasis: number;
  public SubstitutionCofinsAliquot: number;
  public SubstitutionCofinsCalculationBasis: number;
}

export class CalculateInterstateTaxRequest {
  public IcmsCalculationBasisDestinationState: number;
  public IcmsInternalAliquotDestinationState: number;
  public IcmsInterstateAliquot: number;
  public IcmsFcpDestinationState: number;
  public IcmsFcpCalculationBasisDestinationState: number;
}

export class CalculateProductTotalNetValueRequest {
  public TotalGrossValue: number;
  public FreightValue: number;
  public InsuranceValue: number;
  public SubstitutionIcmsValue: number;
  public OtherExpenseValue: number;
  public ImportTaxValue: number;
  public IpiValue: number;
  public DiscountValue: number;
}

export class CalculateIbptTaxRequest {
  public FederalTax: number;
  public ImportTax: number;
  public StateTax: number;
  public MunicipalTax: number;
  public CstOriginCode: string;
  public TotalValue: number;
}

export class BuildIbptPhraseRequest {
  public FederalTaxValue: number;
  public StateTaxValue: number;
  public MunicipalTaxValue: number;
  public HasProduct: boolean;
  public HasService: boolean;
}

export class NfeCancelRequest {
  public Id: string;
  public CompanyId: number;
  public CancelationJustification: string;
}

export class NfeAuthorizeRequest {
  public Id: string;
  public CompanyId: number;
  public UtcOffset: number;
}

export class NfeSendMailRequest {
  public Id: string;
  public UtcOffset: number;
}

export class NfeCorrectionLetterRequest {
  public Id: string;
  public CompanyId: number;
  public CorrectionText: string;
}

export class NfeProductTaxGroupRequest {
  OriginStateId: string;
  OriginBusinessTypeId: string;
  OriginFiscalRegimeTypeName: string;
  DestinationStateId: string;
  DestinationBusinessTypeId: string;
  DestinationFiscalRegimeTypeName: string;
  IsEntry: Boolean;
  CstOriginId: string;
  CompanyId: number;
  ProductId: string;
}

export class NfeInvalidateRequest {
  public Id: string;
  public CompanyId: number;
  public FiscalDocumentSeriesId: string;
  public FiscalDocumentSeries: number;
  public InitialFiscalDocumentSeries: number;
  public FinalFiscalDocumentSeries: number;
  public Justification: string;
}

export class NfeGetFiscalInfoRequest {
  public CompanyId: number;
  public Id: string;
}

export class NfeGetFiscalInfoResponse {
  public FiscalDocumentSeriesId: string;
  public FiscalDocumentSeries: number;
  public FiscalDocumentNumber: number;
  public FiscalDocumentSeriesName: string;
}

export class NfeGetCorrectionLetterRequest {
  public Id: string;
  public CompanyId: number;
}

export class NfeGetCorrectionLetterResponse {
  public ItemNumber: number;
  public CorrectionText: string;

  constructor() {
    this.ItemNumber = 1;
  }
}

export enum NfeStatus {
  NotSent = '0',
  Contingency = '2',
  Rejected = '3',
  WaitingForAuthorization = '4',
  WaitingForCancelation = '5',
  WaitingForInvalidate = '6',
  Authorized = '7',
  Unutilized = '8',
  Canceled = '10',
  Denied = '11'
}

export class NfeServiceResponse {
  public CodStatus: number;
  public Status: NfeStatus;
  public Motivo: string;
  public DataProcessamento: Date;
  public DataSolicitacao: Date;
  public NumeroRecibo: string;
  public NumeroProtocolo: string;
  public ChaveAcesso: string;
}