import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionResolver } from 'app/api/signalr.connectionresolver';
import { AuthService } from 'app/auth/auth.service';
import { QueryModelUpdated } from 'app/domain/models/query-model-updated.model';
import { Trial } from 'app/domain/models/trial.model';
import { TrialService } from 'app/domain/services/trial.service';
import { NotificationService } from 'app/shared/utils/notification.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'kcms-trial-time-completed',
  templateUrl: './trial-time-completed.component.html',
  styleUrls: ['./trial-time-completed.component.scss']
})

export class TrialTimeCompletedComponent implements OnInit {

    public modalRef: BsModalRef;
    public isModalOpened: boolean;
    public currentPlanName: string;
    public oldPlanName: string;

    @Input() trialPlan: Trial

    @ViewChild('trial', { static: true }) public trialCompleted: TemplateRef<any>;

    constructor(
        private modalService: BsModalService,
        private router: Router,        
        private notificationService: NotificationService,
        private authService: AuthService,
        private connectionResolver: ConnectionResolver,
        private trialService: TrialService,
    ) { }

    ngOnInit() { }    

    openModal() {  
      
      this.currentPlanName = this.trialPlan?.CurrentPlanName.substr(4)
      this.oldPlanName = this.trialPlan.OldPlanName.substr(4)

      const config = new ModalOptions();
      config.ignoreBackdropClick = true;
      config.keyboard = false;
      config.class = 'modal-dialog-centered'
      this.modalRef = this.modalService.show(this.trialCompleted, config);
      this.isModalOpened = true;  
    }
    
    closeModal() {        
        this.modalRef.hide(); 
    } 

    handlePlans() {
      this.router.navigate(['/planos']);
    }

    onHirePlan(idPlanTrial) {
      this.closeModal()

      const companyId = localStorage.getItem('id');
      this.router.navigate([`/contratarplano/${companyId}/${idPlanTrial}`]);
   }

    onEndTrialPlan() {
      let subscription = null;

      this.notificationService.showConfirmBox('TrialEndTestTitle', 'TrialEndTestMsg', 'warning').then(ret => {
          if (ret) {
            this.notificationService.showAlertMessage();
              this.trialService.acknowledgeTrialEnded(this.trialPlan.Id).subscribe(ret => {
              }, err => {
                  if (subscription != null){
                    subscription.unsubscribe();
                  }
              })
          }
        }, err => {
            if (subscription != null){
              subscription.unsubscribe();
            }
        })

        subscription = this.connectionResolver.queryModelUpdated.subscribe((message: QueryModelUpdated) => {
          if (message.Message === this.trialService.trialEndedMessage) { 
            this.closeModal();
            this.authService.logout();
            subscription.unsubscribe();
          }
        });            
  }
}