import { Injectable } from '@angular/core';
import { ApiService } from 'app/api/api.service';
import { Observable } from 'rxjs';
import { HirePlan } from '../models/hire-plan.model';

@Injectable()
export class CompanyPlanService {
   domainName = 'CompanyPlan';
   hirePlanMessage = 'HirePlan';

   constructor(private apiService: ApiService) {}

   hirePlan(obj: HirePlan): Observable<any> {
      return this.apiService.post(`${this.domainName}/HirePlan`, obj, this.hirePlanMessage);
   }
}
