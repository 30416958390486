import { NgModule } from '@angular/core';
import { BodyService } from './body.service';
import { FieldFilterPipe } from './field-filter.pipe';
import { FunctionService } from './function.service';
import { MomentPipe } from './moment.pipe';
import { NotificationService } from './notification.service';
import { TimeDirective } from './time.directive';
import { ToggleActiveDirective } from './toggle-active.directive';

@NgModule({
   declarations: [ToggleActiveDirective, MomentPipe, TimeDirective, FieldFilterPipe],
   exports: [ToggleActiveDirective, MomentPipe, TimeDirective, FieldFilterPipe],
   providers: [FunctionService, BodyService, NotificationService],
})
export class UtilsModule {
   static forRoot() {
      return {
         ngModule: UtilsModule,
         providers: [FunctionService, BodyService, NotificationService],
      };
   }
}
