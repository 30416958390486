import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccessType, AuthService } from 'app/auth/auth.service';
import { CompanyService } from 'app/domain/services/company.service';
import { NavigationService } from 'app/domain/services/navigation.service';
import { TrialService } from 'app/domain/services/trial.service';
import { I18nService } from 'app/shared/i18n';
import { FunctionService } from 'app/shared/utils/function.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../layout.service';
declare var mixpanel: any;
declare var $: any;

@Component({
   selector: 'sa-navigation',
   templateUrl: './navigation.component.html',
   styleUrls: ['./minify-menu.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
   public showMenu = false;
   public authorizationTokenObservable: Subscription;
   public permissions: string[] = [];
   public userMenuItems = [];
   public menuItems = [];
   public trialActived = false;
   public isKcmsUser = false;
   public companyPlan: string;

   constructor(
      public authService: AuthService,
      public i18nService: I18nService,
      public navigationService: NavigationService,
      public layoutService: LayoutService,
      public router: Router,
      private trialService: TrialService,
      private companyService: CompanyService,
      private functionService: FunctionService
   ) {}

   ngOnInit() {
      // const user = this.authService.getUserInfo();
      // this.isKcmsUser = user.Email.includes('@kcms.com.br');

      this.authorizationTokenObservable = this.authService.authorizationTokenObservable.subscribe((val) => {
         if (val) {
            this.startNavigation();
         }

         this.showMenu = val;
      });
   }

   ngOnDestroy() {
      this.authorizationTokenObservable?.unsubscribe();
   }

   startNavigation() {
      this.permissions = [];
      this.userMenuItems = [];
      this.menuItems = this.navigationService.get();

      // Getting all Read permissions from localStorage
      const authPermissions = this.authService.getPermissions();
      if (authPermissions && Array.isArray(authPermissions)) {
         authPermissions.forEach((permission) => {
            this.permissions.push(permission);
         });
      }

      // Removing all hidden items
      this.removeHiddenItems(this.menuItems);

      // Configuring all submenu items that the user doesn't have access
      const isUserCompanyAdmin = this.authService.isUserCompanyAdmin();
      this.configureItemsWithoutPermission(this.menuItems, isUserCompanyAdmin);

      // Configuring Items trial and coming soon
      this.configureItemsTrialAndComingSoon(this.menuItems);

      // Configuring submenu items with special access rules
      this.configureItemsWithSpecialRules(this.menuItems);

      // Removing all empty submenus that doesn't have links
      for (let i = 0; i < this.menuItems.length; i++) {
         if (!this.menuItems[i].Link && (!this.menuItems[i].Items || this.menuItems[i].Items.length === 0)) {
            this.menuItems.splice(i, 1);
            i--;
         }
      }

      this.userMenuItems = this.menuItems;
   }

   removeHiddenItems(menu: any[]): void {
      const user = this.authService.getUserInfo();

      if (menu && menu.length > 0) {
         for (let i = 0; i < menu.length; i++) {
            if (menu[i].Items && menu[i].Items.length > 0) {
               this.removeHiddenItems(menu[i].Items);
            }

            if (menu[i].HideInMenu || (!user.Email.includes('@kcms.com.br') && menu[i].KcmsUserOnly)) {
               menu.splice(i, 1);
               i--;
            }
         }
      }
   }

   configureItemsWithoutPermission(menu: any[], isUserCompanyAdmin: boolean): void {
      if (menu && menu.length > 0) {
         for (let i = 0; i < menu.length; i++) {
            if (menu[i].Items && menu[i].Items.length > 0) {
               this.configureItemsWithoutPermission(menu[i].Items, isUserCompanyAdmin);
            }

            if (
               menu[i].Permission &&
               !this.permissions.includes(menu[i].Permission + '-' + (menu[i].AccessType ? menu[i].AccessType : 'Read'))
            ) {
               if (isUserCompanyAdmin) {
                  menu[i].IsOuterLink = true;
                  menu[i].Trial = true;
                  menu[i].AdditionalClass = 'no-permission';
               } else {
                  menu.splice(i, 1);
                  i--;
               }
            }
         }
      }
   }

   configureItemsTrialAndComingSoon(menu: any[]): void {
      const user = this.authService.getUserInfo();

      if (menu && menu.length > 0) {
         for (let i = 0; i < menu.length; i++) {
            if (menu[i].Items && menu[i].Items.length > 0) {
               this.configureItemsTrialAndComingSoon(menu[i].Items);
            }

            if (menu[i].Trial && menu[i].AdditionalClass && !user.Email.includes('@kcms.com.br')) {
               menu[i].Link = '/planos';
               menu[i].Active = '';
            } else if (!menu[i].Link && !menu[i].TrialMenu && !user.Email.includes('@kcms.com.br')) {
               menu[i].Link = '';
               menu[i].Active = '';
            } else if (menu[i].ComingSoon && !menu[i].ComingSoonMenu && !user.Email.includes('@kcms.com.br')) {
               menu[i].Link = '/embreve';
               menu[i].IsOuterLink = false;
               menu[i].Active = '';
            } else if (menu[i].ComingSoon && menu[i].ComingSoonMenu && !user.Email.includes('@kcms.com.br')) {
               menu[i].Link = '';
               menu[i].IsOuterLink = false;
               menu[i].Active = '';
            } else if (menu[i].Trial && menu[i].ComingSoon && !user.Email.includes('@kcms.com.br')) {
               menu[i].Link = '/embreve';
               menu[i].IsOuterLink = false;
               menu[i].Active = '';
               menu[i].Trial = false;
            } else if (menu[i].ComingSoonMenu || menu[i].ComingSoon) {
               menu[i].Link = '/embreve';
               menu[i].IsOuterLink = false;
               menu[i].Active = '';
               menu[i].Trial = false;
            } else {
               menu[i].Trial = false;
               menu[i].ComingSoon = false;
               menu[i].Active = 'active';
            }
         }
      }
   }

   configureItemsWithSpecialRules(menu: any[]): void {
      if (menu && menu.length > 0) {
         for (let i = 0; i < menu.length; i++) {
            if (menu[i].Items && menu[i].Items.length > 0) {
               this.configureItemsWithSpecialRules(menu[i].Items);
            }

            if (menu[i].Permission == 'RoyaltiesMarketingFund') {
               let removeMenu = true;
               const companyToken = this.authService.getCompanies();
               if (companyToken && companyToken.CompanyItems && companyToken.CompanyItems.length > 0) {
                  const allowedCompanies = this.authService.getCompaniesFromGroups('RoyaltiesMarketingFund', AccessType.Read);
                  for (let j = 0; j < companyToken.CompanyItems.length; j++) {
                     const company = companyToken.CompanyItems[j];
                     if (
                        (allowedCompanies.includes(1) || allowedCompanies.includes(company.CompanyId)) &&
                        company.ParentId === company.CompanyId &&
                        company.HasChildren
                     ) {
                        removeMenu = false;
                        break;
                     }
                  }
               }

               if (removeMenu) {
                  menu.splice(i, 1);
                  i--;
               }
            }
         }
      }
   }

   menuClicked(menuName: string, submenuName: string) {
      try {
         mixpanel.track('Menu Item Clicked', {
            Menu: this.i18nService.getTranslation(menuName),
            Submenu: submenuName ? this.i18nService.getTranslation(submenuName) : '',
         });
      } catch (err) {}

      if ($('.mobile-view-activated').length) {
         this.layoutService.onCollapseMenu();
      }
   }

   onHirePlan() {
      const companyId = Number(localStorage.getItem('id'));

      if (companyId > 0) {
         this.router.navigate([`/planos/planosdisponiveis`]);
      }
   }
}
