import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from './can-deactivate-guard.service';
import { MainLayoutComponent } from './shared/layout/app-layouts/main-layout.component';

export const routes: Routes = [
   {
      path: 'login',
      loadChildren: () => import('app/login/login.module').then((m) => m.LoginModule),
   },
   {
      path: 'recuperacaodesenha',
      loadChildren: () => import('app/login/login.module').then((m) => m.LoginModule),
   },
   {
      path: 'assinatura',
      loadChildren: () => import('app/signup/signup.module').then((m) => m.SignupModule),
   },
   {
      path: 'novasenha',
      loadChildren: () => import('app/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
   },
   {
      path: 'filaespera',
      loadChildren: () =>
         import('app/relationship/waitlist-customer-position/waitlist-customer-position.module').then(
            (m) => m.WaitlistCustomerPositionModule
         ),
   },
   {
      path: 'centralpedidospos',
      loadChildren: () => import('app/pos-components/order-center-pos/order-center-pos.module').then((m) => m.OrderCenterPosModule),
   },
   {
      path: 'clientepos',
      loadChildren: () => import('app/pos-components/customer-pos/customer-pos.module').then((m) => m.CustomerPosModule),
   },
   {
      path: 'contratarplano',
      loadChildren: () => import('app/hire-plan/hire-plan.module').then((m) => m.HirePlanModule),
   },
   {
      path: '',
      component: MainLayoutComponent,
      children: [
         {
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full',
         },
         {
            path: 'home',
            loadChildren: () => import('app/home/home.module').then((m) => m.HomeModule),
         },
         {
            path: 'dashboard',
            loadChildren: () => import('app/dashboard/dashboard.module').then((m) => m.DashboardModule),
         },
         {
            path: 'centralpedidos',
            loadChildren: () => import('app/order-center/order-center.module').then((m) => m.OrderCenterModule),
         },
         {
            path: 'menu',
            loadChildren: () => import('app/menu/menu.module').then((m) => m.MenuModule),
            canDeactivate: [CanDeactivateGuard],
         },
         {
            path: 'materiais',
            loadChildren: () => import('app/materials/materials.module').then((m) => m.MaterialsModule),
         },
         {
            path: 'estoque',
            loadChildren: () => import('app/stock/stock.module').then((m) => m.StockModule),
         },
         {
            path: 'pdv',
            loadChildren: () => import('app/pos/pos.module').then((m) => m.PosModule),
         },
         {
            path: 'financeiro',
            loadChildren: () => import('app/financial/financial.module').then((m) => m.FinancialModule),
         },
         {
            path: 'fiscal',
            loadChildren: () => import('app/fiscal/fiscal.module').then((m) => m.FiscalModule),
         },
         {
            path: 'relatorio',
            loadChildren: () => import('app/reports/reports.module').then((m) => m.ReportsModule),
         },
         {
            path: 'marketing',
            loadChildren: () => import('app/marketing/marketing.module').then((m) => m.MarketingModule),
         },
         {
            path: 'relacionamento',
            loadChildren: () => import('app/relationship/relationship.module').then((m) => m.RelationshipModule),
         },
         {
            path: 'cadastrosbase',
            loadChildren: () => import('app/basic-registrations/basic-registrations.module').then((m) => m.BasicRegistrationsModule),
         },
         {
            path: 'configuracao',
            loadChildren: () => import('app/configuration/configuration.module').then((m) => m.ConfigurationModule),
         },
         {
            path: 'sincronizacao',
            loadChildren: () => import('app/sync/sync.module').then((m) => m.SyncModule),
         },
         {
            path: 'perfil',
            loadChildren: () => import('app/personal/personal.module').then((m) => m.PersonalModule),
         },
         {
            path: 'planos',
            loadChildren: () => import('app/trial/trial.module').then((m) => m.TrialModule),
         },
         {
            path: 'embreve',
            loadChildren: () => import('app/coming-soon/coming-soon.module').then((m) => m.ComingSoonModule),
         },
         {
            path: 'admin',
            loadChildren: () => import('app/admin/admin.module').then((m) => m.AdminModule),
         },
         {
            path: 'lojaaplicativos',
            loadChildren: () => import('app/resource/resource.module').then((m) => m.ResourcelModule),
         },
      ],
   },
   { path: '**', redirectTo: 'dashboard' },
];

export const routing = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
