import { Observable, throwError as observableThrowError } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConnectionResolver } from 'app/api/signalr.connectionresolver';
import { AuthService } from 'app/auth/auth.service';
import { environment } from 'environments/environment';
import { catchError, map } from 'rxjs/operators';
declare var $: any;

export class Filter {
   public Key: string;
   public Value: any;
   public ComparisonType: FilterComparisonType;
   public IsCustom?: boolean;
   constructor() {
      this.ComparisonType = FilterComparisonType.Equal;
      this.IsCustom = false;
   }
}

export class Ordering {
   public Field: string;
   public By: any;
}

export enum FilterComparisonType {
   LessThan,
   LessThanEqual,
   Equal,
   GreaterThan,
   GreaterThanEqual,
   Contains,
}

@Injectable()
export class ApiService {
   private options;

   constructor(
      private http: HttpClient,
      private signalRConnection: ConnectionResolver,
      private authService: AuthService
   ) {}

   buildContainsFilter(key: string, value: string): string {
      if (!key || !value) {
         return '';
      }

      return `contains(tolower(${key}), \'${value.toString().toLowerCase()}\')`;
   }

   get(domain: string, param?: any, apiUrl?: string): Observable<any> {
      if (!apiUrl) {
         apiUrl = environment.apiBaseUrl;
      }
      let url = apiUrl + 'api/' + domain;
      if (param) {
         url += '/' + param;
      }

      url += this.getFilters();
      this.options = this.initializeOptions();

      return this.http.get(url, this.options).pipe(
         catchError((error) => {
            return this.errorResult(error);
         })
      );
   }

   getByThirdPartyUrl(apiUrl: string): Observable<any> {
      apiUrl += this.getFilters();

      return this.http.get(apiUrl, this.options).pipe(
         catchError((error) => {
            return this.errorResult(error);
         })
      );
   }

   getCustom(
      domain: string,
      filters?: Filter[],
      orders?: Ordering[],
      itemsNumber?: number,
      isOData: boolean = false,
      oDataReturnOnlyValue: boolean = true,
      matchAllFilters: boolean = true,
      skip: number = 0,
      apiUrl?: string
   ): Observable<any> {
      return this.getByGroups(
         domain,
         filters,
         null,
         null,
         orders,
         itemsNumber,
         isOData,
         oDataReturnOnlyValue,
         matchAllFilters,
         skip,
         apiUrl
      );
   }

   getByGroups(
      domain: string,
      filters?: Filter[],
      accessDomain?: string,
      accessType?: string,
      orders?: Ordering[],
      itemsNumber?: number,
      isOData: boolean = false,
      oDataReturnOnlyValue: boolean = true,
      matchAllFilters: boolean = true,
      skip: number = 0,
      apiUrl?: string
   ): Observable<any> {
      if (!apiUrl) {
         apiUrl = environment.apiBaseUrl;
      }

      const url =
         apiUrl +
         (isOData ? 'odata/' : 'api/') +
         domain +
         this.getFilters(filters, orders, itemsNumber, matchAllFilters, skip);
      this.options = this.initializeOptions();
      return this.http.get(url, this.options).pipe(
         map((result) => {
            let ret = result as any;
            if (isOData && oDataReturnOnlyValue) {
               ret = ret.value;
            }
            // Filter result by companies with this access type
            if (ret && accessDomain) {
               // Getting all companies with the passed access type for the passed domain
               const companyProperty =
                  domain === 'Company' || domain === 'Company/select' ? 'SimplifiedId' : 'CompanyId';
               const companies = this.authService.getCompaniesFromGroups(accessDomain, accessType);
               for (let i = 0; i < ret.length; i++) {
                  if (
                     ret[i][companyProperty] !== 0 &&
                     !companies.includes(ret[i][companyProperty]) &&
                     !companies.includes(1)
                  ) {
                     ret.splice(i, 1);
                     i--;
                  }
               }
            }

            return ret;
         }),
         catchError((error) => {
            return this.errorResult(error);
         })
      );
   }

   patch(domain: string, data: any, message: string, apiUrl?: string, patchUrl?: string): Observable<any> {
      if (!apiUrl) {
         apiUrl = environment.apiBaseUrl;
      }

      let url = apiUrl + 'api/' + domain;

      if (patchUrl) {
         url += patchUrl;
      }

      this.options = this.initializeOptions(message);

      return this.http.patch(url, data, this.options).pipe(
         catchError((error) => {
            return this.errorResult(error);
         })
      );
   }

   put(domain: string, data: any, message: string, apiUrl?: string): Observable<any> {
      if (!apiUrl) {
         apiUrl = environment.apiBaseUrl;
      }

      const url = apiUrl + 'api/' + domain;
      this.options = this.initializeOptions(message);

      return this.http.put(url, data, this.options).pipe(
         catchError((error) => {
            return this.errorResult(error);
         })
      );
   }

   post(domain: string, data: any, message: string, apiUrl?: string): Observable<any> {
      if (!apiUrl) {
         apiUrl = environment.apiBaseUrl;
      }

      const url = apiUrl + 'api/' + domain;
      this.options = this.initializeOptions(message);

      return this.http.post(url, data, this.options).pipe(
         catchError((error) => {
            return this.errorResult(error);
         })
      );
   }

   postAjax(domain: string, obj: any, message: string): any {
      const url = environment.apiBaseUrl + 'api/' + domain;
      let ret = null;
      let header: any;
      const bindedPosId = sessionStorage.getItem('binded-pos-id');

      if (bindedPosId) {
         header = {
            Device: bindedPosId,
            'Content-Type': 'application/json',
            ClientId: this.signalRConnection.getConnectionId(),
            ClientMessage: message,
         };
      } else {
         header = {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            'Company-Token': localStorage.getItem('company_ids_token') ? localStorage.getItem('company_ids_token') : '',
            'Content-Type': 'application/json',
            ClientId: this.signalRConnection.getConnectionId(),
            ClientMessage: message,
         };
      }

      $.ajax({
         type: 'POST',
         url: url,
         data: obj,
         dataType: 'json',
         async: false,
         success: function (result, status, xhr) {
            ret = result;
         },
         error: function (result, status, xhr) {
            ret = result;
         },
         headers: header,
      });
      return ret;
   }

   delete(domain: string, guid: string, message?: string, apiUrl?: string): Observable<any> {
      if (!apiUrl) {
         apiUrl = environment.apiBaseUrl;
      }

      const url = apiUrl + 'api/' + domain + '/' + guid;
      this.options = this.initializeOptions(message);

      return this.http.delete(url, this.options).pipe(
         catchError((error) => {
            return this.errorResult(error);
         })
      );
   }

   getFilters(
      filters?: Filter[],
      orders?: Ordering[],
      itemsNumber?: number,
      matchAllFilters: boolean = true,
      skip: number = 0
   ): string {
      let url = '';

      // Adding filters (parameter)
      if (filters && filters.length > 0) {
         url += '/?$filter=';

         for (let i = 0; i < filters.length; i++) {
            let comparisonSign = 'eq';
            let comparisonItem = '';

            switch (filters[i].ComparisonType) {
               case FilterComparisonType.LessThan:
                  comparisonSign = 'lt';
                  break;
               case FilterComparisonType.LessThanEqual:
                  comparisonSign = 'le';
                  break;
               case FilterComparisonType.Equal:
                  comparisonSign = 'eq';
                  break;
               case FilterComparisonType.GreaterThan:
                  comparisonSign = 'gt';
                  break;
               case FilterComparisonType.GreaterThanEqual:
                  comparisonSign = 'ge';
                  break;
               case FilterComparisonType.Contains:
                  comparisonSign = '';
                  break;
            }

            if (filters[i].IsCustom) {
               comparisonItem += `(${filters[i].Value})`;
            } else if (filters[i].ComparisonType === FilterComparisonType.Contains) {
               comparisonItem += `(${this.buildContainsFilter(filters[i].Key, filters[i].Value)})`;
            } else {
               comparisonItem += `(${filters[i].Key} ${comparisonSign} ${filters[i].Value})`;
            }

            if (i === 0) {
               url += comparisonItem;
            } else {
               url += `${matchAllFilters ? ' and ' : ' or '}${comparisonItem}`;
            }
         }
      }

      if (orders && orders.length > 0) {
         if (url.indexOf('$filter') > 0) {
            url += '&$orderby=';
         } else {
            url += '/?$orderby=';
         }

         for (let i = 0; i < orders.length; i++) {
            url += i > 0 ? ',' : '';
            url += orders[i].Field + ' ' + orders[i].By.toLowerCase();
         }
      }

      if (itemsNumber > 0) {
         if (url.indexOf('$filter') > 0 || url.indexOf('$orderby') > 0) {
            url += '&$count=true&$top=';
         } else {
            url += '/?$count=true&$top=';
         }
         url += itemsNumber;
      }

      if (skip > 0) {
         if (url.indexOf('$filter') > 0 || url.indexOf('$orderby') > 0 || url.indexOf('$top') > 0) {
            url += '&$skip=';
         } else {
            url += '/?$skip=';
         }
         url += skip;
      }

      return url;
   }

   private initializeOptions(message?: string) {
      let headers: HttpHeaders;
      const bindedPosId = sessionStorage.getItem('binded-pos-id');

      if (bindedPosId) {
         headers = new HttpHeaders({
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            Device: bindedPosId,
            'Content-Type': 'application/json',
            ClientId: this.signalRConnection.getConnectionId() ? this.signalRConnection.getConnectionId() : '',
            ClientMessage: message ? message : '',
         });
      } else {
         headers = new HttpHeaders({
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            'Company-Token': localStorage.getItem('company_ids_token') ? localStorage.getItem('company_ids_token') : '',
            'Content-Type': 'application/json',
            ClientId: this.signalRConnection.getConnectionId() ? this.signalRConnection.getConnectionId() : '',
            ClientMessage: message ? message : '',
         });
      }
      if (headers.get('ClientId').length === 0) {
         headers = headers.delete('ClientId');
      }
      if (headers.get('ClientMessage').length === 0) {
         headers = headers.delete('ClientMessage');
      }
      if (headers.get('Company-Token')?.length === 0) {
         headers = headers.delete('Company-Token');
      }

      return { headers: headers };
   }

   getClientId(): string {
      return this.signalRConnection.getConnectionId();
   }

   private errorResult(error: Response): Observable<any> {
      if (error && error.status === 401) {
         $(location).attr('href', '#/dashboard');
      } else {
         return observableThrowError(error);
      }
   }
}
