import { Directive, ElementRef, OnInit, Input, EventEmitter, Output } from '@angular/core';

declare var $: any;

@Directive({
  selector: '[smartClockpicker]'
})
export class SmartClockpickerDirective implements OnInit {

  @Input() smartClockpicker: any;
  @Input() ngModel;
  @Output() ngModelChange = new EventEmitter();

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    import('clockpicker/dist/bootstrap-clockpicker.min.js').then(() => {
      this.render()
    })
  }


  render() {
    $(this.el.nativeElement).clockpicker(this.smartClockpicker || {
      placement: 'top',
      donetext: 'Done',
      afterDone: function () {
        this.ngModel = $(this.el.nativeElement).val();
        this.ngModelChange.emit(this.ngModel);
      }.bind(this)
    });
  }
}
