export class HirePlan {
  public CompanyId: number;
  public KcmsPlanId: string;
  public IntegrationPlanId: string;
  public PaymentFormCode: string;
  public PhoneNumber: string;
  public CardNumber: string;
  public ExpirationDate: string;
  public SecurityCode: string;
  public CardFlag: string;
  public CardCustomerName: string;
  constructor() {
     this.PaymentFormCode = '3';
  }
}


