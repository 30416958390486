import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccessType, AuthService, AuthUser } from 'app/auth/auth.service';
import { NotificationService } from 'app/shared/utils/notification.service';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';

@Component({
   selector: 'sa-login-info',
   templateUrl: './login-info.component.html',
   styleUrls: ['./login-info.component.scss'],
})
export class LoginInfoComponent implements OnInit, OnDestroy {
   public user: AuthUser;
   public planName: string;
   public companyId: number;
   public hasSupportPermission = false;
   public authorizationTokenObservable: Subscription;
   public emailKcmsMaster = false;
   public version = environment.version;

   constructor(private authService: AuthService, private notificationService: NotificationService) {}

   ngOnInit() {
      this.authorizationTokenObservable = this.authService.authorizationTokenObservable.subscribe((val) => {
         if (val) {
            this.user = this.authService.getUserInfo();
            this.hasSupportPermission = this.authService.hasPermission('Support', AccessType.Read);
            let companies = this.authService.getCompanies();

            if (companies.CompanyItems && companies.CompanyItems.length == 1) {
               this.companyId = companies.CompanyItems[0].CompanyId;
               localStorage.setItem('id', String(this.companyId));
               this.planName = localStorage.getItem('company_plan');
            } else {
               this.companyId = null;
               this.planName = null;
            }
         }
      });

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
   }

   ngOnDestroy() {
      this.authorizationTokenObservable?.unsubscribe();
   }

   logout() {
      this.closeMenuProfile();

      this.notificationService.showConfirmBox('DataLogoutTitle', 'DataLogoutMsg', 'warning').then((ret) => {
         if (ret) {
            this.authService.logout();
         }
      });
   }

   openMenuProfile() {
      var element = document.getElementById('profile');
      element.classList.add('profile-in');
      element.classList.remove('profile-out');
   }

   closeMenuProfile() {
      var element = document.getElementById('profile');
      element.classList.add('profile-out');
      element.classList.remove('profile-in');
   }
}
