import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18nService } from 'app/shared/i18n';
declare var $: any;

@Directive({
   selector: '[saUiDatepicker]',
})
export class UiDatepickerDirective implements OnInit {
   @Input() saUiDatepicker: any;
   @Input() yearRange = '50';
   @Input() ngModel;
   @Output() ngModelChange = new EventEmitter();

   constructor(private el: ElementRef, private i18nService: I18nService) {}

   ngOnInit() {
      this.setConfiguration();
   }

   setConfiguration() {
      this.i18nService.i18nServiceObservable.subscribe((ret) => {
         if (ret) {
            this.saUiDatepicker = $.extend(this.saUiDatepicker || {}, {
               dateFormat: 'dd/mm/yy',
               changeMonth: true,
               changeYear: true,
               yearRange: `-${this.yearRange}:+6`,
               dayNames: [
                  this.i18nService.getTranslation('Sunday'),
                  this.i18nService.getTranslation('Monday'),
                  this.i18nService.getTranslation('Tuesday'),
                  this.i18nService.getTranslation('Wednesday'),
                  this.i18nService.getTranslation('Thursday'),
                  this.i18nService.getTranslation('Friday'),
                  this.i18nService.getTranslation('Saturday'),
               ],
               dayNamesMin: [
                  this.i18nService.getTranslation('SundayMin'),
                  this.i18nService.getTranslation('MondayMin'),
                  this.i18nService.getTranslation('TuesdayMin'),
                  this.i18nService.getTranslation('WednesdayMin'),
                  this.i18nService.getTranslation('ThursdayMin'),
                  this.i18nService.getTranslation('FridayMin'),
                  this.i18nService.getTranslation('SaturdayMin'),
               ],
               dayNamesShort: [
                  this.i18nService.getTranslation('SundayShort'),
                  this.i18nService.getTranslation('MondayShort'),
                  this.i18nService.getTranslation('TuesdayShort'),
                  this.i18nService.getTranslation('WednesdayShort'),
                  this.i18nService.getTranslation('ThursdayShort'),
                  this.i18nService.getTranslation('FridayShort'),
                  this.i18nService.getTranslation('SaturdayShort'),
               ],
               monthNames: [
                  this.i18nService.getTranslation('MonthJanuary'),
                  this.i18nService.getTranslation('MonthFebruary'),
                  this.i18nService.getTranslation('MonthMarch'),
                  this.i18nService.getTranslation('MonthApril'),
                  this.i18nService.getTranslation('MonthMay'),
                  this.i18nService.getTranslation('MonthJune'),
                  this.i18nService.getTranslation('MonthJuly'),
                  this.i18nService.getTranslation('MonthAugust'),
                  this.i18nService.getTranslation('MonthSeptember'),
                  this.i18nService.getTranslation('MonthOctober'),
                  this.i18nService.getTranslation('MonthNovember'),
                  this.i18nService.getTranslation('MonthDecember'),
               ],
               monthNamesShort: [
                  this.i18nService.getTranslation('MonthJanuaryShort'),
                  this.i18nService.getTranslation('MonthFebruaryShort'),
                  this.i18nService.getTranslation('MonthMarchShort'),
                  this.i18nService.getTranslation('MonthAprilShort'),
                  this.i18nService.getTranslation('MonthMayShort'),
                  this.i18nService.getTranslation('MonthJuneShort'),
                  this.i18nService.getTranslation('MonthJulyShort'),
                  this.i18nService.getTranslation('MonthAugustShort'),
                  this.i18nService.getTranslation('MonthSeptemberShort'),
                  this.i18nService.getTranslation('MonthOctoberShort'),
                  this.i18nService.getTranslation('MonthNovemberShort'),
                  this.i18nService.getTranslation('MonthDecemberShort'),
               ],
            });
            this.render();
         }
      });
   }

   render() {
      const onSelectCallbacks = [];
      const saUiDatepicker = this.saUiDatepicker || {};
      const element = $(this.el.nativeElement);
      if (saUiDatepicker.minRestrict) {
         onSelectCallbacks.push((selectedDate) => {
            $(saUiDatepicker.minRestrict).datepicker('option', 'minDate', selectedDate);
         });
      }
      if (saUiDatepicker.maxRestrict) {
         onSelectCallbacks.push((selectedDate) => {
            $(saUiDatepicker.maxRestrict).datepicker('option', 'maxDate', selectedDate);
         });
      }

      // Let others know about changes to the data field
      onSelectCallbacks.push((selectedDate) => {
         element.triggerHandler('change');
         const form = element.closest('form');
         if (typeof form.bootstrapValidator === 'function') {
            try {
               form.bootstrapValidator('revalidateField', element);
            } catch (e) {
               console.log(e.message);
            }
         }
      });

      const options = $.extend(saUiDatepicker, {
         prevText: '<i class="far fa-chevron-left"></i>',
         nextText: '<i class="far fa-chevron-right"></i>',
         onSelect: (selectedDate) => {
            onSelectCallbacks.forEach((callback) => {
               callback.call(callback, selectedDate);
            });
         },
      });

      element.datepicker(options).on(
         'change',
         function (e) {
            this.ngModel = $(this.el.nativeElement).val();
            this.ngModelChange.emit(this.ngModel);
         }.bind(this)
      );
   }
}
