import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { SupportComponent } from './support.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
  ],
  declarations: [
    SupportComponent,
  ],
  exports: [
    SupportComponent,
  ]
})
export class SupportModule { }
