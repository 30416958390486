import { Directive, ElementRef, OnInit, Input, Output, OnChanges, EventEmitter} from '@angular/core';
import { addClassName } from '../../../utils/dom-helpers';
import { I18nService } from 'app/shared/i18n';

declare var $: any;
declare var scriptIsLoaded: boolean;

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[select2]',
})

export class Select2Directive implements OnInit, OnChanges {
  @Input() data: any;
  @Input() comboText: string;
  @Input() comboId: string;
  @Input() placeholder: string;
  @Input() emptyPlaceholder: string;
  @Input() domain: string;
  @Input() isLoading: boolean;
  @Input() loadOnInit: boolean;
  @Input() isMultiple = false;

  @Output() onChange = new EventEmitter();
  @Output() onOpening = new EventEmitter();
  @Output() loadComplete = new EventEmitter();
  @Output() reloadComplete = new EventEmitter();

  lastOpenTimeStamp: number;
  isOpened = false;
  scriptIsLoaded = false;
  noResultsFoundLanguage: any = {};

  arrowKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];

  constructor(private el: ElementRef, private i18nService: I18nService) {
    addClassName(this.el.nativeElement, ['sa-cloak', 'sa-hidden']);
    this.noResultsFoundLanguage = {
      'noResults': function () {
        return i18nService.getTranslation('NoRegistersFound');
      }
    };
  }

  ngOnInit() {
    this.initSelect2();
  }

  ngOnChanges() {}

  initSelect2() {
    const self = this;
    import('select2/dist/js/select2.min.js').then(() => {
      import('select2/dist/js/i18n/pt-BR.js').then(() => {
        this.i18nService.i18nServiceObservable.subscribe(ret => {
          if (ret) {
            if (this.isMultiple) {
              $(this.el.nativeElement).attr('multiple', 'multiple');
            }

            $(self.el.nativeElement).select2({
              closeOnSelect: !this.isMultiple,
              placeholder: this.i18nService.getTranslation(self.emptyPlaceholder),
              'language': this.noResultsFoundLanguage,
              escapeMarkup: function (markup) {
                return markup;
              }
            });

            $(self.el.nativeElement).off('select2:opening');
            $(self.el.nativeElement).on('select2:opening', function (e) {              
              if (self.isLoading) {
                return false;
              }             
              
              this.lastOpenTimeStamp = e.timeStamp; 
              this.isOpened = true;
              this.onOpening.emit(e);

              $(document).ready(() => {
                $('input.select2-search__field').get(0).focus();                  
              }); 

            }.bind(this));

            $(self.el.nativeElement).off('select2:closing');
            $(self.el.nativeElement).on('select2:closing', function (e) {
              if (this.isMultiple && this.lastOpenTimeStamp && this.lastOpenTimeStamp > e.timeStamp - 500) {
                e.preventDefault();
              }
            }.bind(this));

            $(self.el.nativeElement).off('select2:close');
            $(self.el.nativeElement).on('select2:close', function (e) {
              this.isOpened = false;
            }.bind(this));
            
            $(document).off('keyup keydown', 'input.select2-search__field');               

            
            $(document).on('keyup keydown', 'input.select2-search__field', function (e) {  
              if (this.arrowKeys.includes(e.key)) {
                const elem = document.getElementsByClassName('select2-results__option--highlighted')[0];
                if (elem) {
                  elem.scrollIntoView();
                }
              }
            }.bind(this));

            self.loadComplete.emit();
          }
        })
      })
    })
  }

  addData(data, model?) {
    let hasSelection = false;
    $(this.el.nativeElement).find('option').remove();
    data.forEach(item => {
      const isSelected = model && model === item.id;
      hasSelection = hasSelection || isSelected;
      const newOption = new Option(item.text, item.id, isSelected, isSelected);
      $(this.el.nativeElement).append(newOption);
    });
    $(this.el.nativeElement).select2('close');
    $(this.el.nativeElement).select2('open');

    if (!hasSelection) {
      $(this.el.nativeElement).val(null).trigger('change');
    }
  }

  reLoadSelect2(data: any[], model: any = null) {
    const self = this;
    let wasOpened = false;
    if (this.isOpened) {
      wasOpened = true;
      $(this.el.nativeElement).select2('close');
    }
    $(self.el.nativeElement).empty();
    $(self.el.nativeElement).select2(
      {
        closeOnSelect: !this.isMultiple,
        placeholder: this.i18nService.getTranslation((data && data.length > 0 ? self.placeholder : self.emptyPlaceholder)),
        data: data,
        'language': this.noResultsFoundLanguage,
        escapeMarkup: function (markup) {
          return markup;
        }
      });

    $(self.el.nativeElement).val(null).trigger('change');
    const element = $(self.el.nativeElement);

    element.off('select2:select');
    element.on('select2:select', () => {
      let selectedValue = element.select2('val');
      const selectedData = element.select2('data');

      if (element.prop('multiple')) {
        const newV = [];
        selectedValue = selectedValue || [];
        for (const item of selectedValue) {
          newV.push(element.find('[value="' + item + '"]').attr('ng-reflect-value'));
        }
        selectedValue = newV;
        const searchBox = element.data('select2').dropdown.$search || element.data('select2').selection.$search;
        searchBox.val('');
        searchBox.trigger('keyup');
      }

      this.onChange.emit(selectedData);
    });

    if (this.isMultiple) {
      element.off('select2:unselect');
      element.on('select2:unselect', () => {
        const selectedData = element.select2('data');
        this.onChange.emit(selectedData);
      });
    }

    if (model) {
      this.selectItem(model);
    }
    this.reloadComplete.emit();
    if (this.isOpened || wasOpened) {
      this.isLoading = false;
      $(this.el.nativeElement).select2('open');
    }
  }

  disableSelect2() {
    const listElements = document.querySelectorAll('.select2-container');

    for (let index = 0; index < listElements.length; index++) {
      const el = listElements[index];
      el['style'].opacity = '0.5'
    }
  }

  enableSelect2() {
    const listElements = document.querySelectorAll('.select2-container');

    for (let index = 0; index < listElements.length; index++) {
      const el = listElements[index];
      el['style'].opacity = 'inherit'
    }
  }

  selectItem(val: any): void {
    $(this.el.nativeElement).val(val).trigger('change');
  }

  reloadSelect(data, model?) {
    this.reLoadSelect2(data, model);
  }
}
