import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'on-off-switch',
  templateUrl: './on-off-switch.component.html',
})
export class OnOffSwitchComponent implements OnInit {

  public widgetId;
  static widgetsCounter = 0

  @Input() name: string;
  @Input() title: string;
  @Input() model: boolean;
  @Input() value: any;
  @Input() disabled: boolean;
  @Input() isSmall = false;
  @Input() switchOnly = false;
  @Output() modelChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.value = this.model;
    this.widgetId = 'on-off-switch' + OnOffSwitchComponent.widgetsCounter++;
  }

  onChange() {
    this.modelChange.emit(this.value)
  }

}
