import { Injectable } from '@angular/core';
import { OrderCenterNotificationMessage } from 'app/domain/models/order-center-notification.model';
import { I18nService } from 'app/shared/i18n/i18n.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
declare var $: any;

@Injectable()
export class NotificationService {
   private defaultSettings = {
      timeOut: 2500,
      positionClass: 'toast-bottom-right',
      preventDuplicates: false,
      progressBar: true,
      tapToDismiss: true,
   };

   constructor(private i18n: I18nService, private toastr: ToastrService) {}

   confirmDiscardChanges(): Promise<boolean> {
      return this.showConfirmBox('IgnoreAndContinue', 'DataUnsavedWillBeLost', 'warning');
   }

   smartMessageBox(data, cb?) {
      $.SmartMessageBox(data, cb);
   }

   showErrorMessage(content?, title?, timeout?): number {
      this.setTimeOut(timeout);
      if (!content) {
         content = 'ErrorOcurred';
      }

      const settings = {
         timeOut: 0,
         positionClass: 'toast-bottom-right',
         preventDuplicates: true,
         progressBar: false,
         tapToDismiss: true,
      };

      const notification = this.toastr.error(this.i18n.getTranslation(content), title ? this.i18n.getTranslation(title) : null, settings);
      return notification ? notification.toastId : null;
   }

   showSuccessMessage(content?, title?, timeout?): number {
      this.setTimeOut(timeout);
      if (!content) {
         content = 'OperationSuccessful';
      }
      const notification = this.toastr.success(this.i18n.getTranslation(content), title, this.defaultSettings);
      return notification ? notification.toastId : null;
   }

   showInfoMessage(content, title?, timeout?): number {
      this.setTimeOut(timeout);
      const notification = this.toastr.info(this.i18n.getTranslation(content), title, this.defaultSettings);
      return notification ? notification.toastId : null;
   }

   showAlertMessage(content?, title?, timeout?): number {
      this.setTimeOut(timeout);
      if (!content) {
         content = 'Processing';
      }
      const notification = this.toastr.warning(this.i18n.getTranslation(content), title, this.defaultSettings);
      return notification ? notification.toastId : null;
   }

   showAlertMessageUploadImage(content?, title?, timeout?): number {
      this.setTimeOut(timeout);
      if (!content) {
         content = 'ProcessingImage';
      }
      const notification = this.toastr.warning(this.i18n.getTranslation(content), title, this.defaultSettings);
      return notification ? notification.toastId : null;
   }

   showAlertMessageDonwloadImage(content?, title?, timeout?): number {
      this.setTimeOut(timeout);
      if (!content) {
         content = 'DonwloadImage';
      }
      const notification = this.toastr.warning(this.i18n.getTranslation(content), title, this.defaultSettings);
      return notification ? notification.toastId : null;
   }

   clearNotification(notificationId: number) {
      this.toastr.clear(notificationId);
   }

   showAlertBox(content: string, title?: string): void {
      swal({
         type: 'info',
         title: title ? this.i18n.getTranslation(title) : null,
         text: this.i18n.getTranslation(content),
         cancelButtonClass: 'swal2-cancel-hiden',
      });
   }

   showAttentionBox(content: string, title?: string): void {
      swal({
         type: 'warning',
         title: title ? this.i18n.getTranslation(title) : null,
         text: this.i18n.getTranslation(content),
         cancelButtonClass: 'swal2-cancel-hiden',
      });
   }

   showExceptionErrorBox(err) {
      let message: string;
      if (err) {
         if (err.error) {
            if (err.error instanceof Array && err.error.length > 0) {
               message = err.error[0].Message;
            } else if (err.error.Message) {
               message = err.error.Message;
            }
         } else if (err.Message) {
            message = err.Message;
         }
      }

      this.showErrorBox(message);
   }

   showErrorBox(content?: string, title?: string, params?: string[]): void {
      if (!content) {
         content = 'ErrorOcurredDescription';
      }
      if (!title) {
         title = 'ErrorOcurred';
      }

      swal({
         type: 'error',
         title: this.i18n.getTranslation(title),
         text: this.i18n.getTranslation(content, params),
         cancelButtonClass: 'swal2-cancel-hiden',
      });
   }

   async showConfirmBox(
      title: string,
      text: string,
      typeAction,
      confirmText: string = 'Yes',
      cancelText: string = 'No',
      allowEscapeKey: boolean = true,
      allowOutsideClick: boolean = true
   ): Promise<boolean> {
      const result = await swal({
         title: this.i18n.getTranslation(title),
         text: this.i18n.getTranslation(text),
         type: typeAction,
         showCancelButton: true,
         confirmButtonColor: '#4267b2',
         cancelButtonColor: '#d33',
         reverseButtons: true,
         allowEscapeKey: allowEscapeKey,
         allowOutsideClick: allowOutsideClick,
         confirmButtonText: this.i18n.getTranslation(confirmText),
         cancelButtonText: this.i18n.getTranslation(cancelText),
      });

      if (result.value) {
         return true;
      } else {
         return false;
      }
   }

   async showConfirmBoxWithSelect(
      title: string,
      text: string,
      typeAction,
      placeHolder: string,
      errorMessage: string,
      inputOptions: any,
      confirmText: string = 'Yes',
      cancelText: string = 'No'
   ): Promise<any> {
      let result = await swal({
         title: this.i18n.getTranslation(title),
         text: this.i18n.getTranslation(text),
         input: 'select',
         inputOptions: inputOptions,
         inputPlaceholder: this.i18n.getTranslation(placeHolder),
         type: typeAction,
         showCancelButton: true,
         confirmButtonColor: '#4267b2',
         cancelButtonColor: '#d33',
         reverseButtons: true,
         confirmButtonText: this.i18n.getTranslation(confirmText),
         cancelButtonText: this.i18n.getTranslation(cancelText),
         inputValidator: (value) => {
            return new Promise((resolve) => {
               if (value) {
                  resolve(null);
               } else {
                  resolve(this.i18n.getTranslation(errorMessage));
               }
            });
         },
      });

      return result;
   }

   async showConfirmBoxWithCustomHtml(
      title: string,
      html: string,
      typeAction,
      confirmText: string = 'Yes',
      cancelText: string = 'No',
      onOpen?
   ): Promise<any> {
      const result = await swal({
         title: this.i18n.getTranslation(title),
         html: html,
         type: typeAction,
         showCancelButton: true,
         confirmButtonColor: '#4267b2',
         cancelButtonColor: '#d33',
         reverseButtons: true,
         confirmButtonText: this.i18n.getTranslation(confirmText),
         cancelButtonText: this.i18n.getTranslation(cancelText),
         onOpen: function (el) {
            if (typeof onOpen === 'function') {
               onOpen();
            }
         },
      });

      return result;
   }

   showQuestionBox(icon, title, message, action) {
      this.smartMessageBox(
         {
            title: "<i class='far " + icon + " '></i> " + this.i18n.getTranslation(title),
            content: this.i18n.getTranslation(message),
            buttons: '[' + this.i18n.getTranslation('No') + '][' + this.i18n.getTranslation('Yes') + ']',
         },
         (ButtonPressed) => {
            action(ButtonPressed === this.i18n.getTranslation('Yes'));
         }
      );
   }

   showOrderCenterMessage(notification: OrderCenterNotificationMessage): number {
      const settings = {
         timeOut: notification.isCritical ? 0 : 28000,
         positionClass: 'toast-bottom-right',
         preventDuplicates: false,
         progressBar: true,
         tapToDismiss: true,
      };

      let message: string;

      if (notification.quantity > 1) {
         message = this.i18n.getTranslation(notification.message + 'Plural');
         message = message.replace('##NUMBER##', notification.quantity.toString());
      } else {
         message = this.i18n.getTranslation(notification.message);
      }

      const notificationBox = this.toastr.info(message, this.i18n.getTranslation('OrderCenter'), settings);

      return notificationBox ? notificationBox.toastId : null;
   }

   private setTimeOut(timeout?) {
      if (timeout !== undefined && timeout != null) {
         this.defaultSettings.timeOut = timeout;
      } else {
         this.defaultSettings.timeOut = 2500;
      }
   }
}
