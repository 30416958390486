import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TrialService } from 'app/domain/services/trial.service';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { TrialTimeCompletedComponent } from './trial-completed/trial-time-completed.component';
import { TrialTimeComponent } from './trial-time.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule
  ],
  declarations: [
    TrialTimeComponent,
    TrialTimeCompletedComponent
  ],
  exports: [
    TrialTimeComponent,
  ],
  providers: [
    TrialService
  ]
})

export class TrialTimeModule { }