import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectionResolver } from 'app/api/signalr.connectionresolver';
import { AuthService } from 'app/auth/auth.service';
import { HirePlan } from 'app/domain/models/hire-plan.model';
import { QueryModelUpdated } from 'app/domain/models/query-model-updated.model';
import { Trial } from 'app/domain/models/trial.model';
import { CompanyPlanService } from 'app/domain/services/company-plan.service';
import { TrialService } from 'app/domain/services/trial.service';
import { FunctionService } from 'app/shared/utils/function.service';
import { NotificationService } from 'app/shared/utils/notification.service';
import { HirePlanLogoutComponent } from './hire-plan-logout/hire-plan-logout.component';

var creditCardType = require('credit-card-type');

@Component({
   // tslint:disable-next-line: component-selector
   selector: 'kcms-hire-plan',
   templateUrl: './hire-plan.component.html',
   styleUrls: ['./hire-plan.component.scss'],
})
export class HirePlanComponent implements OnInit {
   public resources = [];
   public planTrial = [];
   public namePlanHire: string;
   public isValidating = false;
   public currentTrialPlan: Trial;
   public pricePlanHire: string;
   public currentPlan: string;
   public hirePlan = new HirePlan();
   public phoneValidate = false;
   public maskNumberCard: string;
   public invalidCardFlag = false;
   public maxlength = 3;
   public invalidExpirationDate = false;
   public isSaving = false;

   cardsFlags = [
      { Name: 'amex' },
      { Name: 'diners' },
      { Name: 'elo' },
      { Name: 'hipercard' },
      { Name: 'mastercard' },
      { Name: 'visa-blue' },
   ];

   @ViewChild(HirePlanLogoutComponent, { static: true }) hirePlanLogoutComponent: HirePlanLogoutComponent;

   constructor(
      private functionService: FunctionService,
      private route: ActivatedRoute,
      private companyPlanService: CompanyPlanService,
      private trialService: TrialService,
      private notificationService: NotificationService,
      private authService: AuthService,
      private connectionResolver: ConnectionResolver
   ) {
      this.route.params.subscribe((params) => {
         this.getPlanToHire(params['id']);
         this.getCompanyToHire(params['companyId']);
         this.hirePlan.KcmsPlanId = params['id'];
         this.hirePlan.CompanyId = params['companyId'];
      });
   }

   ngOnInit() {
      this.maskNumberCard = '0000 0000 0000 0000';
   }

   ngDoCheck() {
      this.functionService.setPageTitle('PageHirePlan');
   }

   getPlanToHire(idPlan: string) {
      this.trialService.listPlans().subscribe((ret) => {
         if (ret) {
            this.planTrial = ret.filter((plan) => plan.Id === idPlan);

            this.planTrial.forEach((ret) => {
               this.namePlanHire = ret.Name.substr(4);
               this.resources = ret.Descriptions;
               this.pricePlanHire = ret.Price;
               this.hirePlan.IntegrationPlanId = ret.CodePlanHirePlan;
            });
         }
      });
   }

   getCompanyToHire(companyId: number) {
      this.trialService.getTrialByCompanyId(companyId).subscribe((ret) => {
         if (ret) {
            this.currentTrialPlan = ret;
            this.currentPlan = ret.OldPlanName.substr(4);
         }
      });
   }

   validationExpirationDate() {
      const regex = /^(0?[1-9]|1[0-2])\/(20[2-9][0-9])$/;

      if (regex.test(this.hirePlan.ExpirationDate)) {
         this.invalidExpirationDate = false;
      } else {
         this.invalidExpirationDate = true;
      }
   }

   clickGoBack() {
      window.history.back();
   }

   clickHirePlanToCompany() {
      let subscription = null;
      this.notificationService.showConfirmBox('HirePlanTitle', 'HirePlantMsg', 'warning').then(
         (ret) => {
            if (ret) {
               this.isSaving = true;
               this.notificationService.showAlertMessage();
               this.companyPlanService.hirePlan(this.hirePlan).subscribe(
                  (ret) => {},
                  (err) => {
                     if (subscription != null) {
                        subscription.unsubscribe();
                     }
                     this.functionService.generateErrorNotification(err);
                     this.isSaving = false;
                  }
               );
            }
         },
         (err) => {
            if (subscription != null) {
               subscription.unsubscribe();
            }
         }
      );

      subscription = this.connectionResolver.queryModelUpdated.subscribe((message: QueryModelUpdated) => {
         if (message.Message === this.companyPlanService.hirePlanMessage) {
            if (subscription != null) {
               subscription.unsubscribe();
            }
            this.isSaving = false;
            this.hirePlanLogoutComponent.openModal();
         }
      });
   }

   handleCreditCardType(numberCard) {
      creditCardType(numberCard).filter((card) => {
         if (card) {
            this.maxlength = 3;
            switch (card.type) {
               case 'american-express':
                  this.hirePlan.CardFlag = 'amex';
                  this.maskNumberCard = '0000 000000 00000';
                  this.invalidCardFlag = false;
                  this.maxlength = 4;
                  break;
               case 'diners-club':
                  this.hirePlan.CardFlag = 'diners';
                  this.maskNumberCard = '0000 000000 0000';
                  this.invalidCardFlag = false;
                  break;
               case 'hipercard':
                  this.hirePlan.CardFlag = 'hipercard';
                  this.maskNumberCard = '0000 0000 0000 0000';
                  this.invalidCardFlag = false;
                  break;
               case 'mastercard':
                  this.hirePlan.CardFlag = 'mastercard';
                  this.maskNumberCard = '0000 0000 0000 0000';
                  this.invalidCardFlag = false;
                  break;
               case 'visa':
                  this.hirePlan.CardFlag = 'visa';
                  this.maskNumberCard = '0000 0000 0000 0000';
                  this.invalidCardFlag = false;
                  break;
               case 'elo':
                  this.hirePlan.CardFlag = 'elo';
                  this.maskNumberCard = '0000 0000 0000 0000';
                  this.invalidCardFlag = false;
                  break;
               default:
                  this.invalidCardFlag = true;
                  this.hirePlan.CardFlag = null;
                  this.hirePlan.CardNumber = null;
                  this.maskNumberCard = '0000 00';
                  break;
            }
         }
      });
   }
}
