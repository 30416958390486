import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { Trial } from 'app/domain/models/trial.model';
import { TrialService } from 'app/domain/services/trial.service';
import { TrialTimeCompletedComponent } from './trial-completed/trial-time-completed.component';

@Component({
   selector: 'kcms-trial-time',
   templateUrl: './trial-time.component.html',
   styleUrls: ['./trial-time.component.scss'],
})
export class TrialTimeComponent implements OnInit {
   public trialPlan: Trial;
   public progress: string;
   public daysRemaining: number;
   public trialCurrentDate = new Date();

   @ViewChild(TrialTimeCompletedComponent, { static: true }) trialTimeCompletedComponent: TrialTimeCompletedComponent;

   constructor(private router: Router, private trialService: TrialService, private authService: AuthService) {}

   ngOnInit() {
      setTimeout(() => {
         this.getTrial();
      }, 500);
   }

   getTrial() {
      const companyId = Number(localStorage.getItem('id'));

      if (companyId > 0) {
         this.trialService.getTrialByCompanyId(companyId).subscribe((ret) => {
            if (ret) {
               this.trialPlan = ret;

               if (!this.trialPlan.IsUserAwareEnded) {
                  this.onCheckNumberOfDays();

                  const user = this.authService.getUserInfo();
                  const isKcmsUser = user.Email.includes('@kcms.com.br');

                  if (!this.trialPlan.IsActive && !isKcmsUser) {
                     this.trialTimeCompletedComponent.trialPlan = this.trialPlan;
                     this.trialTimeCompletedComponent.openModal();
                  }
               }
            }
         });
      }
   }

   onCheckNumberOfDays() {
      const endDate = new Date(this.trialPlan.EndDate);

      this.daysRemaining = this.trialService.getTrialRemainingNumberOfDays(endDate);
      this.progress = String((10 - this.daysRemaining) * 10);
   }

   onHirePlan() {
      const companyId = Number(localStorage.getItem('id'));

      if (companyId > 0) {
         this.router.navigate([`/contratarplano/${companyId}/${this.trialPlan.NewPlanId}`]);
      }
   }

   handleOpenWhatsApp() {
      const url =
         'https://api.whatsapp.com/send?phone=551534120001&text=Ol%C3%A1,%20estou%20testando%20o%20sistema%20KCMS%20e%20surgiram%20algumas%20d%C3%BAvidas.%20Ser%C3%A1%20que%20voc%C3%AA%20poderia%20me%20ajudar?';
      window.open(url, '_blank');
   }
}
