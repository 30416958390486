import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/observable';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (component) {
      return component && component.canDeactivate ? component.canDeactivate() : true;
    } else {
      sessionStorage.removeItem('sSearch_CustomFilters');
      sessionStorage.removeItem('sSearch_PageIndex');
      sessionStorage.removeItem('sSearch_PageLength');
      for (let i = 0; i < 10; i++) {
        sessionStorage.removeItem('sSearch_' + i);
      }
      return true;
    }
  }
}
