import { PipeTransform, Pipe } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { config } from 'app/shared/smartadmin.config';

const PADDING = '000000';

@Pipe({ name: 'CurrencyPipe' })
export class CurrencyPipe implements PipeTransform {
    point: string;

    constructor(
        private decimalPipe: DecimalPipe
    ) {
        this.decimalPipe = new DecimalPipe(config.defaultLocale);

        var value = this.formatDecimal('0', '1.2');
        this.point = this.getDecimalPoint(value);
    }

    transform(value: any, args: string[]): any {
        var clean = value.replace(/[^-0-9\.]/g, '');
        var negativeCheck = clean.split('-');
        var decimalCheck = clean.split('.');

        if (negativeCheck[1] != undefined) {
            negativeCheck[1] = negativeCheck[1].slice(0, negativeCheck[1].length);
            clean = negativeCheck[0] + '-' + negativeCheck[1];
            if (negativeCheck[0].length > 0) {
                clean = negativeCheck[0];
            }

        }
        if (decimalCheck[1] != undefined) {
            decimalCheck[1] = decimalCheck[1].slice(0, 2);
            clean = decimalCheck[0] + '.' + decimalCheck[1];
        }

        return clean;
    }

    formatDecimal(value, format) {
        return this.decimalPipe.transform(value.replace(',', '.'), format);
    }

    getDecimalPoint(value) {
        let ret = this.reverse(value);

        for (let index = 0; index < ret.length; index++) {
            const element = ret[index];
            let isInteger = Number.isInteger(element);
            var isnum = /^\d+$/.test(element);

            if (isnum == false) {
                return element;
            }
        }

        return '.'; //default
    }

    reverse(s) {
        return s.split('').reverse().join('');
    }

    parse(value: string, fractionSize: number = 2): string {

        let reg = new RegExp('[^-0-9' + this.point + ']', 'g');
        var clean = value.replace(reg, '');
        //var clean = value.replace(/[^-0-9\.]/g, '');

        var negativeCheck = clean.split('-');
        var decimalCheck = clean.split(this.point);

        if (negativeCheck[1] != undefined) {
            negativeCheck[1] = negativeCheck[1].slice(0, negativeCheck[1].length);
            clean = negativeCheck[0] + '-' + negativeCheck[1];
            if (negativeCheck[0].length > 0) {
                clean = negativeCheck[0];
            }

        }
        if (decimalCheck[1] != undefined) {
            decimalCheck[1] = decimalCheck[1].slice(0, 2);
            clean = decimalCheck[0] + this.point + decimalCheck[1];
        }

        return clean;
    }

}