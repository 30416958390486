import { Routes, RouterModule } from '@angular/router';
import { HirePlanComponent } from './hire-plan.component';

export const hirePlanRoutes: Routes = [
    {
      path: 'contratarplano/:companyId/:id',
      component: HirePlanComponent,
      data: { roles: [] }
    }, 
  ];

export const hirePlanRoute = RouterModule.forChild(hirePlanRoutes);