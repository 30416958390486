import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Select2Module, ComboboxComponent } from './index'
import { ComboboxService } from 'app/domain/services/combobox.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        Select2Module
    ],
    declarations: [
        ComboboxComponent
    ],
    exports: [
        ComboboxComponent
    ],
    providers: [
        ComboboxService
    ]
})

export class ComboboxModule { }
