import { PlatformLocation } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, Event as RouterEvent } from '@angular/router';
import { ConnectionResolver } from 'app/api/signalr.connectionresolver';
import { AccessType, AuthService } from 'app/auth/auth.service';
import { Company, CompanyBusinessHoursQueryModel, CompanyBusinessHoursSet, CompanyConfig } from 'app/domain/models/company.model';
import { QueryModelUpdated } from 'app/domain/models/query-model-updated.model';
import { SalesChannel } from 'app/domain/models/sales-channel.model';
import { CompanyService } from 'app/domain/services/company.service';
import { IfoodService } from 'app/domain/services/ifood.service';
import { SalesChannelService } from 'app/domain/services/sales-channel.service';
import { I18nService } from 'app/shared/i18n';
import { IfoodMenuHowToModalComponent } from 'app/shared/kcms/ifood-menu-how-to-modal/ifood-menu-how-to-modal.component';
import { FunctionService } from 'app/shared/utils/function.service';
import { NotificationService } from 'app/shared/utils/notification.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { LayoutService } from '../layout.service';
declare var mixpanel: any;

@Component({
   selector: 'app-main-layout',
   templateUrl: './main-layout.component.html',
   styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
   public salesChannelList: SalesChannel[];
   public isSavingConfig = false;
   public salesChannelName = false;
   public loading = true;
   public creatingAuth = true;
   public companyId: number;
   public authorizationTokenObservable: Subscription;
   public modalRef: BsModalRef;
   public iFoodModalRef: BsModalRef;
   public isModalOpened = false;
   public isCloseModalByBrowser: string;
   public configWizard: CompanyConfig;
   public companyLogoFile: string;
   public businessTypeName: string;
   public stepNumber = 1;
   public isMobile: boolean;
   public unsubscriber: Subject<void> = new Subject<void>();
   public companyBusinessHours = new CompanyBusinessHoursQueryModel();
   public company: Company;
   public canReadCompanyBaseData = false;
   public canUpdateCompanyBaseData = false;
   public textSaving = 'Wait';

   public listBusinessTypeName = [
      { Name: 'açougue' },
      { Name: 'mercado' },
      { Name: 'conveniência' },
      { Name: 'varejo' },
      { Name: 'Agropecuário' },
      { Name: 'Mercearia' },
      { Name: 'Supermercado' },
      { Name: 'Quitanda' },
      { Name: 'Ótica' },
      { Name: 'ONG' },
      { Name: 'Papelaria' },
   ];

   @ViewChild('setupModal', { static: true }) setupModal: TemplateRef<any>;
   @ViewChild(IfoodMenuHowToModalComponent) ifoodMenuHowToModalComponent: IfoodMenuHowToModalComponent;

   @HostListener('window:popstate', ['$event'])
   onPopState(event: Event) {
      this.closeModalByBrowser();
   }

   constructor(
      private router: Router,
      private authService: AuthService,
      private companyService: CompanyService,
      private modalService: BsModalService,
      private notificationService: NotificationService,
      private functionService: FunctionService,
      private connectionResolver: ConnectionResolver,
      private layoutService: LayoutService,
      private salesChannelService: SalesChannelService,
      public location: PlatformLocation,
      private i18nService: I18nService,
      private ifoodService: IfoodService
   ) {
      router.events.subscribe((event: RouterEvent) => {
         this.navigationInterceptor(event);
      });
   }

   ngOnInit() {
      this.canReadCompanyBaseData = this.authService.hasPermission('CompanyBaseData', AccessType.Read);
      this.canUpdateCompanyBaseData = this.authService.hasPermission('CompanyBaseData', AccessType.Update);

      this.companyId = Number(localStorage.getItem('id'));

      this.layoutService.isMobile.subscribe((ret) => (this.isMobile = ret));

      this.authorizationTokenObservable = this.authService.authorizationTokenObservable.subscribe((val) => {
         if (val) {
            setTimeout(() => {
               this.creatingAuth = false;

               if (this.canReadCompanyBaseData && this.canUpdateCompanyBaseData) {
                  this.checkCompanySetup();
               }

               this.connectionResolver.resolve();
            }, 100);
         } else {
            this.creatingAuth = true;
         }
      });
   }

   ngOnDestroy() {
      this.authorizationTokenObservable?.unsubscribe();
   }

   checkCompanySetup() {
      const companiesNeedsSetup = this.authService.getCompaniesNeedsSetup();
      if (companiesNeedsSetup.length > 0) {
         this.setCompanySetup(companiesNeedsSetup[0]);
         this.salesChannelService.getAll().subscribe((ret) => {
            this.salesChannelList = ret;
         });
      }
      if (this.isModalOpened) {
         this.closeModal(false);
      }
   }

   setCompanySetup(companyId: number, reload: boolean = false) {
      let isCloseModalByBrowser = localStorage.getItem('closeModalByBrowser');
      this.companyId = companyId;

      this.companyService.get(String(this.companyId)).subscribe((ret) => {
         this.company = ret;
         this.businessTypeName = ret.BusinessLineName.toLowerCase();
      });

      this.companyService.getConfiguration(this.companyId).subscribe((ret) => {
         if (!ret.HasSetupCompleted && isCloseModalByBrowser === 'true') {
            localStorage.removeItem('closeModalByBrowser');

            this.checkCompanySetup();
            return;
         }

         if (ret.HasSetupCompleted || isCloseModalByBrowser === 'true') {
            this.authService.setCompanySetupCompleted(this.companyId);
            this.checkCompanySetup();
         } else {
            this.configWizard = ret;
            this.configWizard.SalesChannelIds = [];
            this.stepNumber = 1;

            history.pushState(null, '', '/dashboard?assistente=true');

            try {
               mixpanel.track('Setup Wizard Started', {
                  CompanyId: companyId,
               });
            } catch (err) {}

            if (!this.isModalOpened || isCloseModalByBrowser !== 'true') {
               const config = new ModalOptions();
               config.ignoreBackdropClick = true;
               config.keyboard = false;
               config.class = 'modal-lg';
               this.modalRef = this.modalService.show(this.setupModal, config);
               this.isModalOpened = true;
            }
         }
      });
   }

   nextStep() {
      if (this.stepNumber === 1) {
         this.stepNumber = 2;
      } else if (this.stepNumber === 2 && this.configWizard.IfoodMenuUrl) {
         try {
            mixpanel.track('iFood URL added', {
               email: localStorage.getItem('login_email'),
            });
         } catch (err) {}

         this.saveConfig();
      } else if (this.stepNumber === 2 && !this.configWizard.IfoodMenuUrl) {
         this.saveConfig();
      } else if (this.stepNumber === 3) {
         this.checkCompanySetup();
      } else {
         this.stepNumber++;
      }
   }

   previousStep() {
      if (this.stepNumber === 2) {
         this.stepNumber = 1;
      } else {
         this.stepNumber--;
      }
   }

   setTextIsLoading(i: number, hasIfoodMenuUrl: boolean) {
      let texts = [];

      if (hasIfoodMenuUrl) {
         texts = [
            { Text: 'Wait' },
            { Text: 'ConfigWizardSetupSavingImportIfoodMenu' },
            { Text: 'ConfigWizardSetupSaving' },
            { Text: 'PreparingTheCompany' },
            { Text: 'WeArePreparingTheSystem' },
            { Text: 'Wait' },
         ];
      } else {
         texts = [
            { Text: 'Wait' },
            { Text: 'ConfigWizardSetupSaving' },
            { Text: 'PreparingTheCompany' },
            { Text: 'WeArePreparingTheSystem' },
            { Text: 'Wait' },
         ];
      }

      this.textSaving = texts[i].Text;

      setTimeout(() => {
         if (this.isModalOpened) {
            let newIndex = null;
            if (i + 1 === texts.length) {
               newIndex = 0;
            } else {
               newIndex = i + 1;
            }
            this.setTextIsLoading(newIndex, hasIfoodMenuUrl);
         }
      }, 2000);
   }

   saveConfig(closeModalByBrowser: boolean = false) {
      this.isSavingConfig = true;
      let subscription = null;
      this.configWizard.IsUsingDeliveryService = false;
      this.configWizard.HasSetupCompleted = true;

      this.setTextIsLoading(0, this.configWizard.IfoodMenuUrl ? true : false);

      this.configWizard.FriendlyUrl = this.functionService.slugify(this.configWizard.CompanyName);
      this.configWizard.ImportIfoodMenu = this.configWizard.IfoodMenuUrl ? true : false;

      this.companyService.configure(this.companyId, this.configWizard).subscribe(
         (ret) => {
            const request = new CompanyBusinessHoursSet();
            request.Id = this.company.Id;
            request.CompanyId = this.company.SimplifiedId;
            request.WeekBusinessHours = this.company.WeekBusinessHours;

            this.companyService.setBusinessHours(request).subscribe(
               (ret) => {},
               (err) => {
                  if (subscription != null) {
                     subscription.unsubscribe();
                  }
                  this.functionService.generateErrorNotification(err);
               }
            );

            if (!this.configWizard.ImportIfoodMenu) {
               this.completeSaveConfig(closeModalByBrowser);
            }
         },
         (err) => {
            if (subscription != null) {
               subscription.unsubscribe();
            }
            this.functionService.generateErrorNotification(err);
            this.isSavingConfig = false;
         }
      );

      if (this.configWizard.IfoodMenuUrl) {
         localStorage.setItem('iFoodImported', 'true');

         try {
            mixpanel.track('iFood Assistant Initial Imported', {
               Email: localStorage.getItem('login_email'),
            });
         } catch (err) {}
      }

      if (this.configWizard.ImportIfoodMenu) {
         subscription = this.connectionResolver.queryModelUpdated.subscribe((message: QueryModelUpdated) => {
            if (message.Message === this.companyService.configuredMessage) {
               this.completeSaveConfig(closeModalByBrowser);
               subscription.unsubscribe();
            }
         });
      }
   }

   completeSaveConfig(closeModalByBrowser: boolean = false) {
      try {
         let mixPanelMessage = 'Setup Wizard Completed';
         mixpanel.track(mixPanelMessage, {
            CompanyId: this.companyId,
         });
      } catch (err) {}

      this.authService.setCompanySetupCompleted(this.companyId);

      setTimeout(() => {
         this.stepNumber = 3;
         this.isSavingConfig = false;
      }, 3000);
   }

   closeModal(redirect: boolean) {
      if (this.modalRef) {
         this.modalRef.hide();
      }

      this.isModalOpened = false;

      if (redirect) {
         let businessType = this.listBusinessTypeName.filter((n) => n.Name.toLowerCase() === this.businessTypeName);

         if (businessType && businessType.length > 0) {
            this.router.navigate(['/materiais/produto']);
         } else {
            this.router.navigate(['/menu']);
         }
      } else {
         this.router.navigate(['/menu']);
      }
   }

   openImportIfoodMenuHowTo() {
      this.ifoodMenuHowToModalComponent.openIfoodMenuHowToModal();
   }

   updateConfigWizardSalesChannel() {
      this.configWizard.SalesChannelIds = this.salesChannelList.filter((n) => n.IsChecked).map((n) => n.Id);
   }

   navigationInterceptor(event: RouterEvent): void {
      if (event instanceof NavigationStart) {
         this.loading = true;
      }
      if (event instanceof NavigationEnd) {
         this.loading = false;
      }
      if (event instanceof NavigationCancel) {
         this.loading = false;
      }
      if (event instanceof NavigationError) {
         this.loading = false;
      }
   }

   setBusinessHours(weekBusinessHours) {
      this.company.WeekBusinessHours = weekBusinessHours;
   }

   closeModalByBrowser() {
      if (this.isModalOpened) {
         localStorage.setItem('closeModalByBrowser', 'true');

         this.notificationService.showAlertBox('CloseModalByBrowserMessage', 'Attention');

         localStorage.removeItem('closeModalByBrowser');
         this.setCompanySetup(this.companyId, true);
      }
   }
}
