import { Component } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import * as moment from 'moment';
import { ApplicationInsightsService } from './domain/services/application-insights.service';
import { config } from './shared/smartadmin.config';
declare var $: any;

@Component({
   selector: 'app-root',
   template: '<router-outlet></router-outlet>',
})
export class AppComponent {
   public constructor(public auth: AuthService, private applicationInsightsService: ApplicationInsightsService) {
      auth.scheduleRenewal();
      moment.locale(config.defaultLocale);
      this.removeAutoComplete();
      this.configureNotificationDropdownButton();
   }

   removeAutoComplete() {
      $(document).on('focus', ':input', function () {
         $(this).attr('autocomplete', 'off');
      });
   }

   configureNotificationDropdownButton() {
      $('body').on('click', function (e) {
         if (
            !$('.dropdown-menu-order-center').is(e.target) &&
            !$('.btn-dropdown').is(e.target) &&
            $('.dropdown-menu-order-center').has(e.target).length === 0 &&
            $('.btn-dropdown').has(e.target).length === 0
         ) {
            $('.dropdown-menu-order-center').hide();
         }
      });
   }
}
