import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { KcmsAddressComponent } from './address.component';
import { DebounceTimeModule } from 'app/shared/forms/debounce-time/debounce-time.module';
import { NgxMaskModule } from 'ngx-mask';
import { ComboboxModule } from '../combobox/combobox.module';
import { StateService } from 'app/domain/services/state.service';
import { LowercaseInputDirective } from 'app/shared/utils/lowercase-input.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    GooglePlaceModule,
    DebounceTimeModule,
    ComboboxModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    KcmsAddressComponent,
    LowercaseInputDirective
  ],
  exports: [
    KcmsAddressComponent,
  ],
  providers: [
    StateService
  ]
})
export class KcmsAddressModule { }
