import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'app/api/api.service';
import { IfoodMenuImport } from '../models/ifood-menu-import.model';

@Injectable()
export class IfoodService {

  domainName = 'Ifood';
  IfoodMenuImported = 'MenuImported';

  isImportedMenuIfood = new EventEmitter();

  constructor(
    private apiService: ApiService
  ) { }

  importMenu(obj: IfoodMenuImport): Observable<any> {
    if (obj.MerchantUrl) {
      return this.apiService.put(this.domainName, obj, this.IfoodMenuImported);
    }
  }
}