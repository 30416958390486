import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'app/api/api.service';
import { CompanyLocationType } from 'app/domain/models/company-location-type.model';

@Injectable()
export class CompanyLocationTypeService {

  domainName = 'CompanyLocationType';
  addedMessage = 'CompanyLocationTypeAdded';
  updatedMessage = 'CompanyLocationTypeUpdated';

  constructor(private apiService: ApiService) { }

  get(id: string): Observable<CompanyLocationType> {
    return this.apiService.get(this.domainName, id);
  }

  getAll(): Observable<CompanyLocationType[]> {
    return this.apiService.getCustom(this.domainName + '/select');
  }

  save(obj: CompanyLocationType): Observable<any> {
    if (obj.Id && obj.Id.length > 0) {
      return this.apiService.post(this.domainName, obj, this.updatedMessage);
    } else {
      return this.apiService.put(this.domainName, obj, this.addedMessage);
    }
  }
}
