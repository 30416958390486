import { Injectable } from '@angular/core';
import { ApiService } from 'app/api/api.service';
import { CryptoService } from 'app/shared/utils/crypto.service';
import { Observable } from 'rxjs';
import { DeliveryServiceConfig, DeliveryServiceConfigDeliveryFeesSet } from '../models/delivery-service-config.model';
import { DiskDelivery } from '../models/disk-delivery.model';
import { Facebook } from '../models/facebook.model';
import { GoogleAnalystics } from '../models/google-analystics.model';

@Injectable()
export class DeliveryServiceConfigService {
   domainName = 'DeliveryServiceConfig';
   updatedMessage = 'DeliveryServiceConfigUpdated';
   updatedAppearanceMessage = 'DeliveryServiceConfigUpdatedAppearance';
   updatedFacebookMessage = 'DeliveryServiceConfigUpdatedFacebookPixel';
   updatedGoogleAnalysticsMessage = 'DeliveryServiceConfigUpdatedGoogleAnalystics';
   logoRemovedMessage = 'DeliveryServiceConfigLogoRemovedMessage';
   paymentFormsSetMessage = 'DeliveryServiceConfigPaymentFormsSet';
   integrationFormsSetMessage = 'DeliveryServiceConfigIntegrationFormSet';
   deliveryOptionsSetMessage = 'DeliveryServiceConfigDeliveryOptionsSet';
   deliveryFeesSetMessage = 'DeliveryServiceConfigDeliveryFeesSet';
   deliveryOrderMinValueForFreeDeliveryFeeMessage = 'DeliveryOrderMinValueForFreeDeliveryFee';
   updatedDiskDeliveryMessage = 'OrderCenterConfigUpdatedDiskDelivery';
   updatedPicPayMessage = 'DeliveryServiceConfigUpdatedPicPay';

   constructor(private apiService: ApiService, private cryptoService: CryptoService) {}

   get(id: string): Observable<DeliveryServiceConfig> {
      return this.apiService.get(this.domainName, id);
   }

   getByCompanyId(companyId: number): Observable<DeliveryServiceConfig> {
      return this.apiService.get(this.domainName + '/company', companyId);
   }

   save(obj: DeliveryServiceConfig): Observable<any> {
      return this.apiService.post(this.domainName, obj, this.updatedMessage);
   }

   savePaymentForms(obj: DeliveryServiceConfig): Observable<any> {
      const request = {
         Id: obj.Id,
         IsPaymentFormMoneyEnabled: obj.IsPaymentFormMoneyEnabled,
         IsPaymentFormCreditEnabled: obj.IsPaymentFormCreditEnabled,
         IsPaymentFormDebitEnabled: obj.IsPaymentFormDebitEnabled,
         IsPaymentFormVoucherEnabled: obj.IsPaymentFormVoucherEnabled,
         IsPaymentFormPicPayEnabled: obj.IsPaymentFormPicPayEnabled,
         IsPaymentFormPixEnabled: obj.IsPaymentFormPixEnabled,
         PicPayToken: null,
         PicPaySellerToken: null,
         PixKey: obj.PixKey,
         PixKeyTypeId: obj.PixKeyTypeId,
      };
      if (obj.PicPayToken) {
         request.PicPayToken = this.cryptoService.encryptToAes(obj.PicPayToken);
      }
      if (obj.PicPaySellerToken) {
         request.PicPaySellerToken = this.cryptoService.encryptToAes(obj.PicPaySellerToken);
      }

      return this.apiService.post(this.domainName + '/paymentforms', request, this.paymentFormsSetMessage);
   }

   saveIntegrationForm(obj: DeliveryServiceConfig): Observable<any> {
      return this.apiService.post(this.domainName, obj, this.integrationFormsSetMessage);
   }

   saveDeliveryOptions(obj: DeliveryServiceConfig): Observable<any> {
      return this.apiService.post(this.domainName + '/deliveryoptions', obj, this.deliveryOptionsSetMessage);
   }

   saveDeliveryFees(obj: DeliveryServiceConfigDeliveryFeesSet): Observable<any> {
      return this.apiService.post(this.domainName + '/deliveryfee', obj, this.deliveryFeesSetMessage);
   }

   saveOrderMinValueForFreeDeliveryFee(id: string, value?: number): Observable<any> {
      const patchUrl = `${this.domainName}/${id}/setOrderMinValueForFreeDeliveryFee`;
      return this.apiService.patch(patchUrl, value, this.deliveryOrderMinValueForFreeDeliveryFeeMessage);
   }

   deleteDeliveryServiceLogo(deliveryServiceConfigId: string) {
      return this.apiService.delete(this.domainName + '/logo', deliveryServiceConfigId, this.logoRemovedMessage);
   }

   getFacebookByCompanyId(companyId: number): Observable<Facebook> {
      return this.apiService.get(this.domainName + '/facebookparams', companyId);
   }

   saveFacebookParams(companyId: number, obj: Facebook): Observable<string> {
      return this.apiService.post(
         this.domainName + '/' + companyId + '/facebookparams',
         obj,
         this.updatedFacebookMessage
      );
   }

   getGoogleAnalysticsByCompanyId(companyId: number): Observable<GoogleAnalystics> {
      return this.apiService.get(this.domainName + '/googleparams', companyId);
   }

   saveGoogleAnalysticsParams(companyId: number, obj: GoogleAnalystics): Observable<string> {
      return this.apiService.post(
         this.domainName + '/' + companyId + '/googleparams',
         obj,
         this.updatedGoogleAnalysticsMessage
      );
   }

   saveDiskDeliveryParams(companyId: number, obj: DiskDelivery): Observable<string> {
      return this.apiService.post(
         this.domainName + '/' + companyId + '/diskdeliveryparams',
         obj,
         this.updatedDiskDeliveryMessage
      );
   }
}
