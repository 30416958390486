import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebounceTimeDirective } from './debounce-time.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DebounceTimeDirective,
  ],
  exports: [
    DebounceTimeDirective
  ]
})
export class DebounceTimeModule { }
