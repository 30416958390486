import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'app/api/api.service';
import { Plan } from 'app/domain/models/plan.model';
import { I18nService } from 'app/shared/i18n';
declare var $: any;

@Injectable()
export class PlanService {  

  domainName = 'Plan';
  addedMessage = 'PlanAdded';
  updatedMessage = 'PlanUpdated';
  permissionUpdatedMessage = 'PlanPermissionUpdated';

  constructor(
    private apiService: ApiService, 
    private i18nService: I18nService,
  ) { }

  get(id: string): Observable<Plan> {
    return this.apiService.get(this.domainName, id);
  }

  getAll(): Observable<Plan[]> {
    return this.apiService.get('Trial/Plans')
  } 

  save(obj: Plan): Observable<any> {
    if (obj.DescriptionsCrud) {
      obj.Descriptions = [];
      obj.DescriptionsCrud.forEach(description => {
        obj.Descriptions.push(description.Description);
      });
    }
    if (obj.Id && obj.Id.length > 0) {
      return this.apiService.post(this.domainName, obj, this.updatedMessage);
    } else {
      return this.apiService.put(this.domainName, obj, this.addedMessage);
    }
  }

  setPermissions(obj: Plan): Observable<any> {
    return this.apiService.post(this.domainName + '/permissions', obj, this.permissionUpdatedMessage)
  }

  getCheckedPermissions(): string[] {
    const checkboxes = $('.group-tree .dd-item .group-permission-item');
    const permissions: string[] = [];
    checkboxes.each(function (i) {
      const isChecked = $(this).is(':checked');
      if (isChecked) {
        const splittedId = $(this).attr('id').split('_');
        const permission = splittedId[1];
        permissions.push(permission);
      }
    })
    return permissions;
  }

  getPermissionChanged(planPermissions: string[]): boolean {
    const checkedPermissions = this.getCheckedPermissions();
    if (planPermissions.length !== checkedPermissions.length) {
      return true;
    }
    for (let i = 0; i < planPermissions.length; i++) {
      if (checkedPermissions.indexOf(planPermissions[i]) < 0) {
        return true;
      }
    }
    return false;
  }

  buildPermissionTree(navigationTree: any[], permissionTree: any[], permissions: string[], canEdit: boolean) {
    navigationTree.forEach(item => {
      const domain = item.Permission ? item.Permission : item.Domain;

      const permissionitem = {
        'id': domain,
        'parentId': '',
        'content': '<table class="dataTable responsive table table-striped table-bordered no-footer" width="100%"' +
          'role="grid" aria-describedby="DataTables_Table_0_info">' +
          '<tbody>' +
          '<tr role="row">' +
          '<td style="padding-left: 40px;">' + this.i18nService.getTranslation(item.Name) + '</td>' +
          '<td class="center dt-center" style="width:98px;">' +
          this.buildPermissionCheckbox(domain, permissions, canEdit) + '</td>' +
          '</tr>' +
          '</tbody>' +
          '</table>',
        'children': null,
        'value': domain
      };

      if (item.Items && item.Items.length > 0) {
        permissionitem.children = [];
        this.buildPermissionTree(item.Items, permissionitem.children, permissions, canEdit);
      }
      permissionTree.push(permissionitem);
    });
  }

  private buildPermissionCheckbox(domain: string, permissions: string[], canEdit: boolean) {
    let ret = '';
    if (domain) {
      const isChecked = permissions && permissions.includes(domain);
      ret = '<label class="checkbox' + (canEdit ? '' : ' state-disabled') + '">' +
        '<input type="checkbox" ' + 'class="group-permission-item" ' + 'id="chkGrp_' + domain + '" ' +
        (canEdit ? '' : 'disabled') + ' ' + (isChecked ? 'checked' : '') + '><i></i></label>';
    }
    return ret;
  }
}
