import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PersonAddressWebService } from 'app/domain/models/person.model';
import { validateBr } from 'js-brasil';
import * as moment from 'moment';
import * as pako from 'pako';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { I18nService } from '../i18n';
import { NotificationService } from './notification.service';
declare var $: any;

@Injectable()
export class FunctionService {
   constructor(
      private http: HttpClient,
      private notificationService: NotificationService,
      private i18nService: I18nService,
      private title: Title
   ) {}

   getRandomNumber(min: number = 11111111, max: number = 99999999) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
   }

   guid() {
      function s4() {
         return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
   }

   clone(obj) {
      let copy;

      // Handle the 3 simple types, and null or undefined
      if (null == obj || 'object' !== typeof obj) {
         return obj;
      }

      // Handle Date
      if (obj instanceof Date) {
         copy = new Date();
         copy.setTime(obj.getTime());
         return copy;
      }

      // Handle Array
      if (obj instanceof Array) {
         copy = [];
         for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.clone(obj[i]);
         }
         return copy;
      }

      // Handle Object
      if (obj instanceof Object) {
         copy = {};
         for (const attr in obj) {
            if (obj.hasOwnProperty(attr)) {
               copy[attr] = this.clone(obj[attr]);
            }
         }
         return copy;
      }

      throw new Error("Unable to copy obj! Its type isn't supported.");
   }

   encodeToken(token: any, header?: string): any {
      const encodedToken = btoa(JSON.stringify(token));
      const data = encodedToken.replace('+', '-').replace('/', '_');

      return (header ? header + '.' : '') + data;
   }

   decodeToken(encodedToken: string): any {
      if (encodedToken) {
         const base64Url = encodedToken.split('.')[1];
         const base64 = base64Url.replace('-', '+').replace('_', '/');

         return JSON.parse(atob(base64));
      }

      return null;
   }

   encodeString(object: any): any {
      const encodedString = pako.deflate(object, { gzip: true, to: 'string' });
      const data = btoa(encodedString);

      return data;
   }

   decodeString(encodedString: string): string {
      const strData = atob(encodedString);
      const data = pako.inflate(strData, { gzip: true, to: 'string' });

      return data;
   }

   isNumber(event): boolean {
      const value = event.key;
      // event.preventDefault();
      const reg = new RegExp(/^[0-9]+$/i);

      // Special keys, TAB, Backspace ... etc
      if (event.keyCode === 8 || event.keyCode === 7 || event.keyCode === 9 || event.keyCode === 13) {
         // validate before call add api.
         return true;
      } else if (!reg.test(value)) {
         return false;
      } else {
         return true;
      }
   }

   toType(obj) {
      return {}.toString
         .call(obj)
         .match(/\s([a-zA-Z]+)/)[1]
         .toLowerCase();
   }

   setDefaultValue(type, value): string {
      switch (type) {
         case 'boolean':
            value = value === false ? '' : value;
            break;
         case 'number':
            value = value === 0 ? '' : value;
            break;
         case 'null':
         case 'undefined':
            value = '';
            break;
         default:
            value = value.toString().trim();
            break;
      }

      return value.toString();
   }

   areEqual(a, b) {
      const type1 = this.toType(a);
      const type2 = this.toType(b);
      const val1 = this.setDefaultValue(type1, a);
      const val2 = this.setDefaultValue(type2, b);
      return val1 === val2;
   }

   isGuid(guid: string): boolean {
      const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
      return regexGuid.test(guid);
   }

   addValidation(validationName: string, validationFunction, message: string = null): void {
      $.validator.addMethod(validationName, validationFunction, function () {
         return message || 'The field cannot be less than than';
      });
   }

   getAddress(zipCode: string): Observable<PersonAddressWebService> {
      const zipCodeOnlyNumbers = zipCode.replace(/^\D+/g, '');
      let retAddress: any;
      return this.http.get<any>('https://viacep.com.br/ws/' + zipCodeOnlyNumbers + '/json/').pipe(
         map((response) => {
            const res = response;
            retAddress = new PersonAddressWebService();
            retAddress.ZipCode = res.cep;
            retAddress.AddressLine1 = res.logradouro;
            retAddress.AddressLine2 = res.complemento;
            retAddress.Neighborhood = res.bairro;
            retAddress.CityName = res.localidade;
            retAddress.StateAcronym = res.uf;
            retAddress.IbgeCode = res.ibge;
            retAddress.GiaCode = res.gia;
            retAddress.CountryAcronym = 'BR';
            retAddress.CountryName = 'Brasil';
            return retAddress;
         }),
         catchError((error) => {
            retAddress = new PersonAddressWebService();
            return observableThrowError(retAddress);
         })
      );
   }

   getIp(): Observable<any> {
      return this.http.get<any>('http://meuip.com/api/meuip.php');
   }

   configureMultipleModals() {
      setTimeout(() => {
         const modals = $('.modal.fade.in');

         let zindex = 1039 - modals.length;

         modals.each(function (index) {
            if (index + 1 < modals.length) {
               $(this).css('z-index', zindex++);
            } else {
               $(this).css('z-index', 1050);
            }
         });
      }, 200);
   }

   configureMultipleModalsProductCrud(closeModal = false) {
      setTimeout(() => {
         const modals2 = $('.crud-modal');

         modals2.each(function (index) {
            if (closeModal ? modals2.length - 1 === 1 : modals2.length === 1) {
               $(this).css('background', 'var(--blue-primary)');
            } else {
               $(this).css('background', 'var(--blue-200)');
            }
         });
      }, 200);
   }

   decodeAutocompleteList(
      encodedResponse: any,
      textProperty: string,
      textPropertyPrefix: string = null,
      imageProperty: string = null,
      separator: string = ' - '
   ): any[] {
      const list = JSON.parse(this.decodeString(encodedResponse));
      const ret = [];
      list.forEach((element) => {
         let prices: any[] = null;
         let cost = 0;
         if (element.Prices && element.Prices.length > 0) {
            prices = [];
            cost = element.Prices[0].Cost;
            element.Prices.forEach((price) => {
               prices.push({
                  priceTableId: price.PriceTableId,
                  sell: price.Sell,
               });
            });
         }

         ret.push({
            id: element.Id,
            text: (textPropertyPrefix ? element[textPropertyPrefix] + separator : '') + element[textProperty],
            prices: prices,
            cost: cost,
            image: element[imageProperty],
         });
      });
      return ret;
   }

   toPrecision(num: any, precision: number) {
      const calculatedPrecision = Math.pow(10, precision);
      return Math.floor(num * calculatedPrecision) / calculatedPrecision;
   }

   slugify(text: string) {
      if (!text) {
         return null;
      }
      const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
      const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
      const p = new RegExp(a.split('').join('|'), 'g');

      return text
         .toString()
         .toLowerCase()
         .replace(/\s+/g, '-') // Replace spaces with -
         .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
         .replace(/&/g, '-and-') // Replace & with 'and'
         .replace(/[^\w\-]+/g, '') // Remove all non-word characters
         .replace(/\-\-+/g, '-') // Replace multiple - with single -
         .replace(/^-+/, '') // Trim - from start of text
         .replace(/-+$/, ''); // Trim - from end of text
   }

   copyTextToClipboard(text: string, confirmMessage: string = null) {
      const el = document.createElement('textarea');
      el.value = text;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      if (confirmMessage) {
         this.notificationService.showInfoMessage(confirmMessage);
      }
   }

   formatDocument(document: string): string {
      if (!document) return null;

      const docOnlyNumbers = document.replace(/\D/g, '');
      if (docOnlyNumbers.length == 14) {
         const part1 = docOnlyNumbers.substring(0, 2);
         const part2 = docOnlyNumbers.substring(2, 5);
         const part3 = docOnlyNumbers.substring(5, 8);
         const part4 = docOnlyNumbers.substring(8, 12);
         const part5 = docOnlyNumbers.substring(12);
         return part1 + '.' + part2 + '.' + part3 + '/' + part4 + '-' + part5;
      } else if (docOnlyNumbers.length == 11) {
         const part1 = docOnlyNumbers.substring(0, 3);
         const part2 = docOnlyNumbers.substring(3, 6);
         const part3 = docOnlyNumbers.substring(6, 9);
         const part4 = docOnlyNumbers.substring(9);
         return part1 + '.' + part2 + '.' + part3 + '-' + part4;
      }

      return document;
   }

   deaccentText(text: string) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
   }

   setPageTitle(pageName: string) {
      return this.title.setTitle('KCMS | ' + this.i18nService.getTranslation(pageName));
   }

   convertDateToLocaleString(date: Date, format: moment.LongDateFormatKey = 'L'): string {
      const localeDate = moment(date).localeData();
      return moment.utc(date).format(localeDate.longDateFormat(format));
   }

   convertDateTimeToLocaleString(date: Date, format: string = 'HH:mm'): string {
      return moment(date).format(format);
   }

   generateErrorNotification(err) {
      let message: string;
      if (err) {
         if (err.error) {
            if (err.error instanceof Array && err.error.length > 0) {
               message = err.error[0].Message;
            } else if (err.error.Message) {
               message = err.error.Message;
            }
         } else if (err.Message) {
            message = err.Message;
         }
      }

      this.notificationService.showErrorBox(message);
   }

   dateAddPeriodToDate(date: Date, period: string, times: number): Date {
      if (!period) {
         return date;
      }

      const newDate = moment(date);

      switch (period.toLowerCase()) {
         case 'daily':
            // date.setDate(date.getDate() + (times * 1));
            newDate.add(times, 'days');
            break;
         case 'weekly':
            // date.setDate(date.getDate() + (times * 7));
            newDate.add(times * 7, 'days');
            break;
         case 'monthly':
            // date.setMonth(date.getMonth() + 1);
            newDate.add(times, 'months');
            break;
         case 'yearly':
            // date.setMonth(date.getMonth() + (times * 12));
            newDate.add(times * 12, 'months');
            break;
      }

      return newDate.toDate();
   }

   dateCreateFromDDMMYYYY(ddMMyyyy: string): Date {
      const dateParts = ddMMyyyy.split('/');
      return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
   }

   dateGetStartTime(date: Date): Date {
      return new Date(date.setHours(0, 0, 0, 0));
   }

   dateGetEndTime(date: Date): Date {
      return new Date(date.setHours(23, 59, 59, 999));
   }

   dateToIsoString(date: Date): string {
      return moment(date).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
   }

   getCertificate(): any[] {
      return [
         { Id: '1', Name: 'http://', default: 'default' },
         { Id: '2', Name: 'https://' },
      ];
   }

   validCpfCnpj(documentNumber: string) {
      if (documentNumber) {
         if (documentNumber.length <= 14) {
            return validateBr.cpf(documentNumber);
         } else {
            return validateBr.cnpj(documentNumber);
         }
      }
   }

   validCelPhone(number: string) {
      if (number) {
         if (number.length === 16) {
            return validateBr.celular(number);
         } else {
            return validateBr.telefone(number);
         }
      }
   }

   isValidZipCode(zipCode: string): boolean {
      return validateBr.cep(zipCode);
   }

   setMaskStateRegistration(state: string) {
      let mask = '';
      let size = 0;

      switch (state.toLowerCase()) {
         case 'ac':
            mask = '00.000.000/000-00';
            size = 13;
            break;
         case 'al':
            mask = '000000000';
            size = 9;
            break;
         case 'ap':
            mask = '000000000';
            size = 9;
            break;
         case 'am':
            mask = '00.000.000-0';
            size = 9;
            break;
         case 'ba':
            mask = '0000000-00';
            size = 9;
            break;
         case 'ce':
            mask = '00000000-0';
            size = 10;
            break;
         case 'df':
            mask = '00000000000-00';
            size = 13;
            break;
         case 'es':
            mask = '00000000-0';
            size = 9;
            break;
         case 'go':
            mask = '00.000.000-0';
            size = 9;
            break;
         case 'ma':
            mask = '00000000-0';
            size = 9;
            break;
         case 'mt':
            mask = '0000000000-0';
            size = 11;
            break;
         case 'ms':
            mask = '00000000-0';
            size = 9;
            break;
         case 'mg':
            mask = '000.000.000/0000';
            size = 13;
            break;
         case 'pa':
            mask = '00-000000-0';
            size = 9;
            break;
         case 'pb':
            mask = '00000000-0';
            size = 9;
            break;
         case 'pr':
            mask = '000.00000-00';
            size = 10;
            break;
         case 'pe':
            mask = '0000000-00';
            size = 9;
            break;
         case 'pi':
            mask = '00000000-0';
            size = 9;
            break;
         case 'rj':
            mask = '00.000.00-0';
            size = 8;
            break;
         case 'rn':
            mask = '00.000.000-0';
            size = 9;
            break;
         case 'rs':
            mask = '000/0000000';
            size = 10;
            break;
         case 'ro':
            mask = '0000000000000-0';
            size = 14;
            break;
         case 'rr':
            mask = '00000000-0';
            size = 9;
            break;
         case 'sc':
            mask = '000.000.000';
            size = 9;
            break;
         case 'sp':
            mask = '000.000.000.000';
            size = 12;
            break;
         case 'se':
            mask = '00000000-0';
            size = 10;
            break;
         case 'to':
            mask = '0000000000-0';
            size = 11;
            break;
      }

      return [{ Mask: mask, Size: size }];
   }

   validateIe(state: string, ie: string) {
      if (state.toLocaleLowerCase() === 'go' || state.toLocaleLowerCase() === 'to') {
         return true;
      }

      if (ie) {
         return validateBr.inscricaoestadual(ie, state);
      }
   }

   validateEmail(email: string) {
      if (email) {
         return validateBr.email(email);
      }
   }

   padLeft(str: string, length: number, character: string = '0'): string {
      let result = str || '';
      for (let i = result.length; i < length; ++i) {
         result = character + result;
      }
      return result;
   }

   checkDevice() {
      if (
         navigator.userAgent.match(/Android/i) ||
         navigator.userAgent.match(/webOS/i) ||
         navigator.userAgent.match(/iPhone/i) ||
         navigator.userAgent.match(/iPad/i) ||
         navigator.userAgent.match(/iPod/i) ||
         navigator.userAgent.match(/BlackBerry/i) ||
         navigator.userAgent.match(/Windows Phone/i)
      ) {
         return true;
      } else {
         return false;
      }
   }

   validateColor(color) {
      const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
      return regex.test(color);
   }
}
