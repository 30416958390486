import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as CryptoJS from 'crypto-js';
import Base64 from 'crypto-js/enc-base64'
import Utf8 from 'crypto-js/enc-utf8'

@Injectable()
export class CryptoService {

  constructor() { }

  encryptToAes(text: string): string {
    var key = CryptoJS.enc.Utf8.parse(environment.cryptoKey);
    var genIv = CryptoJS.enc.Utf8.parse(environment.cryptoIV);

    return (CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key,
      { keySize: 128 / 8, iv: genIv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })).toString();
  }

  decryptFromAes(text: string): string {
    var key = CryptoJS.enc.Utf8.parse(environment.cryptoKey);
    var genIv = CryptoJS.enc.Utf8.parse(environment.cryptoIV);

    return CryptoJS.enc.Utf8.stringify((CryptoJS.AES.decrypt(text, key,
      { keySize: 128 / 8, iv: genIv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })));
  }

  hashToHmacSHA256(text: string): string {
    return (CryptoJS.HmacSHA256(text, environment.cryptoKey)).toString();
  }

  encodeToBase64(text: string) {
    return Base64.stringify(Utf8.parse(text));
  }

  decodeBase64(encoded: string) {
    const encodedWord = CryptoJS.enc.Base64.parse(encoded);
    const decoded = CryptoJS.enc.Utf8.stringify(encodedWord);

    return decoded;
  }
}
