import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CompanyService } from 'app/domain/services/company.service';
import { NavigationService } from 'app/domain/services/navigation.service';
import { FunctionService } from 'app/shared/utils/function.service';
import { I18nModule } from '../../i18n/i18n.module';
import { UserModule } from '../../user/user.module';
import { BigBreadcrumbsComponent } from './big-breadcrumbs.component';
import { MinifyMenuComponent } from './minify-menu.component';
import { NavigationComponent } from './navigation.component';
import { SmartMenuDirective } from './smart-menu.directive';

@NgModule({
   imports: [CommonModule, RouterModule, I18nModule, UserModule],
   declarations: [BigBreadcrumbsComponent, MinifyMenuComponent, NavigationComponent, SmartMenuDirective],
   exports: [BigBreadcrumbsComponent, MinifyMenuComponent, NavigationComponent, SmartMenuDirective],
   providers: [NavigationService, CompanyService, FunctionService],
})
export class NavigationModule {}
