import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'app/api/api.service';
import { AlertBox } from 'app/domain/models/alert-box.model';

@Injectable()
export class AlertBoxService {

  domainName = 'AlertBox';
  updatedMessage = 'AlertBoxUpdated';

  constructor(private apiService: ApiService) { }

  get(id: string): Observable<AlertBox> {
    return this.apiService.get(this.domainName, id);
  }

  getActives(): Observable<AlertBox[]> {
    return this.apiService.get(this.domainName);
  }

  save(obj: AlertBox): Observable<string> {
    return this.apiService.post(this.domainName, obj, this.updatedMessage);
  }
}
