import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, CurrencyPipe } from '@angular/common';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { SmartadminDatatableModule } from 'app/shared/ui/datatable/smartadmin-datatable.module';
import { GridListComponent } from './grid-list.component';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
   imports: [CommonModule, I18nModule, SmartadminDatatableModule, LoadingModule],
   declarations: [GridListComponent],
   exports: [GridListComponent],
   providers: [DecimalPipe, CurrencyPipe],
})
export class GridListModule {}
