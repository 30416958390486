import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { ConnectionResolver } from 'app/api/signalr.connectionresolver';
import { AccessType, AuthService } from 'app/auth/auth.service';
import {
   OrderCenterNotification,
   OrderCenterNotificationConfig,
   OrderCenterNotificationMessage,
} from 'app/domain/models/order-center-notification.model';
import { QueryModelUpdated } from 'app/domain/models/query-model-updated.model';
import { OrderCenterNotificationService } from 'app/domain/services/order-center-notification.service';
import { I18nService } from 'app/shared/i18n';
import { FunctionService } from 'app/shared/utils/function.service';
import { NotificationService } from 'app/shared/utils/notification.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
   selector: 'kcms-order-center-notification',
   templateUrl: './order-center-notification.component.html',
   styleUrls: ['./order-center-notification.component.scss'],
})
export class OrderCenterNotificationComponent implements OnInit, OnDestroy {
   failedRequests = 0;
   failedHubConnectionAttempts = 0;
   authorizationTokenObservable: Subscription;
   connection: signalR.HubConnection = null;
   companiesCount = 0;
   selectedConfig: OrderCenterNotificationConfig;
   orderCenterConfigs: OrderCenterNotificationConfig[];
   orderCenterPausePeriod: number;
   isOpened = false;
   isPaused = false;
   openOrders = 0;
   isPauseRequestLoading = false;
   lastRing: moment.Moment;
   notificationInterval: NodeJS.Timer;

   constructor(
      public authService: AuthService,
      public notificationService: NotificationService,
      public functionService: FunctionService,
      public orderCenterNotificationService: OrderCenterNotificationService,
      public router: Router,
      public i18nService: I18nService,
      public connectionResolver: ConnectionResolver
   ) {}

   _bindedPosId: string;
   @Input()
   get bindedPosId(): string {
      return this._bindedPosId;
   }
   set bindedPosId(val: string) {
      this._bindedPosId = val;
      localStorage.removeItem('notification_token');

      if (val) {
         this.notificationService.showAlertBox('Sua Central de Pedido carregada e pronta para uso!!');
      }

      setTimeout(() => {
         this.configureNotificationInterval();
      }, 100);
   }

   ngOnInit() {
      if (environment.notification) {
         this.authorizationTokenObservable = this.authService.authorizationTokenObservable.subscribe((val) => {
            if (val) {
               localStorage.removeItem('notification_token');
               this.configureNotificationInterval();
            }
         });

         this.configureSignalR();
      }
   }

   ngOnDestroy() {
      clearInterval(this.notificationInterval);

      if (this.authorizationTokenObservable) {
         this.authorizationTokenObservable.unsubscribe();
      }
   }

   configureNotificationInterval() {
      this.companiesCount = 0;
      this.selectedConfig = null;
      this.isOpened = false;
      this.isPaused = false;
      this.openOrders = 0;

      clearInterval(this.notificationInterval);

      const hasReadPermission = this.authService.hasPermission('OrderCenterPanel', AccessType.Read);
      const bindedPosId = sessionStorage.getItem('binded-pos-id');

      if (hasReadPermission || bindedPosId) {
         this.tickNotification();
         this.notificationInterval = setInterval(() => this.tickNotification(), 30000);
      }
   }

   tickNotification(forceUpdate = false) {
      const dateTimestamp = new Date().getTime();
      const lastAccess = Number(localStorage.getItem('notification_token'));

      if (forceUpdate || !lastAccess || lastAccess == NaN || dateTimestamp > lastAccess + 28000) {
         localStorage.setItem('notification_token', dateTimestamp.toString());

         this.orderCenterNotificationService.getNotifications(moment().local().utcOffset()).subscribe(
            (encodedRet) => {
               if (encodedRet) {
                  const ret: OrderCenterNotification = JSON.parse(this.functionService.decodeString(encodedRet));
                  this.failedRequests = 0;
                  this.companiesCount = ret.CompaniesCount;
                  this.orderCenterConfigs = ret.OrderCenterConfigs;

                  this.isOpened = ret.IsOpened;
                  this.isPaused = ret.IsPaused;
                  this.openOrders = ret.OpenOrders;

                  if (ret.OpenOrders > 0) {
                     this.playNotificationRing();
                  }

                  if (ret.OrderCenterConfigs && ret.OrderCenterConfigs.length === 1) {
                     this.selectedConfig = ret.OrderCenterConfigs[0];
                  }

                  this.toggleSignalRConnection();
               } else {
                  this.companiesCount = 0;
                  this.selectedConfig = null;
                  this.isOpened = false;
                  this.isPaused = false;
                  this.openOrders = 0;
               }
            },
            (err) => {
               this.failedRequests++;
               this.companiesCount = 0;
               this.selectedConfig = null;
               this.isOpened = false;
               this.isPaused = false;
               this.openOrders = 0;

               if (this.failedRequests >= 3) {
                  clearInterval(this.notificationInterval);
                  this.notificationService.showErrorMessage('NotificationErrorMsg', 'NotificationErrorTitle');
               }
            }
         );
      }
   }

   openOrderCenterDetail(companyId: number) {
      this.selectedConfig = this.orderCenterConfigs.find((n) => n.CompanyId == companyId);
   }

   returnToCompanyList() {
      this.selectedConfig = null;
      this.orderCenterPausePeriod = null;
   }

   openBusinessHoursConfig() {
      this.closeMenuDelivery();
      this.router.navigate(['/configuracao/empresa/detalhe/' + this.selectedConfig.CompanyId, { tab: 'companyTab-c4' }]);
   }

   tooglePauseStore() {
      let subscription = null;
      this.isPauseRequestLoading = true;
      this.notificationService.showAlertMessage();
      this.orderCenterNotificationService
         .pauseStore(this.selectedConfig.CompanyId, this.isPaused ? null : this.orderCenterPausePeriod)
         .subscribe(
            (ret) => {},
            (err) => {
               if (subscription != null) {
                  subscription.unsubscribe();
               }
               this.isPauseRequestLoading = false;
               this.notificationService.showErrorBox(err.Message);
            }
         );

      subscription = this.connectionResolver.queryModelUpdated.subscribe((message: QueryModelUpdated) => {
         const commandMessage = this.isPaused
            ? this.orderCenterNotificationService.storeUnpausedMessage
            : this.orderCenterNotificationService.storePausedMessage;
         if (message.Message === commandMessage) {
            subscription.unsubscribe();

            this.orderCenterPausePeriod = null;
            if (this.orderCenterConfigs && this.orderCenterConfigs.length > 1) {
               this.selectedConfig = null;
            }
            this.isPauseRequestLoading = false;
            this.tickNotification(true);
            this.notificationService.showSuccessMessage(commandMessage);
         }
      });
   }

   configureSignalR() {
      this.connection = new signalR.HubConnectionBuilder().withUrl(environment.orderCenterHubUrl).build();
      this.connection.serverTimeoutInMilliseconds = 60000;
      this.connection.keepAliveIntervalInMilliseconds = 30000;

      this.connection.on('GetOrderStatusChange', (orderId: string, statusCode: string) => {
         if (orderId && statusCode) {
            this.orderCenterNotificationService.setOrderStatusChanged();
            this.tickNotification(true);
         }
      });

      this.connection.on('GetOrderChangeNotification', (clientNotifications: OrderCenterNotificationMessage[]) => {
         if (clientNotifications && clientNotifications.length > 0) {
            this.orderCenterNotificationService.setNotificationCreated();
            this.tickNotification(true);

            clientNotifications.forEach((notification) => {
               this.notificationService.showOrderCenterMessage(notification);
            });
         }
      });

      console.log('OrderCenter SignalR connection configured.');

      this.connection.onclose((event) => {
         console.log('OrderCenter SignalR connection closed.');

         const isInfiniteAttempts = event.message.includes('status code: 1000');

         if (this.isOpened) {
            setTimeout(() => {
               console.log('Reconnecting...');
               this.toggleSignalRConnection(isInfiniteAttempts);
            }, 5000);
         }
      });
   }

   toggleSignalRConnection(isInfiniteAttempts = false) {
      if (
         this.isOpened &&
         this.orderCenterConfigs &&
         (!this.connection || this.connection.state === signalR.HubConnectionState.Disconnected)
      ) {
         this.configureSignalR();

         this.connection
            .start()
            .then(() => {
               const companyIds = this.orderCenterConfigs.map((n) => n.CompanyId);
               this.connection.send('AcknowledgeClient', companyIds);

               this.failedHubConnectionAttempts = 0;
               console.log('OrderCenter SignalR connection estabilished.');
            })
            .catch(() => {
               if (!isInfiniteAttempts) {
                  this.failedHubConnectionAttempts++;
               }

               console.log('Could not connect to OrderCenter SignalR.');

               if (this.failedHubConnectionAttempts < 3) {
                  this.toggleSignalRConnection(isInfiniteAttempts);
               } else {
                  this.notificationService.showErrorMessage('NotificationErrorMsg');
                  clearInterval(this.notificationInterval);
               }
            });
      } else if (!this.isOpened && this.connection && this.connection.state === signalR.HubConnectionState.Connected) {
         this.connection
            .stop()
            .then(() => {})
            .catch(() => {
               console.log('Could not stop OrderCenter SignalR connection.');
            });
      }
   }

   playNotificationRing() {
      const currentDate = moment();

      if (!this.lastRing || this.lastRing.add(15, 'seconds').isBefore(currentDate)) {
         this.lastRing = currentDate;

         const audio = new Audio();
         audio.src = './assets/media/' + environment.orderCenterRing;
         audio.load();

         if (this.bindedPosId) {
            audio.addEventListener('canplaythrough', () => {
               audio.play().catch((e) => {
                  if (e) {
                     window.addEventListener(
                        'click',
                        () => {
                           audio.play();
                        },
                        { once: true }
                     );
                  }
               });
            });
         } else {
            audio.play();
         }
      }
   }

   openMenuDelivery() {
      var element = document.getElementById('delivery-menu');
      element.classList.add('delivery-in');
      element.classList.remove('delivery-out');
   }

   closeMenuDelivery() {
      var element = document.getElementById('delivery-menu');
      element.classList.add('delivery-out');
      element.classList.remove('delivery-in');
   }
}
