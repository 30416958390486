import { EventEmitter, Injectable } from '@angular/core';
import { NotificationService } from 'app/shared/utils/notification.service';
import { QueryModelUpdated } from 'app/domain/models/query-model-updated.model';
import { environment } from 'environments/environment';
import * as signalR from "@microsoft/signalr";

@Injectable()
export class ConnectionResolver {

  failedHubConnectionAttempts = 0;
  private connection: signalR.HubConnection = null;
  private connectionId: string;
  queryModelUpdated: EventEmitter<QueryModelUpdated> = new EventEmitter<QueryModelUpdated>();

  constructor(private notificationService: NotificationService) { }

  resolve() {
    if (!this.connection || this.connection.state === signalR.HubConnectionState.Disconnected) {
      this.configureSignalR();

      this.connection.start().then(() => {
        this.failedHubConnectionAttempts = 0;
        console.log('ERP SignalR connection estabilished.');
      }).catch(() => {
        this.failedHubConnectionAttempts++;

        console.log('Could not connect to ERP SignalR.');

        if (this.failedHubConnectionAttempts < 5) {
          setTimeout(() => {
            this.resolve();
          }, 5000);
        } else {
          this.notificationService.showErrorBox('SignalRConnectionErrorMsg', 'SignalRConnectionErrorTitle');
          this.failedHubConnectionAttempts = 0;
        }
      });
    }
  }

  getConnectionId(): string {
    return this.connectionId;
  }

  private configureSignalR() {
    this.connection = new signalR.HubConnectionBuilder().withUrl(environment.erpHubUrl).build();
    this.connection.serverTimeoutInMilliseconds = 60000;
    this.connection.keepAliveIntervalInMilliseconds = 30000;

    this.connection.on('GetConnected', connectionId => {
      this.connectionId = connectionId;
    });

    this.connection.on('QueryModelUpdated', message => {
      const splittedMessage = message.split(';');
      this.queryModelUpdated.emit(new QueryModelUpdated(splittedMessage.length > 1 ? splittedMessage[1] : '', splittedMessage[0]));
    });

    this.connection.on('ErrorOccurred', errorMessage => {
      console.log(errorMessage);
    });

    this.connection.onclose((event) => {
      console.log('ERP SignalR connection closed.');
      console.log('Reconnecting...');
      this.connectionId = null;
      this.resolve();
    });

    console.log('ERP SignalR connection configured.');
  }
}
