export class NfeEntryProduct {
   public Id: string;
   public ItemNumber: number;
   public ProductCode: string;
   public ProductBarcode: string;
   public ProductName: string;
   public Quantity: number;
   public PackageQuantity: number;
   public TotalQuantity: number;
   public ProductUnitId: string;
   public ProductUnitAcronym: string;
   public NcmId: string;
   public NcmCode: string;
   public NcmName: string;
   public CestId: string;
   public CestCode: string;
   public CestName: string;
   public DiscountValue: number;
   public FreightValue: number;
   public InsuranceValue: number;
   public TaxValue: number;
   public Value: number;
   public NetTotalValue: number;
   public TotalValue: number;
   public AncillaryExpenseValue: number;
   public CfopId: string;
   public CfopCode: string;
   public CfopName: string;
   public CstIcmsId: string;
   public CstIcmsCode: string;
   public CstIcmsName: string;
   public CstOriginId: string;
   public CstOriginCode: string;
   public CstOriginName: string;
   public SubstitutionIcmsDeterminationMethodId: string;
   public SubstitutionIcmsDeterminationMethodCode: string;
   public SubstitutionIcmsDeterminationMethodName: string;
   public SubstitutionIcmsCalculationBasis: number;
   public SubstitutionIcmsReduction: number;
   public SubstitutionIcmsMargin: number;
   public SubstitutionIcmsAliquot: number;
   public SubstitutionIcmsValue: number;
   public CstPisId: string;
   public CstPisCode: string;
   public CstPisName: string;
   public PisValue: number;
   public PisCalculationBasis: number;
   public PisAliquotPercent: number;
   public PisAliquotValue: number;
   public PisQuantity: number;
   public CstCofinsId: string;
   public CstCofinsCode: string;
   public CstCofinsName: string;
   public CofinsValue: number;
   public CofinsCalculationBasis: number;
   public CofinsAliquotPercent: number;
   public CofinsAliquotValue: number;
   public CofinsQuantity: number;
   public CstIpiId: string;
   public CstIpiCode: string;
   public CstIpiName: string;
   public IpiCalculationBasis: number;
   public IpiAliquotPercent: number;
   public IpiQuantity: number;
   public IpiQuantityValue: number;
   public IpiValue: number;

   public LinkedProductId: string;
   public LinkedProductBarcode: string;
   public LinkedProductName: string;
   public LinkedProductUnitId: string;
   public LinkedProductUnitAcronym: string;
   public LinkedProductUnitName: string;
   public LinkedProductNcmId: string;
   public LinkedProductNcmCode: string;
   public LinkedProductNcmName: string;
   public LinkedProductCfopId: string;
   public LinkedProductCfopCode: string;
   public LinkedProductCfopName: string;
   public LinkedProductCategoryId: string;
   public LinkedProductCategoryName: string;

   public IsLinked: boolean;

   public StockLocationId: string;
   public StockLocationName: string;
   public SkipStockMovement: boolean;

   constructor() {
      this.AncillaryExpenseValue = 0;
      this.PackageQuantity = 1;
      this.FreightValue = 0;
      this.DiscountValue = 0;
      this.InsuranceValue = 0;
      this.NetTotalValue = 0;
      this.IsLinked = false;
   }
}

export class NfeEntryPaymentFormInstallment {
   public Number: number;
   public Value: number;
   public DueDay: number;
   public Quota: number;

   constructor(number: number, value: number, dueDay: number, quota: number) {
      this.Number = number;
      this.Value = value;
      this.DueDay = dueDay;
      this.Quota = quota;
   }
}

export class NfeEntryPaymentForm {
   public Id: string;
   public IndicatorCode: string;
   public IndicatorName: string;
   public Code: string;
   public CodeName: string;
   public Name: string;
   public Value: number;
}

export class NfeEntryAddress {
   public Id: string;
   public AddressTypeId: string;
   public AddressTypeName: string;
   public AddressLine1: string;
   public AddressLine2: string;
   public AddressNumber: string;
   public Neighborhood: string;
   public CountryCode: string;
   public CityId: string;
   public CityCode: string;
   public CityName: string;
   public StateId: string;
   public StateCode: string;
   public StateAcronym: string;
   public StateName: string;
   public CountryId: string;
   public CountryName: string;
   public ZipCode: string;
}

export class NfeEntryRecipientAddress {
   public Id: string;
   public AddressLine1: string;
   public AddressLine2: string;
   public AddressNumber: string;
   public Neighborhood: string;
   public CityName: string;
   public ZipCode: string;
   public StateAcronym: string;
}

export class NfeEntryIssuerInfo {
   public Id: string;
   public SupplierId: string;
   public FiscalRegimeTypeId: string;
   public PersonType: number;
   public Name: string;
   public TradingName: string;
   public Cnpj: string;
   public Cpf: string;
   public StateRegistration: string;
   public SuframaRegistration: string;
   public MunicipalRegistration: string;
   public IeIndicator: number;
   public Email: string;
   public Phone: string;
   public DocumentNumber: string;
   public IsLegalPerson: boolean;
   public Address: NfeEntryAddress;

   constructor() {
      this.Address = new NfeEntryAddress();
   }
}

export class NfeEntryRecipientInfo {
   public Id: string;
   public Name: string;
   public Cnpj: string;
   public Cpf: string;
   public DocumentNumber: string;
   public StateRegistration: string;
   public MunicipalRegistration: string;
   public Email: string;
   public Phone: string;
   public IeIndicator: string;
   public Address: NfeEntryRecipientAddress;

   constructor() {
      this.Address = new NfeEntryRecipientAddress();
   }
}

export class NfeEntryShippingCompanyInfo {
   public Id: string;
   public Name: string;
   public Cnpj: string;
   public Cpf: string;
   public StateRegistration: string;
   public FormattedAddress: string;
   public CityName: string;
   public StateAcronym: string;
}

export class NfeEntryPickupPlaceInfo {
   public Id: string;
   public DocumentNumber: string;
   public AddressLine1: string;
   public AddressLine2: string;
   public AddressNumber: string;
   public Neighborhood: string;
   public CityName: string;
   public StateAcronym: string;
}

export class NfeEntryDeliveryPlaceInfo {
   public Id: string;
   public DocumentNumber: string;
   public AddressLine1: string;
   public AddressLine2: string;
   public AddressNumber: string;
   public Neighborhood: string;
   public CityName: string;
   public StateAcronym: string;
}

export class NfeEntryTotal {
   public IcmsTotalCalculationBasis: number;
   public IcmsTotalValue: number;
   public SubstitutionIcmsTotalCalculationBasis: number;
   public SubstitutionIcmsTotalValue: number;
   public IcmsExemptedTotalValue: number;
   public FcpTotalValue: number;
   public SubstitutionFcpTotalValue: number;
   public SubstitutionFcpWithheldTotalValue: number;
   public ProductTotalValue: number;
   public FreightTotalValue: number;
   public InsuranceTotalValue: number;
   public DiscountTotalValue: number;
   public ImportTaxTotalValue: number;
   public IpiTotalValue: number;
   public PisTotalValue: number;
   public CofinsTotalValue: number;
   public AncillaryExpenseTotalValue: number;
   public NfeTotalValue: number;
   public TaxTotalValue: number;

   constructor() {
      this.IcmsTotalCalculationBasis = 0;
      this.IcmsTotalValue = 0;
      this.SubstitutionIcmsTotalCalculationBasis = 0;
      this.SubstitutionIcmsTotalValue = 0;
      this.IcmsExemptedTotalValue = 0;
      this.FcpTotalValue = 0;
      this.SubstitutionFcpTotalValue = 0;
      this.SubstitutionFcpWithheldTotalValue = 0;
      this.ProductTotalValue = 0;
      this.FreightTotalValue = 0;
      this.InsuranceTotalValue = 0;
      this.DiscountTotalValue = 0;
      this.ImportTaxTotalValue = 0;
      this.IpiTotalValue = 0;
      this.PisTotalValue = 0;
      this.CofinsTotalValue = 0;
      this.AncillaryExpenseTotalValue = 0;
      this.NfeTotalValue = 0;
      this.TaxTotalValue = 0;
   }
}

export class NfeEntryReferencedFiscalDocumentInfo {
   public Id: string;
   public AccessKey: string;
}

export class NfeEntry {
   public Id: string;
   public IntegrationId: string;
   public CompanyId: number;
   public CompanyName: string;
   public FiscalDocumentType: number;
   public FiscalDocumentTypeName: string;
   public AccessKey: string;
   public FiscalDocumentSeries: number;
   public FiscalDocumentNumber: number;

   public FiscalOperationTypeId: number;
   public FiscalOperationTypeName: string;

   public FiscalOperationTypeDetailName: string;

   public EmissionDate: Date;
   public EntryDate?: Date;
   public EntryExitDate?: Date;
   public IsManuallyImported: boolean;
   public AdditionalInformation: string;
   public FreightModeCode: string;
   public FreightModeName: string;

   public IsEntry: boolean;
   public IsFinalConsumer: boolean;

   public ConsumerPresenceCode: string;
   public ConsumerPresenceName: string;

   public StatusCode: NfeEntryStatus;
   public StatusName: string;

   public Issuer: NfeEntryIssuerInfo;
   public IssuerName: string;
   public IssuerDocumentNumber: string;

   public HasDifferentDeliveryPlace: boolean;
   public HasDifferentPickupPlace: boolean;

   public Recipient: NfeEntryRecipientInfo;
   public ShippingCompany: NfeEntryShippingCompanyInfo;
   public PickupPlace: NfeEntryPickupPlaceInfo;
   public DeliveryPlace: NfeEntryDeliveryPlaceInfo;
   public Products: NfeEntryProduct[];
   public PaymentForms: NfeEntryPaymentForm[];
   public Total: NfeEntryTotal;
   public NfeReferencedFiscalDocuments: NfeEntryReferencedFiscalDocumentInfo[];

   public HasUnlinkedProducts: boolean;
   public NfeTotalValue: number;

   public CreatedBy: Date;
   public LastModifiedOn: string;
   public LastModifiedBy: string;
   public CreatedOn: Date;

   constructor() {
      this.Issuer = new NfeEntryIssuerInfo();
      this.Recipient = new NfeEntryRecipientInfo();
      this.Total = new NfeEntryTotal();
      this.Products = [];
      this.PaymentForms = [];
      this.NfeTotalValue = 0;
      this.HasDifferentDeliveryPlace = false;
      this.HasDifferentPickupPlace = false;
   }
}

export enum NfeEntryStatus {
   NfeEntryPending = '0',
   NfeEntryClosed = '1',
   NfeEntryCanceled = '2',
}

export class ImportNfeEntryFromFileRequest {
   public CompanyId: number;
   public File: string;
}

export class ImportNfeEntryFromNfeResumeRequest {
   public CompanyId: number;
   public NfeResumeId: string;

   constructor(companyId: number, nfeResumeId: string) {
      this.NfeResumeId = nfeResumeId;
      this.CompanyId = companyId;
   }
}

export class NfeEntryProductInfoRequest {
   public Id: string;

   public LinkedProductId: string;
   public PackageQuantity: number;
   public StockLocationId: string;
   public SkipStockMovement: boolean;
}

export class UpdateNfeEntryEntryInfoRequest {
   public Id: string;
   public CompanyId: number;
   public Products: NfeEntryProductInfoRequest[];
}

export class CloseNfeEntryRequest {
   public Id: string;
   public CompanyId: number;

   constructor(id: string, companyId: number) {
      this.Id = id;
      this.CompanyId = companyId;
   }
}

export class SetNfeEntrySupplierRequest {
   public Id: string;
   public IssuerId: string;
   public SupplierId: string;

   constructor(id: string, issuerId: string, supplierId: string) {
      this.Id = id;
      this.IssuerId = issuerId;
      this.SupplierId = supplierId;
   }
}

export class NfeEntrySummaryResponse {
   public Id: string;
   public CompanyId: number;
   public AccessKey: string;
   public FiscalDocumentSeries: number;
   public FiscalDocumentNumber: number;
   public IssuerName: string;
   public IssuerDocumentNumber: string;
   public IssuerIe: string;
   public EmissionDate: string;
   public NfeTotalValue: number;
   public NfeStatusCode: number;
   public NfeStatusName: string;
   public ReceiverManifestationStatusCode: number;
   public ReceiverManifestationStatusName: string;
   public IsNfeEntryAvailable: boolean;
   public IsNfeEntryImported: boolean;
   public AllowReceiverManifestation: boolean;

   constructor() {
      this.IsNfeEntryAvailable = false;
      this.IsNfeEntryImported = false;
      this.AllowReceiverManifestation = false;
   }
}

export class NfeEntryReceiverManifestationRequest {
   public Id: string;
   public CompanyId: number;
   public TypeCode: number;
   public TypeName: string;
   public Justification: string;

   constructor(id: string, companyId: number) {
      this.Id = id;
      this.CompanyId = companyId;
   }
}

export class NfeEntryReceiverManifestationResponse {
   public Id: string;
   public StatusCode: number;
   public StatusMessage: string;
   public Authorized: boolean;
}

export class NfeEntrySearchDfeDistributionResponse {
   public MaxNsuSefaz: string;
   public LastSearchedNsu: string;
   public NfeEntrySummaryQuantityFound: number;
   public NfeEntryQuantityFound: number;
   public SearchBlockedUntil: Date;
}
