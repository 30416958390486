import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { AlertBoxComponent } from './alert-box.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AlertBoxService } from 'app/domain/services/alert-box.service';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    AlertModule,
  ],
  declarations: [
    AlertBoxComponent,
  ],
  exports: [
    AlertBoxComponent,
  ],
  providers: [
    AlertBoxService,
  ]
})

export class AlertBoxModule { }
